import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Image, Col, Row, Card, Button } from "react-bootstrap";
import "react-image-lightbox/style.css";
import Paginate from "../../inc/Paginate";
import { FaArrowRight } from "react-icons/fa";
import { BiSearchAlt } from "react-icons/bi";
import axios from "axios";

function Galeri() {
  const [galeri, setGaleri] = useState([]);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(8);
  const [searchValue, setSearchValue] = useState("");

  function DetailGaleri(id_agenda_value, nama) {
    navigate("/main/galeridetail", {
      state: { id_agenda_current: id_agenda_value, nama_kegiatan: nama },
    });
  }

  useEffect(() => {
    axios
      .get("https://ika.pcr.ac.id/backend_ikapcr/public/api/agenda")
      .then((response) => {
        setGaleri(response.data);
      });
  }, []);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = galeri.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const previousPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== Math.ceil(galeri.length / postsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div>
       <div style={{ paddingTop: "70px" }}>
        <div className="main-title">
          <h1>
          <strong>Galeri</strong>
          </h1>
        </div>
      </div>
      <Container className="box">
    
        <div className="search">
          <div className="search-box">
            <Button className="btn-search">
              <BiSearchAlt />
            </Button>
            <input
              className="input-search"
              type="text"
              name="search"
              placeholder="Type to Search..."
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
        </div>
        <div className="konten">
          <Row>
            {currentPosts
              .filter((galjdl) =>
                galjdl.nama_kegiatan.match(new RegExp(searchValue, "i"))
              )
              .map((galeris) => {
                return (
                  <Col sm style={{ paddingTop: 20 }}>
                    <div key={galeris.id_agenda}>
                      <Card 
                      onClick={() =>
                        DetailGaleri(
                          galeris.id_agenda,
                          galeris.nama_kegiatan
                        )
                      }
                      className="galeri-card">
                        <div className="galeri-card-header">
                          <Image
                            className="galeri-card-images"
                            src={
                              "https://ika.pcr.ac.id/backend_ikapcr/public/storage/agenda/" +
                              galeris.foto
                            }
                            alt={galeris.foto}
                          />
                        </div>
                        <Card.Body className="galeri-card-isi">
                          <div className="galeri-card-cover-isi">

                          <div className="galeri-title">
                            {galeris.nama_kegiatan}
                          </div>
                          </div>
                          {/* <div className="galeri-btn">
                            <Button
                              className="galeri-btn-dt"
                              onClick={() =>
                                DetailGaleri(
                                  galeris.id_agenda,
                                  galeris.nama_kegiatan
                                )
                              }
                            >
                              Lihat <FaArrowRight />
                            </Button>
                          </div> */}
                        </Card.Body>
                      </Card>
                    </div>
                  </Col>
                );
              })}
          </Row>
        </div>
        <div className="pagenation">
          <Paginate
            postsPerPage={postsPerPage}
            totalPosts={galeri.length}
            paginate={paginate}
            previousPage={previousPage}
            nextPage={nextPage}
          />
        </div>
      </Container>
    </div>
  );
}

export default Galeri;
