import React, { useState, useEffect } from 'react'
import { Chart as ChartJs, BarElement, CategoryScale, LinearScale } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import fetch from 'node-fetch';

ChartJs.register(
  CategoryScale,
  LinearScale,
  BarElement
)


const JumlahPertahun = () => {
  const cors = require('cors');
  const [dataAlumni, setDataAlumni] = useState([]);
  const [chart, setChart] = useState({})
  const baseUrl = "/api/api/tracer-alumni?collection=alumni-all&pagesize=10000";
  const optiont = {
    method: "GET",
    headers: {
      apikey: "UnUsYXPyc32yiOKmf71J26XdB0VVuX6c",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  };
  useEffect(() => {
    const fetchalumni = async () => {
      await fetch(baseUrl, optiont).then((response) => {
          if (response.ok) {
            response.json().then((json) => {
              setDataAlumni(json.items);
              // setChart(json.data)
            });
          }
        }).catch((error) => {
          // console.log(error);
        });
    };
    fetchalumni()
  }, [])

  const setTahun = new Set(dataAlumni.map((a) => a.tahun_lulus));
  const tahun = Array.from(setTahun).sort();

  const setAlumniCount = new Set(tahun.map((x) => (
    dataAlumni.filter((obj) => obj.tahun_lulus === x).length)
  )) 
  const alumniCount = Array.from(setAlumniCount);


  var data = {
    labels: tahun.map(x => x),
    datasets: [
      {
        
        label: "",

        backgroundColor: [
          '#f76d02',
        ],
      
        data: alumniCount.map(x=>x),
      },
    ],
  };

  var options = {
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true
      }
    },
    legend: {
     
    }
  }

  return (
    <div>
      <Bar
        data={data}
        height={400}
        options={options}
      ></Bar>
    </div>
  )
}

export default JumlahPertahun
