import React, {  useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {  Container, Row, Col, Card,  Image, Button } from 'react-bootstrap';
import { BsArrowRight } from "react-icons/bs";
import { AiFillHeart } from "react-icons/ai";
import axios from 'axios';
import moment from 'moment/moment';


function BeritaHomepage() {

    const [berita, setBerita] = useState([]);
    const [likes, setLikes] = useState([]);
    const navigate = useNavigate();
    const [like, setLike] = useState([]);
    const [refreshKey, setRefreshKey] = useState(1);

    function handleLikeClick(beritaVal) {
        var likeData = new FormData();
        likeData.append("id_berita", beritaVal);
        likeData.append("id_alumni", sessionStorage.getItem("id_alumni"));
        likeData.append("like", '1');
     
        var checkAlumniLiked = like.some((l) => l.id_alumni === parseInt(sessionStorage.getItem('id_alumni')) && l.id_berita === parseInt(beritaVal));

        if (checkAlumniLiked === true) {
            fetch('https://ika.pcr.ac.id/backend_ikapcr/public/api/like/' + sessionStorage.getItem('id_alumni') + '/' + beritaVal, {
                method: 'GET',
            })
                .then(function (res) {
                }, function (e) {

                });


        } else {
            fetch('https://ika.pcr.ac.id/backend_ikapcr/public/api/like', {
                method: 'POST',
                body: likeData,
            })
                .then(function (res) {
                }, function (e) {
                });
          
                
        }
        setRefreshKey(oldKey => oldKey +1)
    };

    function navigasiBerita(id_berita_value) {
        navigate('/main/beritadetail', { state: { id_berita_current: id_berita_value } });
    }

    function navigateToBerita() {
        navigate("/main/berita");
      }

    useEffect(() => {
        
            axios.get('https://ika.pcr.ac.id/backend_ikapcr/public/api/berita').then((response) => {
                setBerita(response.data);

            });
        
   
            axios.get('https://ika.pcr.ac.id/backend_ikapcr/public/api/like').then((response) => {
                setLikes(response.data);

            });
        
       
            axios.get('https://ika.pcr.ac.id/backend_ikapcr/public/api/likes').then((response) => {
                setLike(response.data);

            });
        
    }, [refreshKey]);




    return (
        <div>
            <Container className='box-beranda'>
                <div className='main-title-beranda'>
                    <h2><strong>Berita Terbaru</strong></h2>
                </div>
                <div>
                <div >
          <div className="lihat-semua-btn">
            <Button className="lihat-semua-btn-dt" onClick={navigateToBerita}>
              Lihat Semua Berita
              <BsArrowRight />
            </Button>
          </div>
        </div>
                </div>
                <div className='konten'>
                    <Row >
                        {berita.slice(0, 4).map((beritas) => {
                            return (
                                <Col key={beritas.id_berita} style={{ paddingTop: 20 }}>
                                    <div className='berita-card animate__slideInUp' >
                                        <Card 
                                        onClick={() => navigasiBerita(beritas.id_berita)}
                                        className='berita-card-border'>
                                            <div className='berita-card-header'>

                                                <Image className='berita-card-images' src={"https://ika.pcr.ac.id/backend_ikapcr/public/storage/berita/" + beritas.gambar} alt={beritas.gambar} />
                                            </div>
                                            <Card.Body>
                                                <div className='berita-card-isi' >
                                                    <Row>
                                                        <Col> <small className="text-muted" >{moment(beritas.tanggal_posting).format('LL')}</small> </Col>
                                                        <Col className='btn-like'>
                                                            <Row>
                                                                <Col><div onClick={() => handleLikeClick(beritas.id_berita)}>
                                                                    <div className='like'> <AiFillHeart /></div>

                                                                </div></Col>
                                                                <Col>
                                                                    <div className='like-count'>
                                                                        {likes.filter((l) => l.id_berita === beritas.id_berita).map((countLike) => {
                                                                            return (
                                                                                <div key={countLike.id_berita}>
                                                                                    {countLike.lik}
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                        </Col>
                                                    </Row>
                                                </div>
                                                <div className='berita-card-cover-isi'>
                                                    <Card.Title className='berita-card-title'>{beritas.judul_berita}</Card.Title>
                                                </div>
                                                <div>
                                                    <div className='berita-card-detail'>
                                                        <div className='berita-btn'>
                                                            <Button className='berita-btn-dt' onClick={() => navigasiBerita(beritas.id_berita)}>
                                                                Baca Berita <BsArrowRight />
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </Col>
                            );
                        })}
                    </Row>
                </div>
            </Container>
        </div>
    );
}


export default BeritaHomepage;