import React, { useEffect, useState } from "react";
import { Card, Table, Col, Row } from "react-bootstrap";
import { FcProcess, FcApproval, FcCancel } from "react-icons/fc";

function StatusInputan() {
  const [lokera, setLokera] = useState([]);
  const [usahaal, setUsahaal] = useState([]);

  useEffect(() => {
    fetch("https://ika.pcr.ac.id/backend_ikapcr/public/api/loker")
      .then((response) => response.json())
      .then((res) => {
        setLokera(res);
      })
      .catch((error) => {
        console.error("Error fetching Loker data:", error);
      });

    fetch("https://ika.pcr.ac.id/backend_ikapcr/public/api/UsahaAlumni")
      .then((response) => response.json())
      .then((res) => {
        setUsahaal(res);
      })
      .catch((error) => {
        console.error("Error fetching Usaha data:", error);
      });
  }, []);

  return (
    <div style={{padding:30}}>
      <Card style={{padding:10}} className="profil-card">
        <div className="profil-notif-isi">
        
          
          {/* Loker */}
          <Row>
          <Col>
          <Card.Header className="profil-card-header">
          <div className="profil-card-title">Status Inputan Loker</div>
        </Card.Header>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>No</th>
                <th>Status</th>
                <th>Judul</th>
              </tr>
            </thead>
            <tbody>
              {lokera
                .filter(
                  (obj) =>
                    obj.id_alumni ===
                    parseInt(sessionStorage.getItem("id_alumni"))
                )
                .map((loker, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      {loker.status === "Proses" && <FcProcess />}
                      {loker.status === "Approve" && <FcApproval />}
                      {loker.status === "Decline" && <FcCancel />}
                    </td>
                    <td>{loker.judul_lowongan}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Col>
        <Col>
        <Card.Header className="profil-card-header">
          <div className="profil-card-title">Status Inputan Usaha</div>
        </Card.Header>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>No</th>
              <th>Status</th>
              <th>Judul</th>
            </tr>
          </thead>
          <tbody>
            {usahaal
              .filter(
                (obj) =>
                  obj.id_alumni ===
                  parseInt(sessionStorage.getItem("id_alumni"))
              )
              .map((usaha, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    {usaha.status === "Approve" && <FcApproval />}
                    {usaha.status === "Proses" && <FcProcess />}
                    {usaha.status === "Decline" && <FcCancel />}
                  </td>
                  <td>{usaha.nama_usaha}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Col>
          </Row>
   
          
        </div>
      </Card>
    </div>
  );
}

export default StatusInputan;
