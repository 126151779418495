import { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";


function EditUsaha({ id_usaha_alumni }) {

  const navigate = useNavigate();

  const [usahaalumnie, setUsahaAlumniE] = useState([]);
  const [nama_usaha, setNamaUsaha] = useState("");
  const [jenis_usaha, setJenisUsaha] = useState("");
  const [alamat_usaha, setAlamatUsaha] = useState("");
  const [pemilik_usaha, setPemilikUsaha] = useState("");
  const [no_hp, setNoHp] = useState("");
  const [email, setEmail] = useState("");
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [link_tree, setLinktree] = useState("");
  const [tiktok, setTiktok] = useState("");
  const [shope, setShope] = useState("");
  const [tokopedia, setTokopedia] = useState("");
  const [youtube, setYoutube] = useState("");
  const [website, setWebsite] = useState("");
  const [foto, setFoto] = useState(null);
  const [deskripsi_usaha, setDeskripsiUsaha] = useState("");
  // ... Other state variables

  useEffect(() => {
    fetch(
      "https://ika.pcr.ac.id/backend_ikapcr/public/api/UsahaAlumni/" +
        id_usaha_alumni
    )
      .then((res) => res.json())
      .then((response) => {
        setUsahaAlumniE(response);
        // Populate state variables based on response
        if (response.length > 0) {
          const usahaData = response[0]; // Assuming you only expect one result
          setNamaUsaha(usahaData.nama_usaha);
          setJenisUsaha(usahaData.jenis_usaha);
          setAlamatUsaha(usahaData.alamat_usaha);
          setPemilikUsaha(usahaData.pemilik_usaha);
          setDeskripsiUsaha(usahaData.deskripsi_usaha);
          setNoHp(usahaData.no_hp);
          setEmail(usahaData.email);
          setFacebook(usahaData.facebook);
          setInstagram(usahaData.instagram);
          setWhatsapp(usahaData.whatsapp);
          setLinktree(usahaData.link_tree);
          setTiktok(usahaData.tiktok);
          setShope(usahaData.shope);
          setTokopedia(usahaData.tokopedia);
          setYoutube(usahaData.youtube);
          setWebsite(usahaData.website);
          setFoto(usahaData.foto);
          // ... Set other state variables
        }
      });
  }, [id_usaha_alumni]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Construct the data object
      const updateData = {
        id_alumni: sessionStorage.getItem("id_alumni"),
        nama_usaha: nama_usaha,
        jenis_usaha: jenis_usaha,
        alamat_usaha: alamat_usaha,
        pemilik_usaha: pemilik_usaha,
        no_hp: no_hp,
        email: email,
        facebook: facebook,
        instagram: instagram,
        whatsapp: whatsapp,
        link_tree: link_tree,
        tiktok: tiktok,
        shope: shope,
        tokopedia: tokopedia,
        youtube: youtube,
        website: website,

        // ... More fields
      };
    

      // Make the PUT request to update the data
      const response = await axios.post(
        `https://ika.pcr.ac.id/backend_ikapcr/public/api/UsahaAlumni/update-data/${id_usaha_alumni}`,
        updateData
      );

      // Show success Swal notification
      Swal.fire("Success", "Data berhasil diperbarui!", "success").then(() => {
        // Redirect to UsahaDetailPanel after Swal is closed
        window.location.reload();
      });
    } catch (error) {
      console.error("Error updating data:", error);
      // Show error Swal notification
      Swal.fire("Error", "Terjadi kesalahan saat memperbarui data.", "error");
    }
  };

  const loadImage = (e) => {
    const image = e.target.files[0];
    setFoto(image);
    setFoto(URL.createObjectURL(image));
  };

  const handleFotoInput = (e) => {
    const image = e.target.files[0];
    setFoto(image);
  };

  return (
    <div>
      {usahaalumnie
        .filter(
          (obj) =>
            obj.id_alumni === parseInt(sessionStorage.getItem("id_alumni"))
        )
        .map((us) => {
       // console.log(sessionStorage.getItem('id_alumni'));
          // console.log(al.id_alumni);
          return ( 
      <div style={{ padding:20}}>
        <Form noValidate onSubmit={handleSubmit} encType="multipart/form-data">
          <Row>
            <Col>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Nama Usaha</Form.Label>
                <Form.Control
                  type="text"
                  id="nama_usaha"
                  value={nama_usaha}
                  onChange={(e) => setNamaUsaha(e.target.value)}
                />
                <Form.Text style={{ color: "red" }}>*required</Form.Text>
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Jenis Usaha</Form.Label>
                <Form.Select
                  type="text"
                  id="status"
                  value={jenis_usaha}
                  onChange={(e) => setJenisUsaha(e.target.value)}
                >
                  <option>Pilih Jenis Usaha</option>
                  <option value="Fashion">Fashion</option>
                  <option value="Food and Beverage">Food and Beverage</option>
                  <option value="Jasa">Jasa</option>
                  <option value="Elektronik">Elektronik</option>
                  <option value="Lainnya">Lainnya..</option>
                </Form.Select>
                <Form.Text style={{ color: "red" }}>*required</Form.Text>
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Alamat Usaha</Form.Label>
                <Form.Control
                  type="text"
                  id="alamat_usaha"
                  value={alamat_usaha}
                  onChange={(e) => setAlamatUsaha(e.target.value)}
                />
                <Form.Text style={{ color: "red" }}>*required</Form.Text>
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Form.Text style={{ color: "red" }}>*required</Form.Text>
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>No Hp</Form.Label>
                <Form.Control
                  type="text"
                  id="no_hp"
                  value={no_hp}
                  onChange={(e) => setNoHp(e.target.value)}
                />
                <Form.Text style={{ color: "red" }}>*required</Form.Text>
              </Form.Group>
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Inputkan Gambar Usaha</Form.Label>
                <Form.Control
                  type="file"
                  id="foto_usaha"
                  onChange={handleFotoInput}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Deskripis</Form.Label>
                <Form.Control
                  style={{ borderRadius: 10 }}
                  as="textarea"
                  rows={3}
                  placeholder="deskripsikan usaha anda..."
                  id="deskripsi_usaha"
                  value={deskripsi_usaha}
                  onChange={(e) => setDeskripsiUsaha(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Facebook</Form.Label>
                <Form.Control
                  type="text"
                  id="facebook"
                  value={facebook}
                  onChange={(e) => setFacebook(e.target.value)}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Instagram</Form.Label>
                <Form.Control
                  type="text"
                  id="instagram"
                  value={instagram}
                  onChange={(e) => setInstagram(e.target.value)}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Whatsapp</Form.Label>
                <Form.Control
                  type="text"
                  id="whatsapp"
                  value={whatsapp}
                  onChange={(e) => setWhatsapp(e.target.value)}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Tiktok</Form.Label>
                <Form.Control
                  type="text"
                  id="tiktok"
                  value={tiktok}
                  onChange={(e) => setTiktok(e.target.value)}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Biolink</Form.Label>
                <Form.Control
                  type="text"
                  id="linktree"
                  value={link_tree}
                  onChange={(e) => setLinktree(e.target.value)}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Shopee</Form.Label>
                <Form.Control
                  type="text"
                  id="shope"
                  value={shope}
                  onChange={(e) => setShope(e.target.value)}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Tokopedia</Form.Label>
                <Form.Control
                  type="text"
                  id="tokopedia"
                  value={tokopedia}
                  onChange={(e) => setTokopedia(e.target.value)}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Youtube</Form.Label>
                <Form.Control
                  type="text"
                  id="youtube"
                  value={youtube}
                  onChange={(e) => setYoutube(e.target.value)}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Website</Form.Label>
                <Form.Control
                  type="text"
                  id="website"
                  value={website}
                  onChange={(e) => setWebsite(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
            <div style={{display:'flex', justifyContent:'end'}}>
            <Button type="submit" onClick={() => handleSubmit()}>
              Update
            </Button>
            </div>
          {/* <Button variant="secondary" onClick={handleClose}>
        Close
      </Button> */}
        </Form>
      </div>
       );
      })}
    </div>
  );
}

export default EditUsaha;
