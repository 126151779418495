import React, { Component } from 'react';
import HeroIkaberbagi from './IkapcrBerbagiHero';
import BeritaHomepage from '../pages/isi-beranda/BeritaHomepage';
import UsahaAlumniHomepage from '../pages/isi-beranda/UsahaAlumniHomepage';
import AgendaHomepage from '../pages/isi-beranda/AgendaHomepage';
import VisiMisiHomepage from '../pages/isi-beranda/VisiMisiHomepage';
import GaleriHomepage from '../pages/isi-beranda/GaleriHomepage';
import KisahAlumniHomepage from '../pages/isi-beranda/KisahAlumniHomepage';
import LinkTerkait from './LinkTerkait';
import KetuaHero from '../pages/isi-beranda/KetuaHero';




class Main extends Component {
    render() {
        return (
            <div style={{ backgroundColor: '#f6f8fd' }} 
            classNameName="animate__animated animate__fadeInUpBig">
                {/* <LinkTerkait/> */}
                <KetuaHero/>
                <BeritaHomepage />
                <VisiMisiHomepage />
                <KisahAlumniHomepage />
                <AgendaHomepage/>
                <UsahaAlumniHomepage/>
                <div style={{paddingTop:'70px'}}>
                <HeroIkaberbagi/>
                </div>
                <GaleriHomepage/>
         
            </div >
        );
    }
}

export default Main;