import React, { Component } from 'react';
import { Container, Card, Table, Col, Row } from 'react-bootstrap';

class ArtiLogo extends Component {
    render() {
        return (
            <div>
                
                <Container className='box'>
                    <div className='main-title'>
                        <h1><strong>Arti Lambang IKAPCR</strong></h1>
                    </div>
                <div>
                    <Row>
                        <Col></Col>
                        <Col></Col>
                    </Row>
                </div>
                </Container>
            </div>
        );
    }
}

export default ArtiLogo;