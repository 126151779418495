import React, { useState, useEffect } from "react";
import { Nav, Navbar, NavDropdown, Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import LogoImage from "../assets/images/logoikapcr2.png";
import ProfilImage from "../assets/images/profilakun.png";
import jwt_decode from "jwt-decode";

function NavigationBar() {
  const [user, setUser] = useState({});
  const [colorChange, setColorchange] = useState(false);
  const location = useLocation();
  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);

  const google = window.google;

  function handleCallbackResponse(response) {
    var userObject = jwt_decode(response.credential);
    setUser(userObject);
    document.getElementById("SignInDiv").hidden = true;
  }

  useEffect(() => {
    google.accounts.id.initialize({
      client_id:
        "778985182345-1eltaiv2tqj0mjqckmtb45cssb5ou6jr.apps.googleusercontent.com",
      callback: handleCallbackResponse,
    });

    google.accounts.id.renderButton(document.getElementById("SignInDiv"), {
      theme: "outline",
      size: "large",
    });
  }, []);

  return (
    <div>
      <Navbar
        className={colorChange ? "navbar colorChange" : "navbar"}
        expand="lg"
        fixed="top"
      >
        <Container>
          <Navbar.Brand href="/">
            <img src={LogoImage} className="d-inline-block align-top" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="justify-content-end flex-grow-1 pe-3"
              activeKey={location.pathname}
            >
              <Nav.Link href="/main">Beranda</Nav.Link>
              <NavDropdown title="Profil" id="navbarScrollingDropdown">
                <NavDropdown.Header
                  className="dropdown-header"
                  style={{ paddingTop: 1 }}
                ></NavDropdown.Header>
                <NavDropdown.Item href="../main/sekapursirih">
                  Sekapur Sirih
                </NavDropdown.Item>
                <NavDropdown.Item href="../main/visimisi">Visi Misi</NavDropdown.Item>
                <NavDropdown.Item href="/main/strukturorganisai">
                  Struktur Organisasi
                </NavDropdown.Item>
                <NavDropdown.Item href="../main/progjadepartment">
                  Program Kerja
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="../main/berita">Berita</Nav.Link>
              <Nav.Link href="../main/agenda">Agenda</Nav.Link>
              <NavDropdown title="Alumni" id="navbarScrollingDropdown">
                <NavDropdown.Header
                  className="dropdown-header"
                  style={{ paddingTop: 1 }}
                ></NavDropdown.Header>
                <NavDropdown.Item href="../main/kisahalumni">
                  Kisah Alumni
                </NavDropdown.Item>
                <NavDropdown.Item href="../main/statistik">Statistik</NavDropdown.Item>
                <NavDropdown.Item href="../main/usahaalumni">
                  Ikapcr Preneur
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title="Galeri" id="navbarScrollingDropdown">
                <NavDropdown.Header
                  className="dropdown-header"
                  style={{ paddingTop: 1 }}
                ></NavDropdown.Header>
                <NavDropdown.Item href="../main/galeri">Foto</NavDropdown.Item>
                <NavDropdown.Item href="https://www.youtube.com/@ikapcr4196">
                  Video
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="../main/loker">Loker</Nav.Link>
              <Nav.Link href="../main/faq">FAQ</Nav.Link>
              <Nav.Link href="../main/kontak">Kontak</Nav.Link>
            </Nav>
            <Nav className="justify-content-end flex-grow-1 pe-3">
              <Nav.Link href="../main/login">
                <Navbar.Brand>
                  <img src={ProfilImage} />
                </Navbar.Brand>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default NavigationBar;
