import React from 'react'
import { useState } from 'react';
import {FaArrowCircleUp} from 'react-icons/fa';
import { Button } from 'react-bootstrap';

function ScrollButton() {

    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300){
          setVisible(true)
        } 
        else if (scrolled <= 300){
          setVisible(false)
        }
      };
      
      const scrollToTop = () =>{
        window.scrollTo({
          top: 0, 
          behavior: 'smooth'
        });
      };
      
      window.addEventListener('scroll', toggleVisible);

  return (
    <div>
       <Button className='scroll'  onClick={scrollToTop} 
     style={{display: visible ? 'inline' : 'none'}}>
     <FaArrowCircleUp />
    </Button>
    </div>
  )
}

export default ScrollButton
