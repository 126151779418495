import React, { useEffect, useState } from "react";
import { Container, Row, Col, Image, Table, Nav} from "react-bootstrap";
import axios from "axios";
import { useLocation, useNavigate, Link} from "react-router-dom";

function UsahaAlumniDetail() {
  const [usahaalumniD, setUAD] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();

//   function navigateToBiolink() {
//     fetch("https://ika.pcr.ac.id/backend_ikapcr/public/api/UsahaAlumni/" +
//     location.state.id_usaha_alumni_current
//     )
//     .then(response => response.json())
//     .then((data) => {
//       window.open(data.link_tree, '_blank');
//     })
//     .catch((error) => console.error("oops:",error));
// }

  useEffect(() => {
    axios
      .get(
        "https://ika.pcr.ac.id/backend_ikapcr/public/api/UsahaAlumni/" +
          location.state.id_usaha_alumni_current
      )
      .then((response) => {
        setUAD(response.data);
      });
  }, []);

  return (
    <div>
      {usahaalumniD.map((uadetail) => {
        return (
          <div key={uadetail.id_usaha_alumni}>
             <div style={{ paddingTop: "70px" }}>
        <div className="main-title">
          {/* <h1>
            <strong>Visi Misi</strong>
          </h1> */}
        </div>
      </div>
            <Container className="box">
            
              <div>
                <Row>
                  <Col sm>
                    <Image
                      className="agenda-detail-gambar-isi"
                      src={
                        "https://ika.pcr.ac.id/backend_ikapcr/public/storage/UsahaAlumni/" +
                        uadetail.foto_usaha
                      }
                      alt={uadetail.gambar}
                    />
                  </Col>
                  <Col>
                    <div className="usaha-alumni-title-detail">
                      <p className="usaha-alumni-title-detail-text">
                        <strong>{uadetail.nama_usaha}</strong>
                      </p>
                    </div>
                    <div>
                      <div>
                        <Table id="uadetail">
                          <thead>
                            <th colSpan={2}>Owner</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td rowSpan={3}>
                                <div className="uadetail-img-owner">
                                  <Image
                                    roundedCircle
                                    width={50}
                                    src={
                                      "https://ika.pcr.ac.id/backend_ikapcr/public/storage/alumni/" +
                                      uadetail.foto
                                    }
                                    alt={uadetail.foto}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="uadetail-td">
                                  <p>{uadetail.nama}</p>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="uadetail-td">
                                  <p>{uadetail.prodi}</p>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="uadetail-td">
                                  <p>Generasi {uadetail.generasi}</p>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                      <div className="uadetail-row">
                        <div className="uadetail-label">Kategori :</div>
                        <div className="uadetail-text">
                          {uadetail.jenis_usaha}
                        </div>
                      </div>
                      <div className="uadetail-row">
                        <div className="uadetail-label">Alamat :</div>
                        <div className="uadetail-text">
                          {uadetail.alamat_usaha}
                        </div>
                      </div>
                      <div className="uadetail-row">
                        <div className="uadetail-label">No HP :</div>
                        <div className="uadetail-text">{uadetail.no_hp}</div>
                      </div>
                      <div className="uadetail-row">
                        <div className="uadetail-label">Email :</div>
                        <div className="uadetail-text">{uadetail.email}</div>
                      </div>
                      <div className="uadetail-row">
                        <div className="uadetail-label">Biolink :</div>
                        <div className="uadetail-text" 
                        >

                        <a href={uadetail.link_tree} target="_blank">{uadetail.link_tree}</a>
                        </div>
                      </div>

                      <Row className="uadetail-row">
                        <div sm={6} className="uadetail-label">
                          Tentang Product Ini :
                        </div>
                        <div className="uadetail-text-deskripsi">
                          {uadetail.deskripsi_usaha}
                        </div>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
        );
      })}
    </div>
  );
}

export default UsahaAlumniDetail;
