import React, { useState, useEffect } from 'react'
import { Chart as ChartJs, ArcElement, CategoryScale, LinearScale } from 'chart.js'
import { Line } from 'react-chartjs-2'

ChartJs.register(
  ArcElement
)

const DomisiliBekerja = () => {
  const [dataAlumni, setDataAlumni] = useState([]);
  const [chart, setChart] = useState({})
  const baseUrl = "/api/api/tracer-alumni?collection=alumni-all&pagesize=10000&fields=nama,foto,jenis_kelamin,agama, tempat_lahir,tgl_lahir, w.prop as ktp_alamat_prop, w.kec as ktp_alamat_kec, w.desa as ktp_alamat_kel, w.kab as ktp_alamat_kab, alamat_jl as ktp_alamat_jln, w2.prop as domisili_sekarang_prop, w2.kec as domisili_sekarang_kec, w2.desa as domisili_sekarang_kel, w2.kab as domisili_sekarang_kab, domisili_sekarang_jl as domisili_sekarang_jln, nama_prodi, angkatan,tahun_lulus, tempat_bekerja, alamat_kantor";
  const optiont = {
    method: "GET",
    headers: {
      apikey: "UnUsYXPyc32yiOKmf71J26XdB0VVuX6c",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  };
  useEffect(() => {
    const fetchalumni = async () => {
      await fetch(baseUrl, optiont).then((response) => {
          if (response.ok) {
            response.json().then((json) => {
              setDataAlumni(json.items);
              // setChart(json.data)
            });
          }
        }).catch((error) => {
          // console.log(error);
        });
    };
    fetchalumni()
  }, [])

  const setProdi = new Set(dataAlumni.map((a) => a.domisili_sekarang_prop));
  const domisili_sekarang_prop = Array.from(setProdi).filter((obj) => obj);

  const setAlumniCount = new Set(domisili_sekarang_prop.map((x) => (
    dataAlumni.filter((obj) => obj.domisili_sekarang_prop === x).length)
  )) 
  const alumniCount = Array.from(setAlumniCount);


  var data = {
    labels: domisili_sekarang_prop.map(x => x),
    datasets: [{
      label: 'My First Dataset',
      data: alumniCount.map(x=>x),
      backgroundColor: [
        'rgb(255, 99, 132)',
        'rgb(54, 162, 235)',
        'rgb(255, 205, 86)'
      ],
      hoverOffset: 4
    }]
  };

  var options = {
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true
      }
    },
    legend: {
     
    }
  }

  return (
    <div>
      <Line
        data={data}
        height={400}
        // options={options}
      ></Line>
    </div>
  )
}

export default DomisiliBekerja
