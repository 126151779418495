import React, {  useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  Image,
  Button,
} from "react-bootstrap";
import { BsArrowRight } from "react-icons/bs";
import axios from "axios";


function UsahaAlumniHomepage() {

    const [usahaalumni, setUA] = useState([]);
    const navigate = useNavigate();
  

    function navigateToUADetail(id_usaha_alumni_value) {
      navigate("/main/usahaalumnidetail", {
        state: { id_usaha_alumni_current: id_usaha_alumni_value },
      });
    }

    function navigateToUA() {
      navigate("/usahaalumni");
    }
  
    useEffect(() => {
      axios
        .get("https://ika.pcr.ac.id/backend_ikapcr/public/api/UsahaAlumni")
        .then((response) => {
          setUA(response.data);
        });
    }, []);
  

  

  

  

  return (
    <div>
        <Container className='box-beranda'>
        <div className='main-title-beranda'>
                    <h2><strong>Usaha Alumni</strong></h2>
                </div>
                <div >
          <div className="lihat-semua-btn">
            <Button className="lihat-semua-btn-dt" onClick={navigateToUA}>
              Lihat Semua Usaha
              <BsArrowRight />
            </Button>
          </div>
        </div>
                <div className="konten">
          <Row>
            {usahaalumni.slice(0, 4)
            .filter((usahaalumni) => usahaalumni.status == "Approve")
              .map((usahaalumnis) => {
                return (
                  <Col style={{ paddingTop: 20 }}>
                    <div
                      key={usahaalumnis.id_usaha_alumni}
                      className="usaha-alumni-card"
                    >
                      <Card 
                       onClick={() =>
                        navigateToUADetail(usahaalumnis.id_usaha_alumni)
                      }
                      
                      className="usaha-alumni-card-border">
                        <div className="usaha-alumni-card-header">
                          <Image
                            className="usaha-alumni-card-images"
                            src={
                              "https://ika.pcr.ac.id/backend_ikapcr/public/storage/UsahaAlumni/" +
                              usahaalumnis.foto_usaha
                            }
                            alt={usahaalumnis.gambar}
                          />
                        </div>
                        <Card.Body>
                          <div className="usaha-alumni-card-isi">
                            <small className="text-muted">
                              {usahaalumnis.nama}
                            </small>
                          </div>
                          <div className="usaha-alumni-card-title">
                            <Card.Title>{usahaalumnis.nama_usaha}</Card.Title>
                          </div>
                          <div className="usaha-alumni-btn">


                            <Button
                              className="usaha-alumni-btn-detail"
                              onClick={() =>
                                navigateToUADetail(usahaalumnis.id_usaha_alumni)
                              }
                            >
                              Lihat
                            </Button>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </Col>
                );
              })}
          </Row>
        </div>
        </Container>
    </div>
  )
}

export default UsahaAlumniHomepage