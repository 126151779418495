import React from "react";

function PanelNavbar() {
  return (
    <div>
      <nav className="main-header navbar navbar-expand navbar-white navbar-light">
      {/* Left navbar links */}
      <ul className="navbar-nav">
        <li className="nav-item">
          <a
            className="nav-link"
            data-widget="pushmenu"
            href="/"
            role="button"
          >
            <i className="fas fa-bars" />
          </a>
        </li>
      </ul>

    
    </nav>
    </div>
  );
}

export default PanelNavbar;
