import React, {  useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Image } from 'react-bootstrap';
import axios from 'axios';

function RecentPost() {

    const [berita, setBerita] = useState([]);
    const navigate = useNavigate();

    function navigasiBerita(id_berita_value) {
        navigate('/beritadetail', { state: { id_berita_current: id_berita_value } });
    }

    useEffect(() => {
        axios.get('https://pengawas.ika.pcr.ac.id/api/berita').then((response) => {
            setBerita(response.data);

        });
    }, []);

    return (
        <div>
            <Container style={{ padding: 30 }}>
                <div className='main-title' style={{ fontFamily: 'SF Pro Display' }}>
                    <h4><strong>Recent Post</strong></h4>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Row >
                        {berita.slice(0, 5).map((beritar) => {
                            return (
                                <div style={{ width: '100%' }}>
                                        <Row>
                                            <Col style={{marginBottom:10}}>
                                                <Image style={{ width: '120px' }} src={"https://pengawas.ika.pcr.ac.id/storage/berita/"+beritar.gambar} alt={beritar.gambar}></Image>                                         </Col>
                                            <Col sm={7} style={{marginLeft:-10}}>
                                                <div 
                                                style={{textAlign:'justify', fontSize:'11px'}}
                                                onClick={() => navigasiBerita(beritar.id_berita)}>
                                                  <p> {beritar.judul_berita}</p> 
                                                </div>
                                            </Col>
                                        </Row>

                                </div>
                            );
                        })}
                    </Row>
                </div>
            </Container>
        </div>
    );
}

export default RecentPost;
