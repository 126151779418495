import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

function LokerDetail () {

    const [lokerd, setLokerD] = useState([]);
    const location = useLocation();


    useEffect(() => {
        axios.get('https://ika.pcr.ac.id/backend_ikapcr/public/api/loker/'
         + location.state.id_loker_current)
            .then((response) => {
                setLokerD(response.data);
            });
    }
        , []);



        return (
            <div>  {lokerd.map((lokerde) => {
                        return (
                <div key={lokerde.id_loker}>
                     <div style={{ paddingTop: "70px" }}>
        <div className="main-title">
          <h2>
          <strong>{lokerde.judul_lowongan}</strong>
          </h2>
        </div>
      </div>
                <Container style={{ padding: 30 }}>
                    <div>
                        <Row>
                            <Col>
                            <Image 
                            className='loker-card-detail-images' 
                            src={"https://ika.pcr.ac.id/backend_ikapcr/public/storage/loker/" + lokerde.gambar} alt={lokerde.gambar} />
                            </Col>
                            <Col>
                                <p>
                                   {ReactHtmlParser(lokerde.deskipsi)}
                                </p> <br></br>
                            </Col>
                        </Row>
                    </div>
                </Container>
                    
                </div>    )
                    })}
            </div>
        );
    }

export default LokerDetail;