import React, { useEffect, useState } from "react";
import { Row, Col, Image, Container, Card } from "react-bootstrap";
import VisiMisiImage from "../../assets/images/visimisi.png";
import axios from "axios";
import ReactHTMLParser from "react-html-parser";

function VisiMisi() {
  const [profilikapcr, setProfilIkapcr] = useState([]);

  useEffect(() => {
    axios
      .get("https://ika.pcr.ac.id/backend_ikapcr/public/api/ProfilIkapcr")
      .then((response) => {
        setProfilIkapcr(response.data);
      });
  }, []);

  return (
    <div>
      <div style={{ paddingTop: "70px" }}>
        <div className="main-title">
          <h1>
            <strong>Visi Misi</strong>
          </h1>
        </div>
      </div>
      <Container style={{ padding: 30 }}>
        <Card className="ketua-card">
          <Row>
            <Col sm>
              {profilikapcr.map((profils) => {
                return (
                  <div key={profils.id_profil} style={{ textAlign: "justify" }}>
                    <div>
                      <h5 className="visi-misi-tittle">
                        <strong>
                          <p
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {" "}
                            VISI
                          </p>
                          <p
                            style={{
                              fontSize: "19px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            Ikatan Keluarga Alumni Politeknik Caltex Riau
                          </p>
                        </strong>
                      </h5>
                    </div>

                    <div>
                      <p className="visi-misi-text-beranda">
                        {ReactHTMLParser(profils.visi)}
                      </p>
                    </div>
                    <div>
                      <h5 className="visi-misi-tittle">
                        <strong>
                          <p
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            MISI
                          </p>
                          <p
                            style={{
                              fontSize: "19px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            Ikatan Keluarga Alumni Politeknik Caltex Riau
                          </p>
                        </strong>
                      </h5>
                      <div style={{ marginLeft: "-15px" }}>
                        <p className="visi-misi-text-beranda">
                          {ReactHTMLParser(profils.misi)}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Col>
            <Col sm>
              <Image src={VisiMisiImage} style={{ width: "100%" }}></Image>
            </Col>
          </Row>
        </Card>
      </Container>
    </div>
  );
}

export default VisiMisi;
