import React from 'react';
import Container from 'react-bootstrap/Container';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Button} from 'react-bootstrap';
import HeroImage from '../assets/images/hero1.png';
import BgImage from '../assets/images/bg1.png';
import 'animate.css'


function Hero() {
  const navigate = useNavigate();

  function navigateToDaftar(){
      navigate('/login');
  };

  return (
    <div style={{ backgroundImage: `url(${BgImage})`, width:'100%' }}>
      <Container className='box-hero'>
        <Row >
          <Col sm={6} style={{ paddingTop: 40 }} className="animate__animated animate__fadeInUpBig">
            <p className='hero-title'>Ikatan Alumni<br></br>
              Politeknik Caltex Riau</p>
            <h5 className='hero-sub-title'>
              Cara mudah agar saling terhubung antara <br></br>
              sesama alumni Politeknik Caltex Riau
            </h5>
            <Button className='btn-hero'
            onClick={navigateToDaftar}
            >
              Gabung
            </Button>
          </Col>
          <Col sm={6} style={{ }} className="animate__animated animate__fadeIn">
            <img src={HeroImage} style={{ width: '100%' }}></img>
          </Col>
        </Row>
      </Container>
      {/* <ProfilHero/> */}
    </div>
    
  );
}
export default Hero;