import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Card, Image, Button } from "react-bootstrap";
import axios from "axios";
import Paginate from "../../inc/Paginate";
import { BiSearchAlt } from "react-icons/bi";

function UsahaAlumni() {
  const [usahaalumni, setUA] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(6);
  const [selectedJenisUsaha, setSelectedJenisUsaha] = useState(""); // State for selected jenis usaha

  const navigate = useNavigate();

  function navigateToUADetail(id_usaha_alumni_value) {
    navigate("/main/usahaalumnidetail", {
      state: { id_usaha_alumni_current: id_usaha_alumni_value },
    });
  }

  useEffect(() => {
    fetch("https://ika.pcr.ac.id/backend_ikapcr/public/api/UsahaAlumni")
      .then((response) => response.json())
      .then((res) => {
        console.log(res);
        setUA(res);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [1]);

  const [searchValue, setSearchValue] = useState("");
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = usahaalumni.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const previousPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== Math.ceil(usahaalumni.length / postsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div>
      <div style={{ paddingTop: "70px" }}>
        <div className="main-title">
          <h1>
            <strong>Usaha Alumni</strong>
          </h1>
        </div>
      </div>
      <Container style={{ padding: 30 }}>
      <Row>
      <Col sm={8}>
      <div className="search">
      <div className="search-box">
        <Button className="btn-search">
          <BiSearchAlt />
        </Button>
        <input
          className="input-search"
          type="text"
          name="search"
          placeholder="Type to Search..."
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
    
      </div>
    </div>
      </Col>
      <Col>
      <div className="select">
      <select
      // className="input-select"
      value={selectedJenisUsaha}
      onChange={(e) => setSelectedJenisUsaha(e.target.value)}
    >
      <option value="">Select Jenis Usaha</option>
      {/* Replace with your actual jenis_usaha values */}
      <option value="Wedding Invitation">Wedding Invitation</option>
      <option value="Fashion">Fashion</option>
      <option value="Food and Beverage">Food and Beverage</option>
      <option value="Jasa">Jasa</option>
      <option value="Elektronik">Elektronik</option>
      <option value="Lainnya">Lainnya..</option>
      {/* Add more options as needed */}
    </select>
      </div>
      </Col>
      </Row>
  

        <div className="konten">
          <Row>
            {currentPosts
              .filter((usahaalumni) => usahaalumni.status === "Approve")
              .filter((uajud) => {
                return (
                  (uajud.nama_usaha.match(new RegExp(searchValue, "i")) ||
                    uajud.nama.match(new RegExp(searchValue, "i"))) &&
                  (selectedJenisUsaha === "" ||
                    uajud.jenis_usaha === selectedJenisUsaha)
                );
              })
              .map((usahaalumnis) => {
                return (
                  <Col style={{ paddingTop: 20 }}>
                    <div
                      key={usahaalumnis.id_usaha_alumni}
                      className="usaha-alumni-card"
                    >
                      <Card
                        onClick={() =>
                          navigateToUADetail(usahaalumnis.id_usaha_alumni)
                        }
                        className="usaha-alumni-card-border"
                      >
                        <div className="usaha-alumni-card-header">
                          <Image
                            className="usaha-alumni-card-images"
                            src={
                              "https://ika.pcr.ac.id/backend_ikapcr/public/storage/UsahaAlumni/" +
                              usahaalumnis.foto_usaha
                            }
                            alt={usahaalumnis.gambar}
                          />
                        </div>
                        <Card.Body>
                          <div className="usaha-alumni-card-isi">
                            <small className="text-muted">
                              {usahaalumnis.nama}
                            </small>
                          </div>
                          <div className="usaha-alumni-card-title">
                            <div>{usahaalumnis.nama_usaha}</div>
                          </div>
                          <div className="usaha-alumni-btn">
                            <Button
                              className="usaha-alumni-btn-detail"
                              onClick={() =>
                                navigateToUADetail(usahaalumnis.id_usaha_alumni)
                              }
                            >
                              Lihat
                            </Button>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </Col>
                );
              })}
          </Row>
        </div>

        <div className="pagenation">
          <Paginate
            postsPerPage={postsPerPage}
            totalPosts={usahaalumni.length}
            paginate={paginate}
            previousPage={previousPage}
            nextPage={nextPage}
          />
        </div>
      </Container>
    </div>
  );
}

export default UsahaAlumni;
