import { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import axios from "axios";

function EditProfilAlumni() {
  const [validated, setValidated] = useState(false);
  const [dataWilayah, setDataWilayah] = useState([]);
  const [selectedProvinsi, setSelectedProvinsi] = useState("");
  const [selectedKabupaten, setSelectedKabupaten] = useState("");

  const handleSubmit = (event) => {
    // event.preventDefault();
    // const form = event.currentTarget;
    // if (form.checkValidity() === false) {
    //   event.preventDefault();
    //   event.stopPropagation();
    // }
    // setValidated(true);

    var data = new FormData();
    var fotodata = document.getElementById("gambar").files[0];
    // var dataAlumni = document.getElementById('id_alumni');

    data.append("nama", nama);
    data.append("jenis_kelamin", jenis_kelamin);
    data.append("agama", agama);
    data.append("jenis_kelamin", jenis_kelamin);
    data.append("tempat_lahir", tempat_lahir);
    data.append("tanggal_lahir", tanggal_lahir);
    data.append("alamat", alamat);
    data.append("provinsi", provinsi);
    data.append("kota", kota);
    data.append("kecamatan", kecamatan);
    data.append("kelurahan", kelurahan);
    data.append("no_hp", no_hp);
    data.append("email_alumni", email_alumni);
    data.append("password", password);
    data.append("jurusan", jurusan);
    data.append("prodi", prodi);
    data.append("generasi", generasi);
    data.append("status_pekerjaan", status_pekerjaan);
    data.append("nama_kantor", nama_kantor);
    data.append("alamat_kantor", alamat_kantor);
    data.append("provinsi_kantor", provinsi_kantor);
    data.append("kota_kantor", kota_kantor);
    data.append("divisi", divisi);
    data.append("jabatan", jabatan);
    if (fotodata !== undefined) {
      data.append("foto", fotodata);
    }

    // data.append("id_alumni",sessionStorage.getItem('id_alumni'));
    console.log(data);

    fetch(
      "https://ika.pcr.ac.id/backend_ikapcr/public/api/alumni/update-data/" +
        sessionStorage.getItem("id_alumni"),
      {
        method: "POST",
        body: data,
      }
    ).then((res) => res.json());
  };

  // fetch(`https://www.emsifa.com/api-wilayah-indonesia/api/provinces.json`)
  //   .then((response) => response.json())
  //   .then((provinces) => console.log(provinces));

  const [alumnie, setAlumni] = useState([]);
  useEffect(() => {
    axios
      .get(
        "https://ika.pcr.ac.id/backend_ikapcr/public/api/alumni/" +
          sessionStorage.getItem("id_alumni")
      )
      .then((response) => {
        setAlumni(response.data);
        // console.log(response.data);
      });
  }, []);

  useEffect(() => {
    axios
      .get("https://www.emsifa.com/api-wilayah-indonesia/api/provinces.json")
      .then((response) => {
        setDataWilayah(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleProvinsiChange = (provinsi) => {
    setSelectedProvinsi(provinsi);
    setSelectedKabupaten(""); // Reset kabupaten ketika memilih provinsi baru
  };

  const handleKabupatenChange = (kabupaten) => {
    setSelectedKabupaten(kabupaten);
  };

  const [nama, setNama] = useState("");
  const [jenis_kelamin, setJenisKelamin] = useState("");
  const [agama, setAgama] = useState("");
  const [tempat_lahir, setTempatLahir] = useState("");
  const [tanggal_lahir, setTanggalLahir] = useState("");
  const [alamat, setAlamat] = useState("");
  const [provinsi, setProvinsi] = useState("");
  const [kota, setKota] = useState("");
  const [kecamatan, setKecamatan] = useState("");
  const [kelurahan, setKelurahan] = useState("");
  const [no_hp, setNoHp] = useState("");
  const [email_alumni, setEmailAlumni] = useState("");
  const [password, setPassword] = useState("");
  const [jurusan, setJurusan] = useState("");
  const [prodi, setProdi] = useState("");
  const [generasi, setGerasi] = useState("");
  const [status_pekerjaan, setStatusPekerjaan] = useState("");
  const [nama_kantor, setNamaKantor] = useState("");
  const [alamat_kantor, setAlamatKantor] = useState("");
  const [provinsi_kantor, setProvinsiKantor] = useState("");
  const [kota_kantor, setKotaKantor] = useState("");
  const [divisi, setDivisi] = useState("");
  const [jabatan, setJabatan] = useState("");
  const [gambar, setGambar] = useState("");

  const loadImage = (e) => {
    const image = e.target.files[0];
    setGambar(image);
    setGambar(URL.createObjectURL(image));
  };

  const handleFotoInput = (e) => {
    setGambar(e.target.files[0]);
  };

  return (
    <Container>
      <Row>
        <Col>
          {alumnie
            .filter(
              (obj) =>
                obj.id_alumni === parseInt(sessionStorage.getItem("id_alumni"))
            )
            .map((al) => {
              // console.log(sessionStorage.getItem('id_alumni'));
              // console.log(al.id_alumni);
              return (
                <div style={{ marginLeft: 20 }} key={al.id_alumni}>
                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                    encType="multipart/form-data"
                  >
                    <h4>*Data Diri</h4>
                    <Row>
                      <Col>
                        <Form.Group controlId="validationCustom01">
                          <Form.Label>Nama Lengkap</Form.Label>

                          <Form.Control
                            required
                            type="text"
                            placeholder="Nama Lengkap"
                            id="nama"
                            // value={al.nama}
                            onChange={(e) => setNama(e.target.value)}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Tempat Lahir</Form.Label>
                          <Form.Control
                            required
                            id="tempat_lahir"
                            type="text"
                            placeholder="Tempat Lahir"
                            // value={al.tempat_lahir}
                            onChange={(e) => setTempatLahir(e.target.value)}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Tanggal Lahir</Form.Label>
                          <Form.Control
                            required
                            id="tanggal_lahir"
                            type="date"
                            placeholder="Tanggal Lahir"
                            // value={al.tanggal_lahir}
                            onChange={(e) => setTanggalLahir(e.target.value)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>Jenis Kelamin</Form.Label>
                          <Form.Select
                            required
                            id="jenis_kelamin"
                            type="text"
                            // value={al.jenis_kelamin}
                            onChange={(e) => setJenisKelamin(e.target.value)}
                          >
                            <option>Pilih Jenis Kelamin</option>
                            <option value="Perempuan">Perempuan</option>
                            <option value="Laki-laki">Laki-laki</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Agama</Form.Label>
                          <Form.Select
                            required
                            type="text"
                            id="agama"
                            // value={al.agama}
                            onChange={(e) => setAgama(e.target.value)}
                          >
                            <option>Pilih Agama</option>
                            <option value="Islam">Islam</option>
                            <option value="Kristen Protestan">
                              Kristen Protestan
                            </option>
                            <option value="Kristen Katolik">
                              Kristen Katolik
                            </option>
                            <option value="Budha">Budha</option>
                            <option value="Konghuchu">Konghuchu</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Alamat</Form.Label>
                          <Form.Control
                            required
                            id="alamat"
                            type="text"
                            placeholder="Alamat"
                            // value={al.alamat}
                            onChange={(e) => setAlamat(e.target.value)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>Nomor Whatshapp</Form.Label>
                          <Form.Control
                            required
                            type="number"
                            id="no_hp"
                            placeholder="Nomor HP"
                            // value={al.no_hp}
                            onChange={(e) => setNoHp(e.target.value)}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Provinsi</Form.Label>

                          <Form.Control
                            required
                            id="provinsi"
                            type="text"
                            placeholder="Provinsi"
                            // value={al.provinsi}
                            onChange={(e) => setProvinsi(e.target.value)}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Kota/Kabupaten</Form.Label>
                          <Form.Select
                            required
                            id="provinsi"
                            value={selectedProvinsi}
                            onChange={handleProvinsiChange}
                          >
                            <option value="">Pilih Provinsi</option>
                            {dataWilayah.map((provinsi) => (
                              <option
                                key={provinsi.attributes.Kode_Provi}
                                value={provinsi.attributes.Provinsi}
                              >
                                {provinsi.attributes.Provinsi}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>Kecamatan</Form.Label>
                          <Form.Control
                            required
                            id="kecamatan"
                            type="text"
                            placeholder="Kecamatan"
                            // value={al.kecamatan}
                            onChange={(e) => setKecamatan(e.target.value)}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Kelurahan</Form.Label>
                          <Form.Control
                            required
                            id="kelurahan"
                            type="text"
                            placeholder="Kelurahan"
                            // value={al.kelurahan}
                            onChange={(e) => setKelurahan(e.target.value)}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="validationCustom01">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            required
                            id="email_alumni"
                            type="email"
                            placeholder="Email"
                            // value={al.email_alumni}
                            onChange={(e) => setEmailAlumni(e.target.value)}
                          />
                          <Form.Text style={{ color: "red" }}>
                            *required, inputkan ulang email
                          </Form.Text>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      {/* <Col>
                                            <Form.Group>
                                                <Form.Label>Username</Form.Label><Form.Control
                                                    required
                                                    id='username'
                                                    type="username"
                                                    placeholder="Username"
                                                    onChange={(e) => setUsername(e.target.value)}
                                                />

                                            </Form.Group>
                                        </Col> */}
                      <Col>
                        <Form.Group>
                          <Form.Label>Password</Form.Label>
                          <Form.Control
                            required
                            id="password"
                            type="password"
                            placeholder="Password"
                            // value={al.password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <Form.Text style={{ color: "red" }}>
                            *required, update ulang password
                          </Form.Text>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="formFile" className="mb-3">
                          <Form.Label>Inputkan Foto Profil</Form.Label>
                          <Form.Control
                            type="file"
                            id="gambar"
                            name="gambar"
                            onChange={handleFotoInput}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <div style={{ paddingBottom: 20, paddingTop: 10 }}>
                      <h4>*Data Kampus</h4>
                      <Row>
                        <Col>
                          <Form.Group>
                            <Form.Label>Jurusan</Form.Label>
                            <Form.Select
                              required
                              type="text"
                              // value={al.jurusan}
                              onChange={(e) => setJurusan(e.target.value)}
                            >
                              <option>Pilih Jurusan</option>
                              <option value="Jurusan Teknologi Informasi">
                                Jurusan Teknologi Informasi
                              </option>
                              <option value="Jurusan Teknik Industri">
                                Jurusan Teknik Industri
                              </option>
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label>Prodi</Form.Label>
                            <Form.Select
                              required
                              type="text"
                              value={al.prodi}
                              onChange={(e) => setProdi(e.target.value)}
                            >
                              <option>Pilih Prodi</option>
                              <option value="D3-Akuntansi">D3-Akuntansi</option>
                              <option value="D3-Teknik Telekomunikasi">
                                D3-Teknik Telekomunikasi
                              </option>
                              <option value="D3-Teknik Elektronika">
                                D3-Teknik Elektronika
                              </option>
                              <option value="D3-Teknik Mekatronika">
                                D3-Teknik Mekatronika
                              </option>
                              <option value="D3-Teknik Komputer">
                                D3-Teknik Komputer
                              </option>
                              <option value="D4-Akuntansi Perpajakan">
                                D4-Akuntansi Perpajakan
                              </option>
                              <option value="D4-Sistem Informasi">
                                D4-Sistem Informasi
                              </option>
                              <option value="D4-Teknik Informatika">
                                D4-Teknik Informatika
                              </option>
                              <option value="D4-Teknik Rekayasa Komputer">
                                D4-Teknik Rekayasa Komputer
                              </option>
                              <option value="D4-Teknik Elektronika Telekomunikasi">
                                D4-Teknik Elektronika Telekomunikasi
                              </option>
                              <option value="D4-Teknik Rekayasa Jaringan Telekomunikasi">
                                D4-Teknik Rekayasa Jaringan Telekomunikasi
                              </option>
                              <option value="D4-Teknik Rekayasa Mekatronika">
                                D4-Teknik Rekayasa Mekatronika
                              </option>
                              <option value="D4-Teknik Mesin">
                                D4-Teknik Mesin
                              </option>
                              <option value="D4-Teknik Listrik">
                                D4-Teknik Listrik
                              </option>
                              <option value="D4-Teknik Rekayasa Sistem Elektronika">
                                D4-Teknik Rekayasa Sistem Elektronika
                              </option>
                              <option value="S2-Magister Terapan Teknik Komputer">
                                S2-Magister Terapan Teknik Komputer
                              </option>
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label>Generasi</Form.Label>
                            <Form.Control
                              required
                              type="text"
                              placeholder="Generasi 19"
                              // value={al.generasi}
                              onChange={(e) => setGerasi(e.target.value)}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                    <div style={{ paddingBottom: 20 }}>
                      <h4>*Data Pekerjaan</h4>
                      <Row>
                        <Col>
                          <Form.Group>
                            <Form.Label>Status Pekerjaan</Form.Label>
                            <Form.Select
                              required
                              type="text"
                              value={al.status_pekerjaan}
                              onChange={(e) =>
                                setStatusPekerjaan(e.target.value)
                              }
                            >
                              <option>Status</option>
                              <option value="Bekerja">Bekerja</option>
                              <option value="Belum Bekerja">
                                Belum Bekerja
                              </option>
                              <option value="Freelance">Freelance</option>
                              <option value="Wirausaha">Wirausaha</option>
                              <option value="Ibu Rumah Tangga (IRT)">
                                Ibu Rumah Tangga (IRT)
                              </option>
                              <option value="Dll">Dll</option>
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label>Nama Kantor</Form.Label>
                            <Form.Control
                              required
                              type="text"
                              placeholder="Nama Kantor"
                              // value={al.nama_kantor}
                              onChange={(e) => setNamaKantor(e.target.value)}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label>Alamat</Form.Label>
                            <Form.Control
                              required
                              type="text"
                              placeholder="Alamat"
                              // value={al.alamat_kantor}
                              onChange={(e) => setAlamatKantor(e.target.value)}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group>
                            <Form.Label>Provinsi</Form.Label>
                            <Form.Control
                              required
                              type="text"
                              placeholder="Provinsi"
                              // value={al.provinsi_kantor}
                              onChange={(e) =>
                                setProvinsiKantor(e.target.value)
                              }
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label>Kota</Form.Label>
                            <Form.Control
                              required
                              type="text"
                              placeholder="Kota"
                              // value={al.kota_kantor}
                              onChange={(e) => setKotaKantor(e.target.value)}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label>Divisi/Bidang</Form.Label>
                            <Form.Control
                              required
                              type="text"
                              placeholder="Divisi"
                              // value={al.divisi}
                              onChange={(e) => setDivisi(e.target.value)}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label>Jabatan</Form.Label>
                            <Form.Control
                              required
                              type="text"
                              placeholder="Jabatan"
                              // value={al.jabatan}
                              onChange={(e) => setJabatan(e.target.value)}
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          marginTop: 10,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            backgroundColor: "#0d6efd",
                            borderRadius: 5,
                            color: "white",
                            width: "60px",
                            height: "40px",
                          }}
                        >
                          <Button type="submit" onClick={() => handleSubmit()}>
                            Update
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              );
            })}
        </Col>
      </Row>
    </Container>
  );
}

export default EditProfilAlumni;
