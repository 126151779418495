import React, {  useEffect, useState } from 'react';
import { Container, Image } from 'react-bootstrap';
import '../../assets/css/GlobalStyling.css'
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';


function KisahAlumniDetail() {
    const [kisahalumniD, setKA] = useState([]);
    const location = useLocation();


    useEffect(() => {
        axios.get('https://ika.pcr.ac.id/backend_ikapcr/public/api/KisahAlumni/' 
        + location.state.id_kisah_alumni_current)
            .then((response) => {
                setKA(response.data);
            });
    }
        , []);

    return (
        <div>
            {kisahalumniD.map((ka) => {
                return (
                    <div key={ka.id_kisah_alumni}>
                         <div style={{ paddingTop: "70px" }}>
        <div className="main-title">
          <h1>
          <strong>{ka.nama}</strong>
          </h1>
        </div>
      </div>
                        <Container style={{ padding: 30 }}>
         
                            <div >
                                <div>
                                    <div className='kisah-alumni-images-detail'>                                      
                                    <Image className='kisah-alumni-images' roundedCircle src={"https://ika.pcr.ac.id/backend_ikapcr/public/storage/KisahAlumni/" + ka.foto_kisah} alt={ka.foto_kisah}></Image>
                                    </div>
                                    <div className='kisah-alumni-detail'>
                                        {ReactHtmlParser(ka.deskripsi_kisah)}
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </div>
                );
            })}
        </div>
    );
}

export default KisahAlumniDetail;