import { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";

function EditProfilPanel() {
  const [validated, setValidated] = useState(false);
  const [dataWilayah, setDataWilayah] = useState([]);
  const [dataKota, setDataKota] = useState([]);
  const [dataKecamatan, setDataKecamatan] = useState([]);
  const [dataKelurahan, setDataKelurahan] = useState([]);

  const handleSubmit = (event) => {
    // event.preventDefault();
    // const form = event.currentTarget;
    // if (form.checkValidity() === false) {
    //   event.preventDefault();
    //   event.stopPropagation();
    // }
    // setValidated(true);

    var data = new FormData();
    var fotodata = document.getElementById("gambar").files[0];
    // var dataAlumni = document.getElementById('id_alumni');

    data.append("nama", nama);
    data.append("jenis_kelamin", jenis_kelamin);
    data.append("agama", agama);
    data.append("jenis_kelamin", jenis_kelamin);
    data.append("tempat_lahir", tempat_lahir);
    data.append("tanggal_lahir", tanggal_lahir);
    data.append("alamat", alamat);
    data.append("provinsi", provinsi);
    data.append("kota", kota);
    data.append("kecamatan", kecamatan);
    data.append("kelurahan", kelurahan);
    data.append("no_hp", no_hp);
    data.append("email_alumni", email_alumni);
    data.append("password", password);
    data.append("jurusan", jurusan);
    data.append("prodi", prodi);
    data.append("generasi", generasi);
    data.append("status_pekerjaan", status_pekerjaan);
    data.append("nama_kantor", nama_kantor);
    data.append("alamat_kantor", alamat_kantor);
    data.append("provinsi_kantor", provinsi_kantor);
    data.append("kota_kantor", kota_kantor);
    data.append("divisi", divisi);
    data.append("jabatan", jabatan);
    if (fotodata !== undefined) {
      data.append("foto", fotodata);
    }

    // data.append("id_alumni",sessionStorage.getItem('id_alumni'));
    console.log(data);

    fetch(
      "https://ika.pcr.ac.id/backend_ikapcr/public/api/alumni/update-data/" +
        sessionStorage.getItem("id_alumni"),
      {
        method: "POST",
        body: data,
      }
    ).then((res) => res.json())
    .then((response) => {
      // Tampilkan SweetAlert2 dengan pesan sukses
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Data berhasil diperbarui!",
        timer: 2000,
        showConfirmButton: false,
      });

      // Setelah beberapa saat, arahkan pengguna ke halaman sebelumnya
      setTimeout(() => {
        window.location.href = "/panel"; // Change this to the actual path
      }, 2000);
    });
  };

  // fetch(`https://www.emsifa.com/api-wilayah-indonesia/api/provinces.json`)
  //   .then((response) => response.json())
  //   .then((provinces) => console.log(provinces));

  const [alumnie, setAlumni] = useState([]);
  useEffect(() => {
    axios
      .get(
        "https://ika.pcr.ac.id/backend_ikapcr/public/api/alumni/" +
          sessionStorage.getItem("id_alumni")
      )
      .then((response) => {
        setAlumni(response.data);
        const data = response.data[0]; // Assuming the API returns an array
        setNama(data.nama);
        setJenisKelamin(data.jenis_kelamin);
        setAgama(data.agama);
        setTempatLahir(data.tempat_lahir);
        setTanggalLahir(data.tanggal_lahir);
        setAlamat(data.alamat);
        setProvinsi(data.provinsi);
        setKota(data.kota);
        setKecamatan(data.kecamatan);
        setKelurahan(data.kelurahan);
        setNoHp(data.no_hp);
        setEmailAlumni(data.email_alumni);
        setPassword(data.password);
        setJurusan(data.jurusan);
        setProdi(data.prodi);
        setGerasi(data.generasi);
        setStatusPekerjaan(data.status_pekerjaan);
        setNamaKantor(data.nama_kantor);
        setAlamatKantor(data.alamat_kantor);
        setProvinsiKantor(data.provinsi_kantor);
        setKotaKantor(data.kota_kantor);
        setDivisi(data.divisi);
        setJabatan(data.jabatan);
      });
  }, []);


  useEffect(() => {
    fetch(
      `https://staggingabsensi.labura.go.id/api-wilayah-indonesia/static/api/provinces.json`
    )
      .then((response) => response.json())
      .then((provinces) => {
        setDataWilayah(provinces);
        console.log(provinces);
      });
  }, []);



  const handleProvinsiChange = (kota) => {
    fetch(
      `https://staggingabsensi.labura.go.id/api-wilayah-indonesia/static/api/regencies/${kota}.json`
    )
      .then((response) => response.json())
      .then((kota) => {
        setDataKota(kota);
        console.log(kota);
      });

    const prov = dataWilayah.find((a) => a.id === kota);
    setProvinsi(prov.name);
  };

  const handleKabupatenChange = (kecamatan) => {
    fetch(
      `https://staggingabsensi.labura.go.id/api-wilayah-indonesia/static/api/districts/${kecamatan}.json`
    )
      .then((response) => response.json())
      .then((kecamatan) => {
        setDataKecamatan(kecamatan);
        console.log(kecamatan);
      });

    const kota = dataKota.find((a) => a.id === kecamatan);
    setKota(kota.name);
  };


  const handleKecamatanChange = (kelurahan) => {
    fetch(
      `https://staggingabsensi.labura.go.id/api-wilayah-indonesia/static/api/villages/${kelurahan}.json`
    )
      .then((response) => response.json())
      .then((kelurahan) => {
        setDataKelurahan(kelurahan);
        console.log(kelurahan);
      });

    const kecamatan = dataKecamatan.find((a) => a.id === kelurahan);
    setKecamatan(kecamatan.name);
  };

  const handleKelurahanChange = (kel) => {
    fetch(
      `https://staggingabsensi.labura.go.id/api-wilayah-indonesia/static/api/villages/${kel}.json`
    )
      .then((response) => response.json())
      .then((kel) => {
        setDataKelurahan(kel);
        console.log(kel);
      });

    const kelurahan = dataKelurahan.find((a) => a.id === kel);
    setKelurahan(kelurahan.name);
  };

  const [nama, setNama] = useState("");
  const [jenis_kelamin, setJenisKelamin] = useState("");
  const [agama, setAgama] = useState("");
  const [tempat_lahir, setTempatLahir] = useState("");
  const [tanggal_lahir, setTanggalLahir] = useState("");
  const [alamat, setAlamat] = useState("");
  const [provinsi, setProvinsi] = useState("");
  const [kota, setKota] = useState("");
  const [kecamatan, setKecamatan] = useState("");
  const [kelurahan, setKelurahan] = useState("");
  const [no_hp, setNoHp] = useState("");
  const [email_alumni, setEmailAlumni] = useState("");
  const [password, setPassword] = useState("");
  const [jurusan, setJurusan] = useState("");
  const [prodi, setProdi] = useState("");
  const [generasi, setGerasi] = useState("");
  const [status_pekerjaan, setStatusPekerjaan] = useState("");
  const [nama_kantor, setNamaKantor] = useState("");
  const [alamat_kantor, setAlamatKantor] = useState("");
  const [provinsi_kantor, setProvinsiKantor] = useState("");
  const [kota_kantor, setKotaKantor] = useState("");
  const [divisi, setDivisi] = useState("");
  const [jabatan, setJabatan] = useState("");
  const [gambar, setGambar] = useState("");

  const loadImage = (e) => {
    const image = e.target.files[0];
    setGambar(image);
    setGambar(URL.createObjectURL(image));
  };

  const handleFotoInput = (e) => {
    setGambar(e.target.files[0]);
  };

  return (
    <Container>
      <Row>
        <Col>
          {alumnie
            .filter(
              (obj) =>
                obj.id_alumni === parseInt(sessionStorage.getItem("id_alumni"))
            )
            .map((al) => {
              // console.log(sessionStorage.getItem('id_alumni'));
              // console.log(al.id_alumni);
              return (
                <div style={{ padding: 20 }} key={al.id_alumni}>
                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                    encType="multipart/form-data"
                  >
                    <h4>*Data Diri</h4>
                    <Row>
                      <Col>
                        <Form.Group controlId="validationCustom01">
                          <Form.Label>Nama Lengkap</Form.Label>

                          <Form.Control
                            required
                            type="text"
                            placeholder="Nama Lengkap"
                            id="nama"
                            value={nama}
                            onChange={(e) => setNama(e.target.value)}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Tempat Lahir</Form.Label>
                          <Form.Control
                            required
                            id="tempat_lahir"
                            type="text"
                            placeholder="Tempat Lahir"
                            value={tempat_lahir}
                            onChange={(e) => setTempatLahir(e.target.value)}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Tanggal Lahir</Form.Label>
                          <Form.Control
                            required
                            id="tanggal_lahir"
                            type="date"
                            placeholder="Tanggal Lahir"
                            value={tanggal_lahir}
                            onChange={(e) => setTanggalLahir(e.target.value)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>Jenis Kelamin</Form.Label>
                          <Form.Select
                            required
                            id="jenis_kelamin"
                            type="text"
                            value={jenis_kelamin}
                            onChange={(e) => setJenisKelamin(e.target.value)}
                          >
                            <option>Pilih Jenis Kelamin</option>
                            <option value="Perempuan">Perempuan</option>
                            <option value="Laki-laki">Laki-laki</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Agama</Form.Label>
                          <Form.Select
                            required
                            type="text"
                            id="agama"
                            value={agama}
                            onChange={(e) => setAgama(e.target.value)}
                          >
                            <option>Pilih Agama</option>
                            <option value="Islam">Islam</option>
                            <option value="Kristen Protestan">
                              Kristen Protestan
                            </option>
                            <option value="Kristen Katolik">
                              Kristen Katolik
                            </option>
                            <option value="Budha">Budha</option>
                            <option value="Konghuchu">Konghuchu</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Alamat</Form.Label>
                          <Form.Control
                            required
                            id="alamat"
                            type="text"
                            placeholder="Alamat"
                            value={alamat}
                            onChange={(e) => setAlamat(e.target.value)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>Nomor Whatshapp</Form.Label>
                          <Form.Control
                            required
                            type="number"
                            id="no_hp"
                            placeholder="Nomor HP"
                            value={no_hp}
                            onChange={(e) => setNoHp(e.target.value)}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Provinsi</Form.Label>

                          <Form.Select
                            required
                            id="provinsi"
                            value={provinsi}
                            onChange={(event) =>
                              handleProvinsiChange(event.target.value)
                            }
                          >
                            <option value="">Pilih Provinsi</option>
                            {dataWilayah.map((provinsi) => (
                              <option key={provinsi.id} value={provinsi.id}>
                                {provinsi.name}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Kota/Kabupaten</Form.Label>
                          <Form.Select
                            required
                            id="kota"
                            value={kota}
                            onChange={(event) =>
                              handleKabupatenChange(event.target.value)
                            }
                          >
                            <option value="">Pilih Kota/Kabupaten</option>
                            {dataKota.map((kota) => (
                              <option key={kota.id} value={kota.id}>
                                {kota.name}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>Kecamatan</Form.Label>
                          <Form.Select
                            required
                            id="kecamatan"
                            value={kecamatan}
                            onChange={(event) =>
                              handleKecamatanChange(event.target.value)
                            }
                          >
                            <option value="">Pilih Kecamatan</option>
                            {dataKecamatan.map((kecamatan) => (
                              <option key={kecamatan.id} value={kecamatan.id}>
                                {kecamatan.name}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Kelurahan</Form.Label>
                          <Form.Select
                            required
                            id="kelurahan"
                            value={kelurahan}
                            onChange={(event) =>
                              handleKelurahanChange(event.target.value)
                            }
                          >
                            <option value="">Pilih Kelurahan</option>
                            {dataKelurahan.map((kel) => (
                              <option key={kel.id} value={kel.id}>
                                {kel.name}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="validationCustom01">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            required
                            id="email_alumni"
                            type="email"
                            placeholder="Email"
                            value={email_alumni}
                            onChange={(e) => setEmailAlumni(e.target.value)}
                          />
                         
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
 
                      {/* <Col>
                        <Form.Group>
                          <Form.Label>Password</Form.Label>
                          <Form.Control
                            required
                            id="password"
                            type="password"
                            placeholder="Password"
                            // value={al.password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                       
                        </Form.Group>
                      </Col> */}
                      <Col>
                        <Form.Group controlId="formFile" className="mb-3">
                          <Form.Label>Inputkan Foto Profil</Form.Label>
                          <Form.Control
                            type="file"
                            id="gambar"
                            name="gambar"
                            onChange={handleFotoInput}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <div style={{ paddingBottom: 20, paddingTop: 10 }}>
                      <h4>*Data Kampus</h4>
                      <Row>
                        <Col>
                          <Form.Group>
                            <Form.Label>Jurusan</Form.Label>
                            <Form.Select
                              required
                              type="text"
                              value={jurusan}
                              onChange={(e) => setJurusan(e.target.value)}
                            >
                              <option>Pilih Jurusan</option>
                              <option value="Jurusan Teknologi Informasi">
                                Jurusan Teknologi Informasi
                              </option>
                              <option value="Jurusan Teknik Industri">
                                Jurusan Teknik Industri
                              </option>
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label>Prodi</Form.Label>
                            <Form.Select
                              required
                              type="text"
                              value={prodi}
                              onChange={(e) => setProdi(e.target.value)}
                            >
                              <option>Pilih Prodi</option>
                              <option value="D3-Akuntansi">D3-Akuntansi</option>
                              <option value="D3-Teknik Telekomunikasi">
                                D3-Teknik Telekomunikasi
                              </option>
                              <option value="D3-Teknik Elektronika">
                                D3-Teknik Elektronika
                              </option>
                              <option value="D3-Teknik Mekatronika">
                                D3-Teknik Mekatronika
                              </option>
                              <option value="D3-Teknik Komputer">
                                D3-Teknik Komputer
                              </option>
                              <option value="D4-Akuntansi Perpajakan">
                                D4-Akuntansi Perpajakan
                              </option>
                              <option value="D4-Sistem Informasi">
                                D4-Sistem Informasi
                              </option>
                              <option value="D4-Teknik Informatika">
                                D4-Teknik Informatika
                              </option>
                              <option value="D4-Teknik Rekayasa Komputer">
                                D4-Teknik Rekayasa Komputer
                              </option>
                              <option value="D4-Teknik Elektronika Telekomunikasi">
                                D4-Teknik Elektronika Telekomunikasi
                              </option>
                              <option value="D4-Teknik Rekayasa Jaringan Telekomunikasi">
                                D4-Teknik Rekayasa Jaringan Telekomunikasi
                              </option>
                              <option value="D4-Teknik Rekayasa Mekatronika">
                                D4-Teknik Rekayasa Mekatronika
                              </option>
                              <option value="D4-Teknik Mesin">
                                D4-Teknik Mesin
                              </option>
                              <option value="D4-Teknik Listrik">
                                D4-Teknik Listrik
                              </option>
                              <option value="D4-Teknik Rekayasa Sistem Elektronika">
                                D4-Teknik Rekayasa Sistem Elektronika
                              </option>
                              <option value="S2-Magister Terapan Teknik Komputer">
                                S2-Magister Terapan Teknik Komputer
                              </option>
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label>Generasi</Form.Label>
                            <Form.Control
                              required
                              type="text"
                              placeholder="Generasi 19"
                              value={generasi}
                              onChange={(e) => setGerasi(e.target.value)}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                    <div style={{ paddingBottom: 20 }}>
                      <h4>*Data Pekerjaan</h4>
                      <Row>
                        <Col>
                          <Form.Group>
                            <Form.Label>Status Pekerjaan</Form.Label>
                            <Form.Select
                              required
                              type="text"
                              value={status_pekerjaan}
                              onChange={(e) =>
                                setStatusPekerjaan(e.target.value)
                              }
                            >
                              <option>Status</option>
                              <option value="Bekerja">Bekerja</option>
                              <option value="Belum Bekerja">
                                Belum Bekerja
                              </option>
                              <option value="Freelance">Freelance</option>
                              <option value="Wirausaha">Wirausaha</option>
                              <option value="Ibu Rumah Tangga (IRT)">
                                Ibu Rumah Tangga (IRT)
                              </option>
                              <option value="Dll">Dll</option>
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label>Nama Kantor</Form.Label>
                            <Form.Control
                              required
                              type="text"
                              placeholder="Nama Kantor"
                              value={nama_kantor}
                              onChange={(e) => setNamaKantor(e.target.value)}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label>Alamat</Form.Label>
                            <Form.Control
                              required
                              type="text"
                              placeholder="Alamat"
                              value={alamat_kantor}
                              onChange={(e) => setAlamatKantor(e.target.value)}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group>
                            <Form.Label>Provinsi</Form.Label>
                            
                            <Form.Control
                              required
                              type="text"
                              placeholder="Provinsi"
                              value={provinsi_kantor}
                              onChange={(e) =>
                                setProvinsiKantor(e.target.value)
                              }
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label>Kota</Form.Label>
                            <Form.Control
                              required
                              type="text"
                              placeholder="Kota"
                              value={kota_kantor}
                              onChange={(e) => setKotaKantor(e.target.value)}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label>Divisi/Bidang</Form.Label>
                            <Form.Control
                              required
                              type="text"
                              placeholder="Divisi"
                              value={divisi}
                              onChange={(e) => setDivisi(e.target.value)}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label>Jabatan</Form.Label>
                            <Form.Control
                              required
                              type="text"
                              placeholder="Jabatan"
                              value={jabatan}
                              onChange={(e) => setJabatan(e.target.value)}
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          marginTop: 10,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            backgroundColor: "#0d6efd",
                            borderRadius: 5,
                            color: "white",
                            width: "60px",
                            height: "40px",
                          }}
                        >
                          <Button type="submit" onClick={() => handleSubmit()}>
                            Update
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              );
            })}
        </Col>
      </Row>
    </Container>
  );
}

export default EditProfilPanel;
