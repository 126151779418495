import React, { Component } from "react";
import { Col, Row, Container, Image, Nav, Navbar, Card } from "react-bootstrap";
import {
  FaInstagram,
  FaFacebookF,
  FaTelegram,
  FaWhatsapp,
} from "react-icons/fa";
import PlaystoreImage from "../assets/images/playstore.png";
import LogoFooterImage from "../assets/images/logofooter.png";
import bgfooter from "../assets/images/bgfooter.png";

class Footer extends Component {
  render() {
    return (
      <div className="footer" style={{ backgroundImage: `url(${bgfooter})` }}>
        <Container>
          <Row className="box">
            <Col sm={5}>
              <div>
                <Image width={170} src={LogoFooterImage}></Image>
              </div>
              <div className="footer-text">
                <p className="footer-text">
                  IKAPCR adalah Ikatan Alumni Politeknik Caltex Riau yang
                  bertujuan sebagai wadah organisasi alumni yang berbasis
                  kewirausahaan serta bermanfaat bagi alumni, mahasiswa, kampus
                  dan masyarakat{" "}
                </p>
              </div>
              <div>
                <div className="footer-icons">
                  <Row>
                    <Col>
                      <Nav.Link
                        href="https://www.instagram.com/ikapcr/"
                        style={{ color: "white" }}
                      >
                        <FaInstagram />
                      </Nav.Link>
                    </Col>
                    <Col>
                      <Nav.Link href="/" style={{ color: "white" }}>
                        <FaTelegram />
                      </Nav.Link>
                    </Col>
                    <Col>
                      <Nav.Link
                        href="https://www.facebook.com/IkatanAlumniPCR"
                        style={{ color: "white" }}
                      >
                        <FaFacebookF />
                      </Nav.Link>
                    </Col>
                    <Col>
                      <Nav.Link href="/" style={{ color: "white" }}>
                        <FaWhatsapp />
                      </Nav.Link>
                    </Col>
                  </Row>
                </div>
                <div className="footer-ps">
                  <Nav.Link href="https://play.google.com/store/apps/details?id=ikapcr.awpmedia.android">
                    <Navbar.Brand>
                      <img src={PlaystoreImage} />
                    </Navbar.Brand>
                  </Nav.Link>
                </div>
              </div>
            </Col>
            <Col sm style={{ marginTop: 20 }}>
              <Row>
                <Col>
                  {" "}
                  <h6>IKAPCR</h6>
                  <Nav.Link
                    href="/"
                    style={{ color: "#ffffff", fontSize: "13px" }}
                  >
                    {" "}
                    Beranda
                  </Nav.Link>
                  <Nav.Link
                    href="/"
                    style={{ color: "#ffffff", fontSize: "13px" }}
                  >
                    Galeri
                  </Nav.Link>
                  <Nav.Link
                    href="/"
                    style={{ color: "#ffffff", fontSize: "13px" }}
                  >
                    Tentang Alumni
                  </Nav.Link>
                </Col>
                <Col>
                  {" "}
                  <h6>LINK TERKAIT</h6>
                  <Nav.Link
                    href="/"
                    style={{ color: "#ffffff", fontSize: "13px" }}
                  >
                    Beasiswa IKAPCR
                  </Nav.Link>
                  <Nav.Link
                    href="/"
                    style={{ color: "#ffffff", fontSize: "13px" }}
                  >
                    Donasi
                  </Nav.Link>
                  <Nav.Link
                    href="/"
                    style={{ color: "#ffffff", fontSize: "13px" }}
                  >
                    PCR
                  </Nav.Link>
                  <Nav.Link
                    href="/"
                    style={{ color: "#ffffff", fontSize: "13px" }}
                  >
                    Sumatra Career Center
                  </Nav.Link>
                  <Nav.Link
                    href="/"
                    style={{ color: "#ffffff", fontSize: "13px" }}
                  >
                    Tracer PCR
                  </Nav.Link>
                </Col>
              </Row>
            </Col>
            <Col sm style={{ marginTop: 20 }}>
              <div>
                <h6>KONTAK KAMI</h6>
                <p style={{ fontSize: "13px" }}>Sekretariat IKAPCR</p>
                <p style={{ fontSize: "13px" }}>
                  Jl. Umban Sari (Patin) No. 1 Rumbai <br />
                  Pekanbaru-Riau 28265 <br />
                  Telp : (0761) - cxxxxxx
                </p>
                <Row style={{ marginTop: -10 }}>
                  <Col xs={6} style={{ fontSize: "13px" }}>
                    ikapcr@ikapcr.ac.id
                  </Col>
                  <Col xs={6} style={{ fontSize: "13px" }}>
                    +62 9092 1920
                  </Col>
                  <Col>
               
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="footer-copyright">
          © IKAPCR 2023 - All right reserved.
        </div>
      </div>
    );
  }
}

export default Footer;
