import React, { useEffect, useState } from "react";
import { Container, Table } from "react-bootstrap";
import axios from "axios";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";

function ProgjaDepartment() {
  const [progja, setProgja] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const totalPages = Math.ceil(progja.length / itemsPerPage);

  useEffect(() => {
    axios
      .get("https://ika.pcr.ac.id/backend_ikapcr/public/api/progja")
      .then((response) => {
        setProgja(response.data);
      });
  }, []);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const itemsToDisplay = progja.slice(startIndex, endIndex);

  return (
    <div>
      <div style={{ paddingTop: "70px" }}>
        <div className="main-title">
          <h1>
            <strong>Progja Department</strong>
          </h1>
        </div>
      </div>
      <Container className="box">
        <Table responsive id="progjadept">
          <thead>
            <tr>
              <th>No</th>
              <th>Nama Kegiatan</th>
              <th>Department</th>
              <th>Dilaksanakan Tanggal</th>
              <th>Keterangan</th>
              <th>Status</th>
            </tr>
          </thead>
          {itemsToDisplay.map((progjas, index) => {
            const tableIndex = startIndex + index + 1;
            return (
              <tbody key={progjas.id_progja}>
                <tr>
                  <td className="pl-6">{tableIndex}</td>
                  <td>{ReactHtmlParser(progjas.progja)}</td>
                  <td>{progjas.nama_departement}</td>
                  <td>{moment(progjas.tanggal).format("LL")}</td>
                  <td>{ReactHtmlParser(progjas.keterangan)}</td>
                  <td>{ReactHtmlParser(progjas.status)}</td>
                </tr>
              </tbody>
            );
          })}
        </Table>
        <div className="pagination">
        <button
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <div className="page-numbers">
          {[...Array(totalPages)].map((_, pageNumber) => (
            <button
              key={pageNumber}
              className={currentPage === pageNumber + 1 ? "active" : ""}
              onClick={() => setCurrentPage(pageNumber + 1)}
            >
              {pageNumber + 1}
            </button>
          ))}
        </div>
        <button
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
      </Container>
    </div>
  );
}

export default ProgjaDepartment;
