import React, { useEffect, useState } from "react";
import { Container, Card, Image, Row, Col, Nav, Tab } from "react-bootstrap";
import axios from "axios";

function StrukturOrganisasi() {
  const [struktur, setStruktur] = useState([]);

  useEffect(() => {
    axios
      .get("https://ika.pcr.ac.id/backend_ikapcr/public/api/organisasi")
      .then((response) => {
        setStruktur(response.data);
      });
  }, []);

  return (
    <div>
      <div>
      <div style={{ paddingTop: "70px" }}>
        <div className="main-title">
          <h1>
            <strong>Struktur Organisasi</strong>
          </h1>
          <h3 style={{color:"white"}}>IKAPCR PERIODE 2021 – 2024</h3>
        </div>
      </div>
        <Container style={{ padding: 30 }}>
         
          <div style={{}}>
            <Tab.Container id="left-tabs-example" defaultActiveKey="1">
              <Row>
                <Col sm={3}>
                  <Nav variant="pills" className="flex-column" id="struktur">
                    <Nav.Item>
                      <Nav.Link eventKey="1">Ketua IKAPCR </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="2">Inti Kepengurusan</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="3">
                        Departemen Sinergi & Acara
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="4">
                        Departement Pendidikan & Olahraga
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="5">
                        Departement Ristek & Sosial Media
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="6">
                        Departement Sosial & Agama
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="7">Departement Wirausaha</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="8">
                        Departement Humas & Kerjasama
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col sm={9}>
                  <Tab.Content id="struktur">
                    <Tab.Pane eventKey="1">
                      <div>
                        {struktur
                          .filter(
                            (struktur) => struktur.jabatan == "Ketua IKAPCR"
                          )
                          .filter(
                            (struktur) => struktur.tahun_menjabat == "2021-2024"
                          )
                          .map((strukturs) => {
                            return (
                              <div className="struktur-ketua">
                                <div className="struktur-card">
                                  <Card className="struktur-border-card">
                                    <Image
                                      className="struktur-images"
                                      roundedCircle
                                      src={
                                        "https://ika.pcr.ac.id/backend_ikapcr/public/storage/organisasi/" +
                                        strukturs.foto
                                      }
                                      alt={strukturs.foto}
                                    />
                                    <Card.Body>
                                      <div
                                        className="struktur-title-card"
                                        style={{ marginTop: -30 }}
                                      >
                                        <p> {strukturs.nama}</p>
                                      </div>
                                      <div className="struktur-jabatan ">
                                        <small>{strukturs.jabatan}</small>
                                      </div>
                                      <div>
                                        <div className="struktur-text-card">
                                          <small>
                                            {strukturs.tahun_menjabat}
                                          </small>
                                        </div>
                                      </div>
                                    </Card.Body>
                                  </Card>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                      <div className="struktur-ketua-a">
                        <p>
                          ==================== Ketua IKAPCR Periode Sebelumnya
                          ====================
                        </p>
                      </div>
                      <div>
                        <Row>
                          {struktur
                            .filter(
                              (struktur) => struktur.jabatan == "Ketua IKAPCR"
                            )
                            .map((strukturs) => {
                              return (
                                <Col Col sm={4} className="profil-notif">
                                  <div className="struktur-card-ketua">
                                    <Card className="struktur-border-card">
                                      <Image
                                        className="struktur-images"
                                        roundedCircle
                                        src={
                                          "https://ika.pcr.ac.id/backend_ikapcr/public/storage/organisasi/" +
                                          strukturs.foto
                                        }
                                        alt={strukturs.foto}
                                      />
                                      <Card.Body>
                                        <div
                                          className="struktur-title-card"
                                          style={{ marginTop: -30 }}
                                        >
                                          <p> {strukturs.nama}</p>
                                        </div>
                                        <div className="struktur-jabatan ">
                                          <small>
                                            {strukturs.tahun_menjabat}
                                          </small>
                                        </div>
                                      </Card.Body>
                                    </Card>
                                  </div>
                                </Col>
                              );
                            })}
                        </Row>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="2">
                      <div>
                        <Row>
                          {struktur
                            .filter(
                              (struktur) =>
                                struktur.departement == "Inti IKAPCR"
                            )
                            .filter(
                              (struktur) => struktur.tahun_menjabat == "2021-2024"
                            )
                            .map((strukturs) => {
                              return (
                                <Col sm={4} style={{ paddingTop: "20px" }}>
                                  <div className="struktur-card">
                                    <Card className="struktur-border-card">
                                      <Image
                                        className="struktur-images"
                                        roundedCircle
                                        src={
                                          "https://ika.pcr.ac.id/backend_ikapcr/public/storage/organisasi/" +
                                          strukturs.foto
                                        }
                                        alt={strukturs.foto}
                                      />
                                      <Card.Body>
                                        <div
                                          className="struktur-title-card "
                                          style={{ marginTop: -30 }}
                                        >
                                          <p> {strukturs.nama}</p>
                                        </div>
                                        <div className="struktur-jabatan">
                                          <small>{strukturs.jabatan}</small>
                                        </div>
                                        <div>
                                     
                                        </div>
                                      </Card.Body>
                                    </Card>
                                  </div>
                                </Col>
                              );
                            })}
                        </Row>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="3">
                      <div>
                        <Row>
                          {struktur
                            .filter(
                              (struktur) =>
                                struktur.departement ==
                                "Departement Sinergi & Acara"
                            )
                            .map((strukturs) => {
                              return (
                                <Col Col sm={4} style={{ paddingTop: "20px" }}>
                                  <div className="struktur-card">
                                    <Card className="struktur-border-card">
                                      <Image
                                        className="struktur-images"
                                        roundedCircle
                                        src={
                                          "https://ika.pcr.ac.id/backend_ikapcr/public/storage/organisasi/" +
                                          strukturs.foto
                                        }
                                        alt={strukturs.foto}
                                      />
                                      <Card.Body>
                                        <div
                                          className="struktur-title-card "
                                          style={{ marginTop: -30 }}
                                        >
                                          <p> {strukturs.nama}</p>
                                        </div>
                                        <div className="struktur-jabatan">
                                          <small>{strukturs.departement}</small>
                                        </div>
                                        <div>
                                          <Card.Text className="struktur-text-card">
                                            {strukturs.jabatan}
                                          </Card.Text>
                                        </div>
                                      </Card.Body>
                                    </Card>
                                  </div>
                                </Col>
                              );
                            })}
                        </Row>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="4">
                      <div>
                        <Row>
                          {struktur
                            .filter(
                              (struktur) =>
                                struktur.departement ==
                                "Departement Pendidikan & Olahraga"
                            )
                            .map((strukturs) => {
                              return (
                                <Col sm={4}  style={{ paddingTop: "20px" }}>
                                  <div className="struktur-card">
                                    <Card className="struktur-border-card">
                                      <Image
                                        className="struktur-images"
                                        roundedCircle
                                        src={
                                          "https://ika.pcr.ac.id/backend_ikapcr/public/storage/organisasi/" +
                                          strukturs.foto
                                        }
                                        alt={strukturs.foto}
                                      />
                                      <Card.Body>
                                        <div
                                          className="struktur-title-card "
                                          style={{ marginTop: -30 }}
                                        >
                                          <p> {strukturs.nama}</p>
                                        </div>
                                        <div className="struktur-jabatan">
                                          <small>{strukturs.departement}</small>
                                        </div>
                                        <div>
                                          <Card.Text className="struktur-text-card">
                                            {strukturs.jabatan}
                                          </Card.Text>
                                        </div>
                                      </Card.Body>
                                    </Card>
                                  </div>
                                </Col>
                              );
                            })}
                        </Row>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="5">
                      <div>
                        <Row>
                          {struktur
                            .filter(
                              (struktur) =>
                                struktur.departement ==
                                "Departement Ristek & Sosial Media"
                            )
                            .map((strukturs) => {
                              return (
                                <Col sm={4}  style={{ paddingTop: "20px" }}>
                                  <div className="struktur-card">
                                    <Card className="struktur-border-card">
                                      <Image
                                        className="struktur-images"
                                        roundedCircle
                                        src={
                                          "https://ika.pcr.ac.id/backend_ikapcr/public/storage/organisasi/" +
                                          strukturs.foto
                                        }
                                        alt={strukturs.foto}
                                      />
                                      <Card.Body>
                                        <div
                                          className="struktur-title-card "
                                          style={{ marginTop: -30 }}
                                        >
                                          <p> {strukturs.nama}</p>
                                        </div>
                                        <div className="struktur-jabatan">
                                          <small>{strukturs.departement}</small>
                                        </div>
                                        <div>
                                          <Card.Text className="struktur-text-card">
                                            {strukturs.jabatan}
                                          </Card.Text>
                                        </div>
                                      </Card.Body>
                                    </Card>
                                  </div>
                                </Col>
                              );
                            })}
                        </Row>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="6">
                      <div>
                        <Row>
                          {struktur
                            .filter(
                              (struktur) =>
                                struktur.departement ==
                                "Departement Sosial & Agama"
                            )
                            .map((strukturs) => {
                              return (
                                <Col sm={4}  style={{ paddingTop: "20px" }}>
                                  <div className="struktur-card">
                                    <Card className="struktur-border-card">
                                      <Image
                                        className="struktur-images"
                                        roundedCircle
                                        src={
                                          "https://ika.pcr.ac.id/backend_ikapcr/public/storage/organisasi/" +
                                          strukturs.foto
                                        }
                                        alt={strukturs.foto}
                                      />
                                      <Card.Body>
                                        <div
                                          className="struktur-title-card "
                                          style={{ marginTop: -30 }}
                                        >
                                          <p> {strukturs.nama}</p>
                                        </div>
                                        <div className="struktur-jabatan">
                                          <small>{strukturs.departement}</small>
                                        </div>
                                        <div>
                                          <Card.Text className="struktur-text-card">
                                            {strukturs.jabatan}
                                          </Card.Text>
                                        </div>
                                      </Card.Body>
                                    </Card>
                                  </div>
                                </Col>
                              );
                            })}
                        </Row>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="7">
                      <div>
                        <Row>
                          {struktur
                            .filter(
                              (struktur) =>
                                struktur.departement == "Departement Wirausaha"
                            )
                            .map((strukturs) => {
                              return (
                                <Col sm={4}  style={{ paddingTop: "20px" }}>
                                  <div className="struktur-card">
                                    <Card className="struktur-border-card">
                                      <Image
                                        className="struktur-images"
                                        roundedCircle
                                        src={
                                          "https://ika.pcr.ac.id/backend_ikapcr/public/storage/organisasi/" +
                                          strukturs.foto
                                        }
                                        alt={strukturs.foto}
                                      />
                                      <Card.Body>
                                        <div
                                          className="struktur-title-card "
                                          style={{ marginTop: -30 }}
                                        >
                                          <p> {strukturs.nama}</p>
                                        </div>
                                        <div className="struktur-jabatan">
                                          <small>{strukturs.departement}</small>
                                        </div>
                                        <div>
                                          <Card.Text className="struktur-text-card">
                                            {strukturs.jabatan}
                                          </Card.Text>
                                        </div>
                                      </Card.Body>
                                    </Card>
                                  </div>
                                </Col>
                              );
                            })}
                        </Row>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="8">
                      <div>
                        <Row>
                          {struktur
                            .filter(
                              (struktur) =>
                                struktur.departement ==
                                "Departement Humas & Kerjasama"
                            )
                            .map((strukturs) => {
                              return (
                                <Col sm={4}  style={{ paddingTop: "20px" }}>
                                  <div className="struktur-card">
                                    <Card className="struktur-border-card">
                                      <Image
                                        className="struktur-images"
                                        roundedCircle
                                        src={
                                          "https://ika.pcr.ac.id/backend_ikapcr/public/storage/organisasi/" +
                                          strukturs.foto
                                        }
                                        alt={strukturs.foto}
                                      />
                                      <Card.Body>
                                        <div
                                          className="struktur-title-card "
                                          style={{ marginTop: -30 }}
                                        >
                                          <p> {strukturs.nama}</p>
                                        </div>
                                        <div className="struktur-jabatan">
                                          <small>{strukturs.departement}</small>
                                        </div>
                                        <div>
                                          <Card.Text className="struktur-text-card">
                                            {strukturs.jabatan}
                                          </Card.Text>
                                        </div>
                                      </Card.Body>
                                    </Card>
                                  </div>
                                </Col>
                              );
                            })}
                        </Row>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default StrukturOrganisasi;
