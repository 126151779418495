import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, Row, Col, Container, Image, Button } from "react-bootstrap";
import KetuaImage from "../../assets/images/04.JPG";

function KetuaHero() {

  const navigate = useNavigate();

  function navigateTo(){
    navigate('/main/sekapursirih');
};

  return (
    <div className="box-profil-wrap">
      <div className="box-profil">
        <Container className="konten">
          <Card className="ketua-sekapur-sirih-card">


          <Row>
            <Col lg={6}>
                <Image 
                className="ketua-profil-images"
                 src={KetuaImage}></Image>
            </Col>
            <Col lg={6}>
          
              <div className="sekapur-sirih-profil">
                <div>
                  <h3 className="sekapur-sirih-profil-judul">Sekapur Sirih</h3>
                </div>
                <div>
                  <p className="sekapur-sirih-profil-text">
                    Adapun keberadaan Website ini dimaksudkan sebagai media
                    komunikasi antar sesama alumni dan antara alumni dengan
                    almater bahkan antara alumni dengan masyarakat luas.
                    Dengan adanya website ini diharapkan kita semua dapat saling 
                    berkomunikasi untuk mendiskusikan berbagai pemikiran konstruktif 
                    dan menginformasikan berbagai hal baik mengenai aktifitas IKAPCR, 
                    Almamater, keluarga alumni maupun mengenai kehidupan bermasyarakat,
                     berbangsa dan bernegara. 
                  </p>
                </div>
                <div>
                  <Button 
                  onClick={navigateTo}
                  className="btn-sekapur-sirih-profil">
                    Selengkapnya..
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
          </Card>
        </Container>
      </div>
    </div>
  );
}

export default KetuaHero;
