import React, { useState, useEffect } from "react";
import {
  Container,
  Form,
  Button,
  Card,
  Alert,
  Row,
  Col,
  Image,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import LoginImage from "../../assets/images/login.png";
import axios from "axios";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';


function RegisterUser() {
  const [alumni, setAlumni] = useState([]);
  const [email_alumni, setEmailAlumni] = useState("");
  const [password, setPassword] = useState("");
  const [nama, setNama] = useState("");
  const [no_hp, setNoHp] = useState("");
  const [validated, setValidated] = useState(false);
  const [isEmailUnique, setIsEmailUnique] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get("https://ika.pcr.ac.id/backend_ikapcr/public/api/alumni")
      .then((response) => {
        setAlumni(response.data);
      });
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const validasiAlumni = alumni.find(
        (a) => a.email_alumni === email_alumni
      );
  
      if (validasiAlumni !== undefined) {
        setIsEmailUnique(false);
      } else {
        setIsEmailUnique(true);
        const data = new FormData();
        data.append("email_alumni", email_alumni);
        data.append("nama", nama);
        data.append("no_hp", no_hp);
        data.append("password", password);
  
        fetch("https://ika.pcr.ac.id/backend_ikapcr/public/api/alumni", {
          method: "POST",
          body: data,
        })
          .then((res) => {
            if (res.ok) {
              // Show SweetAlert success message here
              Swal.fire({
                icon: 'success',
                title: 'Registration Successful',
                text: 'Your account has been created successfully!',
                confirmButtonText: 'Go to Login',
              }).then((result) => {
                if (result.isConfirmed) {
                  navigate("/main/login");
                }
              });
            } else {
              alert("Gagal Membuat Akun");
            }
          })
          .catch((error) => {
            alert("Error submitting form!");
          });
      }
    }
    setValidated(true);
  };
  

  function navigateToLogin() {
    navigate("/main/login");
  }

  return (
    <div>
      <Container style={{ padding: 30 }}>
        <div className="main-title-1">
          <h1>
            <strong></strong>
          </h1>
        </div>
        <Row>
          <Col>
            <Card className="kontak-card">
              <div className="main-title-login">
                <h3>
                  <strong>Buat Akun Baru</strong>
                </h3>
              </div>
              <div
                style={{
                  background: "#F4F6FC",
                  borderRadius: 12,
                  width: "100%",
                }}
              >
                <div style={{ padding: 20 }}>
                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                  >
                    <Form.Group>
                      <Form.Label>Nama Lengkap</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Nama Lengkap"
                        id="nama"
                        className="formlogin"
                        onChange={(e) => setNama(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Nomor Telephone</Form.Label>
                      <Form.Control
                        required
                        id="no_hp"
                        type="text"
                        placeholder="Nomor Telephone"
                        className="formlogin"
                        onChange={(e) => setNoHp(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        required
                        id="email_alumni"
                        type="email"
                        placeholder="Email"
                        className="formlogin"
                        onChange={(e) => setEmailAlumni(e.target.value)}
                        isInvalid={!isEmailUnique}
                      />
                      <Form.Control.Feedback type="invalid">
                        {isEmailUnique
                          ? "Please provide a valid email."
                          : "This email is already taken."}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        required
                        id="password"
                        type="password"
                        placeholder="Password"
                        className="formlogin"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </Form.Group>
                    <div style={{ paddingTop: 20 }} >
                      <Button type="submit" className="formlogin">Kirim</Button>
                    </div>
                  </Form>
                  <Row style={{ paddingTop: 30 }}>
                <Col>Belum punya akun?</Col>
                <Col>
                  <Button 
                  onClick={navigateToLogin}
                  className="registbnlogin">Klik Disini</Button>
                </Col>
              </Row>
                </div>
              </div>
            </Card>
          </Col>
          <Col>
            <div style={{ marginTop: -20 }}>
              <Image src={LoginImage} style={{ width: "100%" }}></Image>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default RegisterUser;
