import React, {  useEffect, useState } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { FaRegCalendarAlt, FaRegClock, FaLocationArrow } from 'react-icons/fa';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
import { HiOfficeBuilding } from "react-icons/hi";


function AgendaDetail() {

    const [agendaD, setAgendaD] = useState([]);
    const location = useLocation();


    useEffect(() => {
        axios.get('https://ika.pcr.ac.id/backend_ikapcr/public/api/agenda/' 
        + location.state.id_agenda_current)
            .then((response) => {
                setAgendaD(response.data);
            });
    }
        , []);

    return (
        <div>
            {agendaD.map((agendad) => {
                return (
                    <div key={agendad.id_agenda}>
                         <div style={{ paddingTop: "70px" }}>
        <div className="main-title">
          <h2><strong>{agendad.nama_kegiatan}</strong></h2>
        </div>
      </div>
                        <Container style={{ padding: 30 }}>
                            <div>
                                <Row>
                                    <Col sm>
                                        <Image 
                                        width={800}
                                        className='agenda-detail-gambar-isi' 
                                        src={"https://ika.pcr.ac.id/backend_ikapcr/public/storage/agenda/" + agendad.foto} alt={agendad.foto} />
                                    </Col>
                                    <Col>
                                        <div className='agenda-card-isi'>
                                            <div>
                                                <FaRegCalendarAlt /> {moment(agendad.tanggal).format('LL')}
                                                <div className='agenda-card-isi-isi'>
                                                    <FaRegClock /> {agendad.waktu}
                                                    <div className='agenda-card-isi-isi'>
                                                        <FaLocationArrow /> {agendad.tempat_kegiatan}
                                                    </div>
                                                    <div className='agenda-card-isi-isi'>
                                                        <HiOfficeBuilding /> {agendad.departement}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='berita-detail-isi'>
                                                <p>
                                                    {ReactHtmlParser(agendad.deskripsi)}
                                                </p>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </div>
                )
            })}
        </div>
    );
}

export default AgendaDetail;