import React, { Component, ChangeEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Nav,
  Container,
  Row,
  Col,
  Form,
  Card,
  Table,
  Image,
  Button,
  Modal,
} from "react-bootstrap";
import { SiLinktree } from "react-icons/si";
import UsahaImage from "../../assets/images/usahaalumni.png";
import InputUAImage from "../../assets/images/uainput.png";
import axios from "axios";
import Paginate from "../../inc/Paginate";
import Swal from "sweetalert2";
import { BiSearchAlt } from "react-icons/bi";

function UsahaAlumniUSer() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(6);
  const [checkedList, setCheckedList] = useState([]);
  const [dataWilayah, setDataWilayah] = useState([]);

  const [usahaalumni, setUA] = useState([]);
  const navigate = useNavigate();

  const [nama_usaha, setNamaUsaha] = useState("");
  const [jenis_usaha, setJenisUsaha] = useState("");
  const [alamat_usaha, setAlamatUsaha] = useState("");
  const [pemilik_usaha, setPemilikUsaha] = useState("");
  const [no_hp, setNoHp] = useState("");
  const [email, setEmail] = useState("");
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [link_tree, setLinktree] = useState("");
  const [tiktok, setTiktok] = useState("");
  const [shope, setShope] = useState("");
  const [tokopedia, setTokopedia] = useState("");
  const [youtube, setYoutube] = useState("");
  const [website, setWebsite] = useState("");
  const [status, setStatus] = useState("");
  const [foto_usaha, setFoto] = useState();
  const [deskripsi_usaha, setDeskripsiUsaha] = useState("");

  function navigateToUADetail(id_usaha_alumni_value) {
    navigate("/usahaalumnidetailuser", {
      state: { id_usaha_alumni_current: id_usaha_alumni_value },
    });
  }

  useEffect(() => {
    axios
      .get("https://ika.pcr.ac.id/backend_ikapcr/public/api/UsahaAlumni")
      .then((response) => {
        setUA(response.data);
        // console.log(response.data);
      });
  }, []);

  const handleFotoInput = (e) => {
    setFoto(e.target.files[0]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    var data = new FormData();
    var fotodata = document.getElementById("foto_usaha").files[0];
    // var idAlumni = document.getElementById('id_alumni').value;

    data.append("id_alumni", sessionStorage.getItem("id_alumni"));
    data.append("nama_usaha", nama_usaha);
    data.append("jenis_usaha", jenis_usaha);
    data.append("pemilik_usaha", pemilik_usaha);
    data.append("deskripsi_usaha", deskripsi_usaha);
    data.append("alamat_usaha", alamat_usaha);
    data.append("no_hp", no_hp);
    data.append("email", email);
    data.append("whatsapp", whatsapp);
    data.append("link_tree", link_tree);
    data.append("tiktok", tiktok);
    data.append("instagram", instagram);
    data.append("facebook", facebook);
    data.append("shope", shope);
    data.append("tokopedia", tokopedia);
    data.append("youtube", youtube);
    data.append("website", website);
    data.append("status", "Proses");
    data.append("foto_usaha", fotodata);

    fetch("https://ika.pcr.ac.id/backend_ikapcr/public/api/UsahaAlumni", {
      method: "POST",
      body: data,
    }).then(
      function (res) {
        if (res.ok) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Data yang kamu inputkan berhasil diproses, silahkan menunggu untuk validasi oleh admin",
            showCloseButton: false,
            confirmButtonText: '<a href="/profilalumni">OK</a>',
          });
        } else if (res.status == 401) {
          Swal.fire("Error!", "Failed to submit data.", "error");
        }
      },
      function (e) {
        Swal.fire("Error!", "An error occurred.", "error");
      }
    );
  };

  const [searchValue, setSearchValue] = useState("");
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = usahaalumni.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const previousPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== Math.ceil(usahaalumni.length / postsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleSelect = (event) => {
    const jenis_usaha = event.target.jenis_usaha;
    const isChecked = event.target.checked;

    if (isChecked) {
      //Add checked item into checkList
      setCheckedList([...checkedList, jenis_usaha]);
    } else {
      //Remove unchecked item from checkList
      const filteredList = checkedList.filter((item) => item !== jenis_usaha);
      setCheckedList(filteredList);
    }
  };

  return (
    <div>
      <Container style={{ padding: 30 }}>
        <div className="usaha-alumni-btn">
          <Button onClick={handleShow}>Inputkan Usaha Anda</Button>
        </div>

        <div className="konten">
          <Row>
            {usahaalumni
              .filter(
                (obj) =>
                  obj.id_alumni ===
                  parseInt(sessionStorage.getItem("id_alumni"))
              )
              .map((usahaalumnis) => {
                return (
                  <Col style={{ paddingTop: 20 }}>
                    <div
                      key={usahaalumnis.id_usaha_alumni}
                      className="usaha-alumni-card"
                    >
                      <Card className="usaha-alumni-card-border">
                        <div className="usaha-alumni-card-header">
                          <Image
                            className="usaha-alumni-card-images"
                            src={
                              "https://ika.pcr.ac.id/backend_ikapcr/public/storage/UsahaAlumni/" +
                              usahaalumnis.foto_usaha
                            }
                            alt={usahaalumnis.gambar}
                          />
                        </div>
                        <Card.Body>
                          <div className="usaha-alumni-card-isi">
                            <small className="text-muted">
                              {usahaalumnis.nama}
                            </small>
                          </div>
                          <div className="usaha-alumni-card-title">
                            <Card.Title>{usahaalumnis.nama_usaha}</Card.Title>
                          </div>
                          <div className="usaha-alumni-btn">
                            {/* <Button
                              className="usaha-alumni-btn-link"
                              onClick={() =>
                                navigateToUADetail(usahaalumnis.id_usaha_alumni)
                              }
                            >
                              <SiLinktree />
                            </Button> */}

                            <Button
                              className="usaha-alumni-btn-detail"
                              onClick={() =>
                                navigateToUADetail(usahaalumnis.id_usaha_alumni)
                              }
                            >
                              Lihat
                            </Button>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </Col>
                );
              })}
          </Row>
        </div>

        <div>
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size="xl"
            dialogClassName="modal-90w"
          >
            <Modal.Header closeButton>
              <Modal.Title>Inputkan Usaha Anda Disini</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col sm>
                  <Form onSubmit={handleSubmit} encType="multipart/form-data">
                    <Row>
                      <Col>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Nama Usaha</Form.Label>
                          <Form.Control
                            type="text"
                            id="nama_usaha"
                            onChange={(e) => setNamaUsaha(e.target.value)}
                          />
                          <Form.Text style={{ color: "red" }}>
                            *required
                          </Form.Text>
                        </Form.Group>

                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Jenis Usaha</Form.Label>
                          <Form.Select
                            type="text"
                            id="status"
                            onChange={(e) => setJenisUsaha(e.target.value)}
                          >
                            <option>Pilih Jenis Usaha</option>
                            <option value="Fashion">Fashion</option>
                            <option value="Food and Beverage">Food and Beverage</option>
                            <option value="Jasa">Jasa</option>
                            <option value="Elektronik">Elektronik</option>
                            <option value="Lainnya">Lainnya..</option>
                          </Form.Select>
                          <Form.Text style={{ color: "red" }}>
                            *required
                          </Form.Text>
                        </Form.Group>

                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Alamat Usaha</Form.Label>
                          <Form.Control
                            type="text"
                            id="alamat_usaha"
                            onChange={(e) => setAlamatUsaha(e.target.value)}
                          />
                          <Form.Text style={{ color: "red" }}>
                            *required
                          </Form.Text>
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="email"
                            id="email"
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          <Form.Text style={{ color: "red" }}>
                            *required
                          </Form.Text>
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>No Hp</Form.Label>
                          <Form.Control
                            type="text"
                            id="no_hp"
                            onChange={(e) => setNoHp(e.target.value)}
                          />
                          <Form.Text style={{ color: "red" }}>
                            *required
                          </Form.Text>
                        </Form.Group>
                        <Form.Group controlId="formFile" className="mb-3">
                          <Form.Label>Inputkan Gambar Usaha</Form.Label>
                          <Form.Control
                            type="file"
                            id="foto_usaha"
                            onChange={handleFotoInput}
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <Form.Label>Deskripis</Form.Label>
                          <Form.Control
                            style={{ borderRadius: 10 }}
                            as="textarea"
                            rows={3}
                            placeholder="deskripsikan usaha anda..."
                            id="deskripsi_usaha"
                            onChange={(e) => setDeskripsiUsaha(e.target.value)}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Facebook</Form.Label>
                          <Form.Control
                            type="text"
                            id="facebook"
                            onChange={(e) => setFacebook(e.target.value)}
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Instagram</Form.Label>
                          <Form.Control
                            type="text"
                            id="instagram"
                            onChange={(e) => setInstagram(e.target.value)}
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Whatsapp</Form.Label>
                          <Form.Control
                            type="text"
                            id="whatsapp"
                            onChange={(e) => setWhatsapp(e.target.value)}
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Tiktok</Form.Label>
                          <Form.Control
                            type="text"
                            id="tiktok"
                            onChange={(e) => setTiktok(e.target.value)}
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Biolink</Form.Label>
                          <Form.Control
                            type="text"
                            id="linktree"
                            onChange={(e) => setLinktree(e.target.value)}
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Shopee</Form.Label>
                          <Form.Control
                            type="text"
                            id="shope"
                            onChange={(e) => setShope(e.target.value)}
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Tokopedia</Form.Label>
                          <Form.Control
                            type="text"
                            id="tokopedia"
                            onChange={(e) => setTokopedia(e.target.value)}
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Youtube</Form.Label>
                          <Form.Control
                            type="text"
                            id="youtube"
                            onChange={(e) => setYoutube(e.target.value)}
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Website</Form.Label>
                          <Form.Control
                            type="text"
                            id="website"
                            onChange={(e) => setWebsite(e.target.value)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Modal.Footer>
                      <Button type="submit" onClick={() => handleSubmit()}>
                        Tambah
                      </Button>
                      
                    </Modal.Footer>
                  </Form>
                </Col>
              </Row>
            </Modal.Body>
          </Modal>
        </div>
        <div className="pagenation">
          <Paginate
            postsPerPage={postsPerPage}
            totalPosts={usahaalumni.length}
            paginate={paginate}
            previousPage={previousPage}
            nextPage={nextPage}
          />
        </div>
      </Container>
    </div>
  );
}

export default UsahaAlumniUSer;
