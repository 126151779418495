import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Main from './Main.js';
import Panel from './Panel.js';

function App() {
  return (
    <div style={{ backgroundColor: "#f6f8fd" }}>
      <BrowserRouter>
        <Routes>
          <Route path="/main/*" element={<Main />} />
          <Route path="/panel/*" element={<Panel />} />
          <Route path="/*" element={<Navigate to="/main" />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
