import React from 'react';
import Container from 'react-bootstrap/Container';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import HeroImage from '../assets/images/ikapcrberbagi-hero.png';
import 'animate.css'


function HeroIkaberbagi() {
  const navigate = useNavigate();

  function navigateToDaftar(){
      navigate('https://ikapcrberbagi.id/daftar');
  };

  return (
    <div style={{ backgroundColor:'#e4f4ff', width:'100%' }}>
      <Container >
        <Row >
          <Col sm={6}  className="animate__animated animate__fadeInUpBig">
            <p className='hero-title'>MULAI SEKARANG<br></br>
              Bergerak Membantu Sesama</p>
            <h5 className='hero-sub-title'>
            Daftar gratis untuk bergabung dengan donatur lainnya. <br></br>
            Mulai bantu sesama dan ringankan beban mereka yang membutuhkan
            </h5>
            <Button className='btn-hero-ikaberbagi'
            onClick={navigateToDaftar}
            >
              Daftar Sekarang
            </Button>
          </Col>
          <Col sm={6} style={{ marginTop: '-90px'}} className="animate__animated animate__fadeIn">
            <img src={HeroImage} style={{ width: '100%' }}></img>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default HeroIkaberbagi;