import React from 'react'
import { Nav, Container} from 'react-bootstrap';
import LogoBeasiswa from '../assets/images/logo/beasiswa_logo.png';
import LogoDonasi from '../assets/images/logo/donasiikapcr_logo.png';
import LogoSCC from '../assets/images/logo/scc_logo.png';
import LogoPCR from '../assets/images/logo/pcr_logo.png'
import LogoTrecer from '../assets/images/logo/trecer_logo2.png'


function LinkTerkait() {
  return (
    <div>
    <Container>
   
        <div className="slider">
            <div className="slide-track-1">
                <div className="slide">
                    <Nav.Link href='https://beasiswa.ika.pcr.ac.id/'>
                        <img src={LogoBeasiswa} />
                    </Nav.Link>
                </div>
                <div className="slide">
                    <Nav.Link href='https://ikapcrberbagi.id/'>
                        <img src={LogoDonasi} />
                    </Nav.Link>
                </div>
                <div className="slide">
                    <Nav.Link href='https://pcr.ac.id/'>
                        <img src={LogoPCR} />
                    </Nav.Link>
                </div>
                <div className="slide">
                    <Nav.Link href='https://scc.pcr.ac.id/'>
                        <img src={LogoSCC} />
                    </Nav.Link>
                </div>
                <div className="slide">
                    <Nav.Link href='https://tracer.pcr.ac.id/'>
                        <img src={LogoTrecer} />
                    </Nav.Link>
                </div>
            </div>
            <div className="slide-track-1">
                <div className="slide">
                    <Nav.Link href='https://beasiswa.ika.pcr.ac.id/'>
                        <img src={LogoBeasiswa} />
                    </Nav.Link>
                </div>
                <div className="slide">
                    <Nav.Link href='https://ikapcrberbagi.id/'>
                        <img src={LogoDonasi} />
                    </Nav.Link>
                </div>
                <div className="slide">
                    <Nav.Link href='https://pcr.ac.id/'>
                        <img src={LogoPCR} />
                    </Nav.Link>
                </div>
                <div className="slide">
                    <Nav.Link href='https://scc.pcr.ac.id/'>
                        <img src={LogoSCC} />
                    </Nav.Link>
                </div>
                <div className="slide">
                    <Nav.Link href='https://tracer.pcr.ac.id/'>
                        <img src={LogoTrecer} />
                    </Nav.Link>
                </div>
            </div>
            
        </div>
    </Container >
</div >
  )
}

export default LinkTerkait