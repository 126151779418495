import React, {  useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {  Container, Row, Col, Card, Image, Button } from 'react-bootstrap';
import axios from 'axios';
import { BsArrowRight } from "react-icons/bs";

function KisahAlumniHomepage() {

    const [kisahalumni, setKA] = useState([]);
    const navigate = useNavigate();

    function navigateToKADetail(id_kisah_alumni_value) {
        navigate('/main/kisahalumnidetail',{state:{id_kisah_alumni_current:id_kisah_alumni_value}});
    }

    function navigateToKA() {
        navigate("/main/kisahalumni");
      }

    useEffect(() => {
        axios.get('https://ika.pcr.ac.id/backend_ikapcr/public/api/KisahAlumni').then((response) => {
            setKA(response.data);

        });
    }, []);


    return (
        <div>
            <Container className='box-beranda'>
                <div className='main-title-beranda'>
                    <h2><strong>Kisah Alumni</strong></h2>
                </div>
                <div >
          <div className="lihat-semua-btn">
            <Button className="lihat-semua-btn-dt" onClick={navigateToKA}>
              Lihat Semua Kisah
              <BsArrowRight />
            </Button>
          </div>
        </div>
                <div className='konten'>
                    <Row>
                        { kisahalumni.slice(0, 4).map((kisahalumnis) => {
                            return(
                            <Col sm={3} style={{padding:'10px 10px '}} key={kisahalumnis.id_kisah_alumni}>
                                <div className='kisah-alumni-card'>
                                    <Card className='kisah-alumni-border-card' onClick={() => navigateToKADetail(kisahalumnis.id_kisah_alumni)}>
                                    <Image className='kisah-alumni-images' roundedCircle src={"https://ika.pcr.ac.id/backend_ikapcr/public/storage/KisahAlumni/" + kisahalumnis.foto_kisah} alt={kisahalumnis.foto_kisah}></Image>
                                        <Card.Body>
                                            <div className='kisah-alumni-prodi' style={{ marginTop: -30 }} >
                                                <small> {kisahalumnis.judul_kisah}</small>
                                            </div>
                                            <div className='kisah-alumni-title-card '>
                                                <h5>{kisahalumnis.nama}</h5>
                                            </div>
                                            <div>
                                                <Card.Text className='kisah-alumni-text-card'>
                                                    {kisahalumnis.jabatan}
                                                </Card.Text>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </Col>
                            );
                        })}


                    </Row>
                </div>
            </Container>
        </div>
    );
}


export default KisahAlumniHomepage;