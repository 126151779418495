import React, {  useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Image,
  Col,
  Row,
  Card,
  Button,
} from "react-bootstrap";
import "react-image-lightbox/style.css";
import {  FaArrowRight } from "react-icons/fa";
import { BsArrowRight } from "react-icons/bs";
import axios from "axios";

function GaleriHomepage() {
    const [galeri, setGaleri] = useState([]);
    const navigate = useNavigate();

  
    function DetailGaleri (id_agenda_value, nama) {
      navigate('/main/galeridetail', { state: { id_agenda_current: id_agenda_value, nama_kegiatan:nama } });
    }
  

    function navigateToGaleri() {
      navigate("/main/galeri");
    }

    useEffect(() => {
      axios
        .get("https://ika.pcr.ac.id/backend_ikapcr/public/api/agenda")
        .then((response) => {
          setGaleri(response.data);
        });
    }, []);
  return (
    <div>
    <Container className="box">
      <div className="main-title-beranda">
        <h1>
          <strong>Galeri</strong>
        </h1>
      </div>
      <div >
          <div className="lihat-semua-btn">
            <Button className="lihat-semua-btn-dt"
             onClick={navigateToGaleri}>
              Lihat Semua Galeri
              <BsArrowRight />
            </Button>
          </div>
        </div>
      <div className="konten">
        <Row>
          {galeri.slice(0, 4)
            .map((galeris) => {
              return (
                <Col sm style={{ paddingTop: 20 }}>
                  <div key={galeris.id_agenda}>
                    <Card 
                    onClick={() => DetailGaleri(galeris.id_agenda, galeris.nama_kegiatan)} 
                    className="galeri-card">
                      <div className='galeri-card-header'>
                      <Image
                      className='galeri-card-images'
                        src={
                          "https://ika.pcr.ac.id/backend_ikapcr/public/storage/agenda/" +
                          galeris.foto
                        }
                        alt={galeris.foto}
                      />
                      </div>
                      <Card.Body className="galeri-card-isi">
                        <div className="galeri-card-cover-isi">

                        <div className="galeri-title">
                          {galeris.nama_kegiatan}
                        </div>
                        </div>
                        {/* <div className="galeri-btn">
                          <Button className="galeri-btn-dt" onClick={() => DetailGaleri(galeris.id_agenda, galeris.nama_kegiatan)} >
                            Lihat <FaArrowRight />
                          </Button>
                        </div> */}
                      </Card.Body>
                    </Card>
                  </div>
                </Col>
              );
            })}
        </Row>
      </div>

    </Container>
  </div>
  )
}

export default GaleriHomepage