import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Form,
  Card,
  Image,
  Button,
  Modal,
} from "react-bootstrap";
import axios from "axios";
import Paginate from "../../inc/Paginate";
import { BiSearchAlt } from "react-icons/bi";

import moment from "moment";

function Loker() {
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(8);

  const [loker, setLoker] = useState([]);
  const navigate = useNavigate();

  function navigateToLokerDetail(id_loker_value) {
    navigate("/main/lokerdetail", { state: { id_loker_current: id_loker_value } });
  }

  useEffect(() => {
    axios
      .get("https://ika.pcr.ac.id/backend_ikapcr/public/api/loker")
      .then((response) => {
        setLoker(response.data);
      });
  }, []);

  const [searchValue, setSearchValue] = useState("");
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = loker.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const previousPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== Math.ceil(loker.length / postsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div>
      <div style={{ paddingTop: "70px" }}>
        <div className="main-title">
          <h1>
            <strong>Lowongan Pekerjaan</strong>
          </h1>
        </div>
      </div>
      <Container className="box">
        <div className="search">
          <div className="search-box">
            <Button className="btn-search">
              <BiSearchAlt />
            </Button>
            <input
              className="input-search"
              type="text"
              name="search"
              placeholder="Type to Search..."
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
        </div>

        <div konten>
          <Row>
            {currentPosts
              .filter((loker) => loker.status == "Approve")
              .filter((lokJud) =>
                lokJud.judul_lowongan.match(new RegExp(searchValue, "i"))
              )
              .map((lokers) => {
                return (
                  <Col sm={3} style={{ paddingTop: 20 }}>
                    <div  >
                      <Card
                      className="loker-card"
                        onClick={() => navigateToLokerDetail(lokers.id_loker)}
                      >
                        <div className="loker-card-header">
                          <Image
                            className="loker-card-images"
                            src={
                              "https://ika.pcr.ac.id/backend_ikapcr/public/storage/loker/" +
                              lokers.gambar
                            }
                            alt={lokers.gambar}
                          />
                        </div>
                        <div className="loker-card-cover-isi">

                        <Card.Body>
                          <div style={{ marginTop: -10, marginBottom: 10 }}>
                            <small className="text-muted">{lokers.nama}</small>
                          </div>
                          <div style={{ marginTop: -10, marginBottom: 10 }}>
                            <small className="text-muted">
                              {moment(lokers.tanggal_mulai).format("LL")} -{" "}
                              {moment(lokers.tanggal_selesai).format("LL")}
                            </small>
                          </div>

                          <div>
                            <Card.Title className="loker-title">
                              {lokers.judul_lowongan}
                            </Card.Title>
                          </div>
                        </Card.Body>
                        </div>
                      </Card>
                    </div>
                  </Col>
                );
              })}
          </Row>
        </div>
        <div className="pagenation">
          <Paginate
            postsPerPage={postsPerPage}
            totalPosts={loker.length}
            paginate={paginate}
            previousPage={previousPage}
            nextPage={nextPage}
          />
        </div>
      </Container>
    </div>
  );
}

export default Loker;
