import React, { useEffect, useState } from "react";
import { Row, Col, Container, Card } from "react-bootstrap";
import axios from "axios";
import ReactHTMLParser from "react-html-parser";

function VisiMisiHomepage() {
  const [profilikapcr, setProfilIkapcr] = useState([]);

  useEffect(() => {
    axios
      .get("https://ika.pcr.ac.id/backend_ikapcr/public/api/ProfilIkapcr")
      .then((response) => {
        setProfilIkapcr(response.data);
      });
  }, []);

  return (
    <div className="box-profil-wrap">
    <div className="box-visimisi">
      <Container className="konten">
        <Card className="visi-misi-card">
        <Row>
          <Col sm={6}>
       
            {profilikapcr.map((profils) => {
              return (
                <div key={profils.id_profil}>
                  
                      <h5 className="visi-misi-tittle">
                        <strong >VISI
                        <p style={{fontSize:'19px'}}>Ikatan Keluarga Alumni Politeknik Caltex Riau</p> </strong>
                      </h5>
                      
                      <div>
                        <p className="visi-misi-text-beranda">
                          {ReactHTMLParser(profils.visi)}
                        </p>
                      </div>
                      <div>
                      <h5 className="visi-misi-tittle">
                        <strong >MISI
                        <p style={{fontSize:'19px'}}>Ikatan Keluarga Alumni Politeknik Caltex Riau</p> </strong>
                      </h5>
                        <div style={{ marginLeft: "-15px" }}>
                          <p className="visi-misi-text-beranda">
                            {ReactHTMLParser(profils.misi)}
                          </p>
                        </div>
                      </div>
                </div>
              );
            })}
          </Col>
          <Col>
            <div>
              <iframe
                className="profil-hero-yt"
                height={280}
                src="https://www.youtube.com/embed/nEKvF-1GEP8"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </Col>
        </Row>
        </Card>
      </Container>
    </div>
    </div>
  );
}

export default VisiMisiHomepage;
