import React, { useState, useEffect } from "react";
import {
  Chart as ChartJs,
  ArcElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import fetch from "node-fetch";

ChartJs.register(ArcElement);

const JumlahPerProdi = () => {
  const [dataAlumni, setDataAlumni] = useState([]);
  const [chart, setChart] = useState({});
  const baseUrl = "/api/api/tracer-alumni?collection=alumni-all&pagesize=10000";

  const options = {
    method: "GET",
    headers: {
      apikey: "UnUsYXPyc32yiOKmf71J26XdB0VVuX6c",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    withCredentials: false,
  };
  useEffect(() => {
    const fetchalumni = async () => {
      await fetch(baseUrl, options)
        .then((response) => {
          if (response.ok) {
            response.json().then((json) => {
              setDataAlumni(json.items);
              // setChart(json.data)
            });
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    };
    fetchalumni();
  }, []);

  const setProdi = new Set(dataAlumni.map((a) => a.nama_prodi));
  const prodi = Array.from(setProdi).sort();

  const setAlumniCount = new Set(
    prodi.map((x) => dataAlumni.filter((obj) => obj.nama_prodi === x).length)
  );
  const alumniCount = Array.from(setAlumniCount);

  var data = {
    labels: prodi.map((x) => x),
    datasets: [
      {
        label: "My First Dataset",
        data: alumniCount.map((x) => x),
        backgroundColor: [
          "#fbff00",
          "#0055ab",
          "#c8c96d",
          "#8bade8",
          "navy",
          "grey",
          "#eaffa6",
          "#0a6100",
          "#9e0000",
          "#020138",
          "blue",
        ],
        hoverOffset: 4,
      },
    ],
  };

  // var options = {
  //   maintainAspectRatio: false,
  //   scales: {
  //     y: {
  //       beginAtZero: false
  //     }
  //   },
  //   legend: {

  //   }
  // }

  return (
    <div>
      <Doughnut
        data={data}
        height={400}
        // options={options}
      ></Doughnut>
    </div>
  );
};

export default JumlahPerProdi;
