import React, {  useState } from "react";
import { Container, Row, Col, Form, Image, Button, Nav, Card } from "react-bootstrap";
import KontakImage from "../../assets/images/logo_ikapcr.jpg";
import {
  FaInstagram,
  FaFacebookF,
  FaTelegram,
  FaWhatsapp,
} from "react-icons/fa";

import Swal from "sweetalert2";

function Kontak() {
  const handleSubmitKontak = (e) => {
    e.preventDefault();
    var dataKontak = {
      nama: nama,
      email: email,
      pesan: pesan,
    };

    fetch("https://ika.pcr.ac.id/backend_ikapcr/public/api/kontak", {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataKontak),
    }).then(
      function (res) {
        if (res.ok) {
          Swal.fire("Success", "Pesan berhasil dikirimkan, Terimakasih!", "success").then(() => {
            // Redirect to UsahaDetailPanel after Swal is closed
            window.location.reload();
          });
        } else if (res.status == 401) {
          Swal.fire("Error!", "Failed to submit data.", "error");
        }
      },
      function (e) {
        Swal.fire("Error!", "An error occurred.", "error");
      }
    );

  };
  const [nama, setNama] = useState("");
  const [email, setEmail] = useState("");
  const [pesan, setPesan] = useState("");

  return (
    <div>
      <div>
      <div style={{ paddingTop: "70px" }}>
        <div className="main-title">
          <h1>
          <strong>Kontak Kami</strong>
          </h1>
        </div>
      </div>
        <Container style={{ padding: 30 }}>

          <Row>
            <Col sm>
              <Card className="kontak-card">

              <div
                style={{
                  background: "#F4F6FC",
                  borderRadius: 12,
                  width: "100%",
                }}
              >
                <div style={{ padding: 20 }}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Image src={KontakImage} style={{ width: "200px" }}></Image>
                  </div>
                  <div>
                    <div className="footer-icons-kontak">
                      <Row>
                        <Col>
                          <Nav.Link
                            href="https://www.instagram.com/ikapcr/"
                            style={{ color: "#195C92" }}
                          >
                            <FaInstagram />
                          </Nav.Link>
                        </Col>
                        <Col>
                          <Nav.Link
                            href="/"
                            style={{ color: "#195C92"  }}
                          >
                            <FaTelegram />
                          </Nav.Link>
                        </Col>
                        <Col>
                          <Nav.Link
                            href="https://www.facebook.com/IkatanAlumniPCR"
                            style={{ color: "#195C92"  }}
                          >
                            <FaFacebookF />
                          </Nav.Link>
                        </Col>
                        <Col>
                          <Nav.Link
                            href="/"
                            style={{ color: "#195C92"  }}
                          >
                            <FaWhatsapp />
                          </Nav.Link>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <Form onSubmit={handleSubmitKontak}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        id="nama"
                        onChange={(e) => setNama(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="name@example.com"
                        id="email"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>Message</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="write your message here.."
                        id="pesan"
                        onChange={(e) => setPesan(e.target.value)}
                      />
                    </Form.Group>
                  </Form>
                  <Button type="submit" onClick={(e) => handleSubmitKontak(e)}>
                    Kirim
                  </Button>
                </div>
              </div>
              </Card>
            </Col>

            <Col sm>
    

              <div style={{ width: "100%", height: "100%" }}>
              
                <Card className="maps-card">

                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.6202055454196!2d101.42352197410641!3d0.5709805635860175!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31d5ab67086f2e89%3A0x65a24264fec306bb!2sPoliteknik%20Caltex%20Riau!5e0!3m2!1sen!2sid!4v1686153989197!5m2!1sen!2sid"
                  width="100%"
                  height="100%"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
                </Card>
                
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default Kontak;
