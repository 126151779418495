import React, {  useEffect, useState } from "react";
import { Container, Image, Row, Col, Card } from "react-bootstrap";
import KetuaImage from "../../assets/images/ketuaikapcr.png";
import "../../assets/css/GlobalStyling.css";
import axios from "axios";
import ReactHtmlParser from "react-html-parser";

function SekapurSirih() {
  const [profilikapcr, setProfilIkapcr] = useState([]);

  useEffect(() => {
    axios
      .get("https://ika.pcr.ac.id/backend_ikapcr/public/api/ProfilIkapcr")
      .then((response) => {
        setProfilIkapcr(response.data);
      });
  }, []);

  return (
    <div >
      <div style={{paddingTop:'70px'}}>
        <div className="main-title">
          <h1>
            <strong>Sekapur Sirih</strong>
          </h1>
        </div>
      </div>
      <div>

      <Container style={{ padding: 30 }}>
        <div style={{ textAlign: "justify" }}>
              <Card className="ketua-card">
          <Row>
            <Col sm>
                <div className="profil-ketua-wrap">

              <Image
                src={KetuaImage}
                className="profil-ketua-sekapur-sirih-detail-image"
              ></Image>
                </div>
              <Card.Footer className="ketua-sekapur-sirih-card-footer">
              <div className="profil-ketua-sekapur-sirih-detail">
                <p  className="profil-ketua-sekapur-sirih-detail-p-nama">Aditya Widyawan Prima, S.Kom, M.Si</p>
                <p className="profil-ketua-sekapur-sirih-detail-p-periode">Periode 2021-2024</p>
                <p className="profil-ketua-sekapur-sirih-detail-p">Teknik Telekomunikasi</p>
                <p className="profil-ketua-sekapur-sirih-detail-p">Generasi 6</p>
              </div>
              </Card.Footer>
            </Col>
            <Col sm={8}>
              {profilikapcr.map((profils) => {
                return (
                  <div style={{width:'100%'}}>
                    <p>{ReactHtmlParser(profils.sekapur_sirih)}</p>
                  </div>
                );
              })}
            </Col>
          </Row>
              </Card>
        </div>
      </Container>
      </div>
    </div>
  );
}
export default SekapurSirih;
