import React, { Component, ChangeEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Nav,
  Container,
  Row,
  Col,
  Form,
  Card,
  Table,
  Image,
  Button,
  Modal,
} from "react-bootstrap";
import axios from "axios";
import Paginate from "../../../inc/Paginate";
import Swal from "sweetalert2";
import { BiSearchAlt } from "react-icons/bi";
import { FaRegCalendarAlt } from "react-icons/fa";

import moment from "moment";

function LokerUserPanel() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(8);

  const [loker, setLoker] = useState([]);
  const navigate = useNavigate();

  function navigateToLokerDetail(id_loker_value) {
    navigate("/panel/lokerdetail", { state: { id_loker_current: id_loker_value } });
  }

  useEffect(() => {
    axios
      .get("https://ika.pcr.ac.id/backend_ikapcr/public/api/loker")
      .then((response) => {
        setLoker(response.data);
        // console.log(response.data);
      });
  }, []);

  const handleFotoInput = (e) => {
    setGambar(e.target.files[0]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.preventDefault();
    var data = new FormData();
    var gambardata = document.getElementById("gambar").files[0];
    // var id_alumni = document.getElementById('id_alumni');

    data.append("id_alumni", sessionStorage.getItem("id_alumni"));
    data.append("judul_lowongan", judul_lowongan);
    data.append("gambar", gambardata);
    data.append("deskipsi", deskipsi);
    data.append("kategori", kategori);
    data.append("tanggal_mulai", tglmulai);
    data.append("tanggal_selesai", tglselesai);
    data.append("status", "Proses");

    fetch("https://ika.pcr.ac.id/backend_ikapcr/public/api/loker", {
      method: "POST",
      body: data,
    }).then(
      function (res) {
        if (res.ok) {
          Swal.fire("Success", "Data berhasil ditambahkan!", "success").then(() => {
            // Redirect to UsahaDetailPanel after Swal is closed
            window.location.reload();
          });
        } else if (res.status == 401) {
          Swal.fire("Error!", "Failed to submit data.", "error");
        }
      },
      function (e) {
        Swal.fire("Error!", "An error occurred.", "error");
      }
    );
  };

  const [judul_lowongan, setJudulLowongan] = useState("");
  const [deskipsi, setDeskipsi] = useState("");
  const [status, setStatus] = useState("");
  const [gambar, setGambar] = useState("");
  const [kategori, setKategori] = useState("");
  const [tglmulai, setTglMulai] = useState("");
  const [tglselesai, setTglSelesai] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = loker.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const previousPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== Math.ceil(loker.length / postsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div>
      <Container className="box">
        <div className="loker-btn-input">
          <Button onClick={handleShow}>Tambah Loker</Button>
        </div>
        <div>
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Inputkan Lowongan Pekerjaan</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col sm>
                  <Form onSubmit={handleSubmit} encType="multipart/form-data">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Posisi dan Nama Perusahaan</Form.Label>
                      <Form.Control
                        placeholder="IT Support - Telkomsel Indonesia"
                        type="text"
                        id="judul_lowongan"
                        onChange={(e) => setJudulLowongan(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Tanggal Mulai</Form.Label>
                      <Form.Control
                        id="tanggal_mulai"
                        type="date"
                        onChange={(e) => setTglMulai(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Tanggal Berakhir</Form.Label>
                      <Form.Control
                        id="tanggal_selesai"
                        type="date"
                        onChange={(e) => setTglSelesai(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Kategori</Form.Label>
                      <Form.Select
                        required
                        id="kategori"
                        type="text"
                        onChange={(e) => setKategori(e.target.value)}
                      >
                        <option>Pilih Kategori Loker</option>
                        <option value="Magang">Magang</option>
                        <option value="Fulltime">Fulltime</option>
                      </Form.Select>
                    </Form.Group>
                    <Form.Group controlId="formFile" className="mb-3">
                      <Form.Label>Inputkan Gambar Poster</Form.Label>
                      <Form.Control
                        type="file"
                        id="gambar"
                        onChange={handleFotoInput}
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>Deskripsi</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        id="deskipsi"
                        onChange={(e) => setDeskipsi(e.target.value)}
                      />
                    </Form.Group>
                  </Form>
                </Col>
              </Row>

              {/* <Button variant="secondary" onClick={handleClose}>
                Close
              </Button> */}
              <div style={{display:'flex', justifyContent:'end'}}>
              <Button type="submit" onClick={(e) => handleSubmit(e)}>
                Tambah
              </Button>
              </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
        </div>
        <div konten>
          <Row>
          {loker
                                  .filter(
                                    (obj) =>
                                      obj.id_alumni ===
                                      parseInt(
                                        sessionStorage.getItem("id_alumni")
                                      )
                                  )
              .map((lokers) => {
                return (
                  <Col sm={3}>
                    <div className="loker-card" style={{ paddingTop: 20 }}>
                      <Card
                        style={{ border: "none" }}
                        onClick={() => navigateToLokerDetail(lokers.id_loker)}
                      >
                        <div className="loker-card-header">
                          <Image
                            className="loker-card-images"
                            src={
                              "https://ika.pcr.ac.id/backend_ikapcr/public/storage/loker/" +
                              lokers.gambar
                            }
                            alt={lokers.gambar}
                          />
                        </div>
                        <Card.Body>
                          <div style={{ marginTop: -10, marginBottom: 10 }}>
                            <small className="text-muted">{lokers.nama}</small>
                          </div>
                          <div style={{ marginTop: -10, marginBottom: 10 }}>
                            <small className="text-muted">
                              {moment(lokers.tanggal_mulai).format("LL")} -{" "}
                              {moment(lokers.tanggal_selesai).format("LL")}
                            </small>
                          </div>

                          <div>
                            <Card.Title className="loker-title">
                              {lokers.judul_lowongan}
                            </Card.Title>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </Col>
                );
              })}
          </Row>
        </div>
        <div className="pagenation">
          <Paginate
            postsPerPage={postsPerPage}
            totalPosts={loker.length}
            paginate={paginate}
            previousPage={previousPage}
            nextPage={nextPage}
          />
        </div>
      </Container>
    </div>
  );
}

export default LokerUserPanel;
