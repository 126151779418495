import React, { Component, useState, useEffect } from 'react';
import { Container, Card, Col, Row, Image } from 'react-bootstrap';
import Chart from "chart.js/auto";
import { Line, Bar } from "react-chartjs-2";
import JumlahPertahun from './JumlahPertahun';
import JumlahPerProdi from './JumlahPerProdi';
import DomisiTinggal from './DomisiTinggal';
import DomisiliBekerja from './DomisiliBekerja';


function Statistik() {
    const [dataAlumni, setDataAlumni] = useState([]);



    return (
        <div>
             <div style={{ paddingTop: "70px" }}>
        <div className="main-title">
          <h1>
          <strong>Statistik</strong>
          </h1>
        </div>
      </div>
            <Container style={{ padding: 30 }}>
                <Row>
                    <Col style={{paddingBottom:'20px'}}>  <Card className='statistik-card' >
                    <div className='profil-card-title'>Jumlah Lulusan Per Tahun</div> 
                        <JumlahPertahun />
                    </Card>
                    </Col>
                    <Col style={{paddingBottom:'20px'}} >
                    <Card className='statistik-card' >
                        <div className='profil-card-title'>Jumlah Lulusan Per Prodi</div> 

                    <JumlahPerProdi />
                    </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>  
                    <Card className='statistik-card'>
                    <div className='profil-card-title'>Jumlah Domisili Bekerja</div> 
                    <DomisiliBekerja />
                    </Card>
                    </Col>
                    <Col>
                        <Card className='statistik-card'>
                        <div className='profil-card-title'>Jumlah Domisili Tinggal</div> 
                            <DomisiTinggal />
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div >
    );
}

export default Statistik;