import React, {  useEffect, useState } from 'react';
import { Container, Row, Col, Image, Accordion } from 'react-bootstrap';
import FaqImage from "../../assets/images/FAQ.png";
import axios from 'axios';
import ReactHtmlParser from 'react-html-parser';

function Faq() {

    const [faq, setFaq] = useState([]);

    useEffect(() => {
        axios.get('https://ika.pcr.ac.id/backend_ikapcr/public/api/faq')
        .then((response) => {
            setFaq(response.data);

        });
    }, []);

    return (
        <div>
            <div>
            <div style={{ paddingTop: "70px" }}>
        <div className="main-title">
          <h2>
            <strong>Frequently Asked Questions</strong>
          </h2>
        </div>
      </div>
                <Container className='box'>
                   
                    <Row>
                        <Col sm><Image src={FaqImage} style={{ width: '100%' }}></Image>
                        </Col>
                        <Col sm>
                            {faq.map((faqs) => {
                                return (
                                    <Accordion flush key={faqs.id_faq}>
                                        <Accordion.Item >
                                            <Accordion.Header>{ReactHtmlParser(faqs.pertanyaan)}</Accordion.Header>
                                            <Accordion.Body>
                                                {ReactHtmlParser(faqs.jawaban)}
                                            </Accordion.Body>
                                        </Accordion.Item>

                                    </Accordion>
                                )
                            })}
                        </Col>

                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default Faq


