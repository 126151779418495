import React, { useEffect, useState } from "react";
import { Container, Row, Col, Image, Button, Modal } from "react-bootstrap";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import EditLoker from "./EditLoker";

function LokerDetailPanel() {
  const [lokerd, setLokerD] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(
        "https://ika.pcr.ac.id/backend_ikapcr/public/api/loker/" +
          location.state.id_loker_current
      )
      .then((response) => {
        setLokerD(response.data);
      });
  }, []);

  function navigateToEdit(id_loker_value) {
    navigate("/editloker", {
      state: { id_loker_current: id_loker_value },
    });
  }

  const handleEditClick = () => {
    navigateToEdit(location.state.id_loker_current);
  };

  return (
    <div>
      {" "}
      {lokerd.map((lokerde) => {
        return (
          <div key={lokerde.id_loker}>
            <div style={{ paddingTop: "70px" }}>
              <div className="main-title">
                <h2>
                  <strong>{lokerde.judul_lowongan}</strong>
                </h2>
              </div>
            </div>
            <Container style={{ padding: 30 }}>
              <div>
                <Row>
                  <Col>
                    <Image
                      className="loker-card-detail-images"
                      src={
                        "https://ika.pcr.ac.id/backend_ikapcr/public/storage/loker/" +
                        lokerde.gambar
                      }
                      alt={lokerde.gambar}
                    />
                  </Col>

                  <Col>
                    <Button onClick={handleShow}>Edit</Button>
                    <Modal show={show} onHide={handleClose}>
                      {location.state && (
                        <EditLoker id_loker={location.state.id_loker_current} />
                      )}
                    </Modal>
                    <p>{ReactHtmlParser(lokerde.deskipsi)}</p> <br></br>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
        );
      })}
    </div>
  );
}

export default LokerDetailPanel;
