import React, { useEffect, useState } from "react";
import { Container, Image, Form, Button } from "react-bootstrap";
import axios from "axios";
import { useLocation } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import moment from "moment/moment";

function BeritaDetail(id_berita) {
  const [berita, setBerita] = useState([]);
  const location = useLocation();
  const [pesan, setPesan] = useState([]);
  const [komen, setKomen] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);

  console.log(location.state.id_berita_current);

  console.log(id_berita);

  const handleSubmitKomen = (event) => {
    var data = new FormData();

    data.append("id_alumni", parseInt(sessionStorage.getItem("id_alumni")));
    data.append("id_berita", parseInt(location.state.id_berita_current));
    data.append("komen", pesan);
    fetch("https://ika.pcr.ac.id/backend_ikapcr/public/api/komen", {
      method: "POST",
      body: data,
    }).then((res) => res.json());
    document.getElementById("komen").value = "";
    setRefreshKey((oldKey) => oldKey + 1);
  };

  useEffect(() => {
    axios
      .get("https://ika.pcr.ac.id/backend_ikapcr/public/api/komen/")
      .then((response) => {
        setKomen(response.data);
        console.log(response.data);
      });

    axios
      .get(
        "https://ika.pcr.ac.id/backend_ikapcr/public/api/berita/" +
          location.state.id_berita_current
      )
      .then((response) => {
        setBerita(response.data);
        console.log(response.data);
      });
  }, [refreshKey]);

  return (
    <div>
      {berita.map((beri) => {
        return (
          <div key={beri.id_berita}>
           <div style={{ paddingTop: "70px" }}>
        <div className="main-title">
          <h4 style={{color:'#ffffff'}}>
          <strong>{beri.judul_berita}</strong>
          </h4>
              <div className="berita-detail-tanggal">
                <h6>
                  Tanggal Posting {moment(beri.tanggal_posting).format("LL")}
                </h6>
              </div>
        </div>
      </div>
            <Container className="box">
              <div className="berita-detail-gambar">
                <Image
                  src={
                    "https://ika.pcr.ac.id/backend_ikapcr/public/storage/berita/" +
                    beri.gambar
                  }
                  alt={beri.gambar}
                  className="berita-detail-gambar-isi"
                ></Image>
              </div>
              <div className="berita-detail-isi">
                <p>{ReactHtmlParser(beri.isi_berita)}</p>
              </div>

              <div className="box-komentar">
                <div className="p-box-komentar">
                  <h4>Leave Reply here</h4>
                  <Form onSubmit={handleSubmitKomen}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Control
                        style={{ borderRadius: 10 }}
                        as="textarea"
                        rows={3}
                        placeholder="write your message here.."
                        id="komen"
                        onChange={(e) => setPesan(e.target.value)}
                      />
                    </Form.Group>
                    <div className="komentar-btn">
                      <Button onClick={() => handleSubmitKomen()}>Kirim</Button>
                    </div>
                  </Form>

                  <div>
                    <p className="comments-count">
                      {
                        komen.filter(
                          (komentar) => komentar.id_berita === beri.id_berita
                        ).length
                      }{" "}
                      Komentar
                    </p>
                    <div className="comment">
                      {komen
                        .filter(
                          (komentar) =>
                            komentar.id_berita ===
                            parseInt(location.state.id_berita_current)
                        )
                        .map((komens) => {
                          return (
                            <div>
                              <div className="d-flex">
                                <div className="comment-img">
                                  <img
                                    src={
                                      "https://ika.pcr.ac.id/backend_ikapcr/public/storage/alumni/" +
                                      komens.foto
                                    }
                                    alt={komens.foto}
                                  />
                                </div>
                                <div>
                                  <h5>{komens.nama}</h5>
                                  <p>{komens.komen}</p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        );
      })}
    </div>
  );
}

export default BeritaDetail;
