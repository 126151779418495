import { Route, Routes } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import '../node_modules/admin-lte/dist/js/adminlte.min.js';
import PanelSidebar from "./components/pages/UserPanel/PanelSidebar";
import PanelNavbar from "./components/pages/UserPanel/PanelNavbar";
import UsahaAlumni from "./components/pages/Alumni/UsahaAlumni";
import UsahaAlumniUSer from "./components/pages/User/UsahaAlumniUser";
import LokerUserPanel from "./components/pages/UserPanel/DataLoker/LokerUserPanel.js";
import LokerDetailPanel from "./components/pages/UserPanel/DataLoker/LokerDetailPanel.js";
import ProfilAlumni from "./components/pages/User/ProfilAlumni"
import EditProfilPanel from "./components/pages/UserPanel/DataProfil/EditProfilPanel.js";
import ProfilPanel from "./components/pages/UserPanel/DataProfil/ProfilPanel.js";
import UsahaAlumniDetailUser from "./components/pages/User/UsahaAlumniDetailUser.js";
import UpdateUsahaAlumni from "./components/pages/User/UpdateUsahaAlumni.js";
import UsahaUserPanel from "./components/pages/UserPanel/DataUsaha/UsahaUserPanel.js";
import UsahaDetailPanel from "./components/pages/UserPanel/DataUsaha/UsahaDetailPanel.js";
import EditUsaha from "./components/pages/UserPanel/DataUsaha/EditUsaha.js";
import EditLoker from "./components/pages/UserPanel/DataLoker/EditLoker.js";
import StatusInputan from "./components/pages/UserPanel/StatusInputan.js";

export default function Panel() {
  return (
    <>
    <div className="wrapper">
      <PanelNavbar />
      <PanelSidebar />
      <div className="content-wrapper">
        <section className="content">
          <div className="container-fluid">
          <Routes>
              <Route path="/usahaalumni" element={<UsahaUserPanel/>}></Route>
            </Routes>
            <Routes>
              <Route path="/loker" element={<LokerUserPanel/>}></Route>
            </Routes>
            <Routes>
              <Route path="/lokerdetail" element={<LokerDetailPanel/>}></Route>
            </Routes>
            <Routes>
              <Route path="/editloker" element={<EditLoker/>}></Route>
            </Routes>
            <Routes>
              <Route path="/profilpanel" element={<ProfilPanel/>}></Route>
            </Routes>
            <Routes>
              <Route path="/editprofilpanel" element={<EditProfilPanel/>}></Route>
            </Routes>
            <Routes>
              <Route path="/usahadetailpanel" element={<UsahaDetailPanel/>}></Route>
            </Routes>
            <Routes>
              <Route path="/editusaha" element={<EditUsaha />}></Route>
            </Routes>
            <Routes>
              <Route path="/statusinputan" element={<StatusInputan />}></Route>
            </Routes>
          </div>
        </section>
      </div>
    </div>
    </>
  );
}
