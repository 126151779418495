import { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";

function UpdateUsahaAlumni({ id_usaha_alumni }) {
  const handleSubmit = (event) => {
    event.preventDefault();

    var data = new FormData();
    var fotodata = document.getElementById("foto_usaha").files[0];
    // var dataAlumni = document.getElementById('id_alumni');

    data.append("id_alumni", sessionStorage.getItem("id_alumni"));
    data.append("nama_usaha", nama_usaha);
    data.append("jenis_usaha", jenis_usaha);
    data.append("pemilik_usaha", pemilik_usaha);
    data.append("deskripsi_usaha", deskripsi_usaha);
    data.append("alamat_usaha", alamat_usaha);
    data.append("no_hp", no_hp);
    data.append("email", email);
    data.append("whatsapp", whatsapp);
    data.append("link_tree", link_tree);
    data.append("tiktok", tiktok);
    data.append("instagram", instagram);
    data.append("facebook", facebook);
    data.append("shope", shope);
    data.append("tokopedia", tokopedia);
    data.append("youtube", youtube);
    data.append("website", website);
    data.append("status", "Proses");
    if (fotodata !== undefined) {
      data.append("foto_usaha", fotodata);
    }

    fetch(
      `https://ika.pcr.ac.id/backend_ikapcr/public/api/UsahaAlumni/update-data/${id_usaha_alumni}`,
      {
        method: "POST",
        body: data,
      }
    )
      .then((res) => res.json())
      .then((updatedData) => {
        setUsahaAlumniE([updatedData]);

        Swal.fire("Success", "Data berhasil diperbarui!", "success");
      })
      .catch((error) => {
        console.error("Error updating data:", error);
        Swal.fire("Error", "Terjadi kesalahan saat memperbarui data.", "error");
      });
  };

  const [usahaalumnie, setUsahaAlumniE] = useState([]);
  useEffect(() => {
    axios
      .get(
        "https://ika.pcr.ac.id/backend_ikapcr/public/api/UsahaAlumni/" +
          sessionStorage.getItem("id_alumni")
      )
      .then((response) => {
        setUsahaAlumniE(response.data);
        console.log(response.data);
      });
  }, []);

  const [nama_usaha, setNamaUsaha] = useState("");
  const [jenis_usaha, setJenisUsaha] = useState("");
  const [alamat_usaha, setAlamatUsaha] = useState("");
  const [pemilik_usaha, setPemilikUsaha] = useState("");
  const [no_hp, setNoHp] = useState("");
  const [email, setEmail] = useState("");
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [link_tree, setLinktree] = useState("");
  const [tiktok, setTiktok] = useState("");
  const [shope, setShope] = useState("");
  const [tokopedia, setTokopedia] = useState("");
  const [youtube, setYoutube] = useState("");
  const [website, setWebsite] = useState("");
  const [status, setStatus] = useState("");
  const [foto_usaha, setFoto] = useState();
  const [deskripsi_usaha, setDeskripsiUsaha] = useState("");

  const loadImage = (e) => {
    const image = e.target.files[0];
    setFoto(image);
    setFoto(URL.createObjectURL(image));
  };

  const handleFotoInput = (e) => {
    setFoto(e.target.files[0]);
  };

  return (
    <div>
      {/* {usahaalumnie
        .filter(
          (obj) =>
            obj.id_alumni === parseInt(sessionStorage.getItem("id_alumni"))
        )
        .map((us) => { */}
      {/* // console.log(sessionStorage.getItem('id_alumni'));
          // console.log(al.id_alumni);
          return ( */}
      <div style={{ marginLeft: 20 }}>
        <Form noValidate onSubmit={handleSubmit} encType="multipart/form-data">
          <Row>
            <Col>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Nama Usaha</Form.Label>
                <Form.Control
                  type="text"
                  id="nama_usaha"
                  onChange={(e) => setNamaUsaha(e.target.value)}
                />
                <Form.Text style={{ color: "red" }}>*required</Form.Text>
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Jenis Usaha</Form.Label>
                <Form.Select
                  type="text"
                  id="status"
                  onChange={(e) => setJenisUsaha(e.target.value)}
                >
                  <option>Pilih Jenis Usaha</option>
                  <option value="Fashion">Fashion</option>
                  <option value="Food and Beverage">Food and Beverage</option>
                  <option value="Jasa">Jasa</option>
                  <option value="Elektronik">Elektronik</option>
                </Form.Select>
                <Form.Text style={{ color: "red" }}>*required</Form.Text>
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Alamat Usaha</Form.Label>
                <Form.Control
                  type="text"
                  id="alamat_usaha"
                  onChange={(e) => setAlamatUsaha(e.target.value)}
                />
                <Form.Text style={{ color: "red" }}>*required</Form.Text>
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  id="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Form.Text style={{ color: "red" }}>*required</Form.Text>
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>No Hp</Form.Label>
                <Form.Control
                  type="text"
                  id="no_hp"
                  onChange={(e) => setNoHp(e.target.value)}
                />
                <Form.Text style={{ color: "red" }}>*required</Form.Text>
              </Form.Group>
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Inputkan Gambar Usaha</Form.Label>
                <Form.Control
                  type="file"
                  id="foto_usaha"
                  onChange={handleFotoInput}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Deskripis</Form.Label>
                <Form.Control
                  style={{ borderRadius: 10 }}
                  as="textarea"
                  rows={3}
                  placeholder="deskripsikan usaha anda..."
                  id="deskripsi_usaha"
                  onChange={(e) => setDeskripsiUsaha(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Facebook</Form.Label>
                <Form.Control
                  type="text"
                  id="facebook"
                  onChange={(e) => setFacebook(e.target.value)}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Instagram</Form.Label>
                <Form.Control
                  type="text"
                  id="instagram"
                  onChange={(e) => setInstagram(e.target.value)}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Whatsapp</Form.Label>
                <Form.Control
                  type="text"
                  id="whatsapp"
                  onChange={(e) => setWhatsapp(e.target.value)}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Tiktok</Form.Label>
                <Form.Control
                  type="text"
                  id="tiktok"
                  onChange={(e) => setTiktok(e.target.value)}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Biolink</Form.Label>
                <Form.Control
                  type="text"
                  id="linktree"
                  onChange={(e) => setLinktree(e.target.value)}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Shopee</Form.Label>
                <Form.Control
                  type="text"
                  id="shope"
                  onChange={(e) => setShope(e.target.value)}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Tokopedia</Form.Label>
                <Form.Control
                  type="text"
                  id="tokopedia"
                  onChange={(e) => setTokopedia(e.target.value)}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Youtube</Form.Label>
                <Form.Control
                  type="text"
                  id="youtube"
                  onChange={(e) => setYoutube(e.target.value)}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Website</Form.Label>
                <Form.Control
                  type="text"
                  id="website"
                  onChange={(e) => setWebsite(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Button type="submit" onClick={() => handleSubmit()}>
            Daftar
          </Button>
          {/* <Button variant="secondary" onClick={handleClose}>
        Close
      </Button> */}
        </Form>
      </div>
    </div>
  );
}

export default UpdateUsahaAlumni;
