import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  Row,
  Col,
  Image,
  Button,
  Modal,
  Tabs,
  Tab,
} from "react-bootstrap";
import axios from "axios";
import { FiEdit } from "react-icons/fi";
import { BiLogOut } from "react-icons/bi";
import { FcProcess, FcApproval, FcCancel } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import EditProfilPanel from "./EditProfilPanel";

function ProfilPanel() {
  const [key, setKey] = useState("home");
  const [alumnis, setalumnis] = useState([]);
  const [lokera, setLokera] = useState([]);
  const [usahaal, setUsahaAl] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get("https://ika.pcr.ac.id/backend_ikapcr/public/api/alumni")
      .then((response) => {
        setalumnis(response.data);
        // console.log(response.data);
      });
  }, []);

  const logoutCall = () => {
    sessionStorage.removeItem("id_alumni");
    navigate("/login");
  };

  function navigateToEdit() {
    navigate("/editprofilpanel");
  }

  // const getUserLogin = alumnis.find(
  //   (u) => u.id_alumni === sessionStorage.getItem("id_alumni")
  // );

  return (
    <div>
      <div style={{ paddingTop: "70px" }}></div>
      <Container>
        {alumnis
          .filter(
            (obj) =>
              obj.id_alumni === parseInt(sessionStorage.getItem("id_alumni"))
          )
          .map((al) => {
            return (
              <div key={al.id_alumni}>
                <div>
                  <Container>
                    <Row>
                      <Col>
                        <Card className="profil-card">
                          <Card.Header
                            style={{ backgroundColor: "#0b395d" }}
                          ></Card.Header>
                          <div className="profil-card-isi">
                            <Image
                              roundedCircle
                              src={
                                "https://ika.pcr.ac.id/backend_ikapcr/public/storage/alumni/" +
                                al.foto
                              }
                              alt={al.foto}
                              className="profil-card-image"
                            />
                            <div className="profil-card-nama">{al.nama}</div>
                            <div className="profil-card-isi-dt">
                              {al.jurusan}
                              <div className="profil-card-isi-dt">
                                {al.prodi}
                                <div className="profil-card-isi-dt">
                                  Generasi {al.generasi}
                                </div>
                              </div>
                            </div>
                            {/* <Button onClick={navigateToEdit}>
                              <FiEdit /> Edit Profil
                            </Button> */}
                            <Button onClick={handleShow}>    <FiEdit /> Edit Profil</Button>
                      <Modal show={show}  size='xl' onHide={handleClose}>
                       <EditProfilPanel/>
                      </Modal>
                          </div>
                        </Card>
                      </Col>
                      <Col sm={8}>
                        <div>
                          <Card className="profil-card">
                            <Tabs
                              id="controlled-tab-example"
                              activeKey={key}
                              onSelect={(k) => setKey(k)}
                            >
                              <Tab eventKey="home" title="Detail Data Diri">
                                <div>
                                  <div className="profil-pribadi-card">
                                    <Card.Body>
                                      <Row className="profil-row">
                                        <Col sm={4} className="profil-label">
                                          Nama Lengkap
                                        </Col>
                                        <Col sm={8} className="profil-text">
                                          {al.nama}
                                        </Col>
                                      </Row>
                                      <Row className="profil-row">
                                        <Col sm={4} className="profil-label">
                                          Agama
                                        </Col>
                                        <Col sm={8} className="profil-text">
                                          {al.agama}
                                        </Col>
                                      </Row>
                                      <Row className="profil-row">
                                        <Col sm={4} className="profil-label">
                                          Jenis Kelamin
                                        </Col>
                                        <Col sm={8} className="profil-text">
                                          {al.jenis_kelamin}
                                        </Col>
                                      </Row>
                                      <Row className="profil-row">
                                        <Col sm={4} className="profil-label">
                                          Tempat Lahir
                                        </Col>
                                        <Col sm={8} className="profil-text">
                                          {al.tempat_lahir}
                                        </Col>
                                      </Row>
                                      <Row className="profil-row">
                                        <Col sm={4} className="profil-label">
                                          Tanggal Lahir
                                        </Col>
                                        <Col sm={8} className="profil-text">
                                          {moment(al.tanggal_lahir).format(
                                            "LL"
                                          )}
                                        </Col>
                                      </Row>
                                      <Row className="profil-row">
                                        <Col sm={4} className="profil-label">
                                          Alamat
                                        </Col>
                                        <Col sm={8} className="profil-text">
                                          {al.alamat}
                                        </Col>
                                      </Row>
                                      <Row className="profil-row">
                                        <Col sm={4} className="profil-label">
                                          Provinsi
                                        </Col>
                                        <Col sm={8} className="profil-text">
                                          {al.provinsi}
                                        </Col>
                                      </Row>
                                      <Row className="profil-row">
                                        <Col sm={4} className="profil-label">
                                          Kota/Kabupaten
                                        </Col>
                                        <Col sm={8} className="profil-text">
                                          {al.kota}
                                        </Col>
                                      </Row>
                                      <Row className="profil-row">
                                        <Col sm={4} className="profil-label">
                                          Kelurahan
                                        </Col>
                                        <Col sm={8} className="profil-text">
                                          {al.kelurahan}
                                        </Col>
                                      </Row>
                                      <Row className="profil-row">
                                        <Col sm={4} className="profil-label">
                                          Kecamatan
                                        </Col>
                                        <Col sm={8} className="profil-text">
                                          {al.kecamatan}
                                        </Col>
                                      </Row>
                                      <Row className="profil-row">
                                        <Col sm={4} className="profil-label">
                                          No HP
                                        </Col>
                                        <Col sm={8} className="profil-text">
                                          {al.no_hp}
                                        </Col>
                                      </Row>
                                      <Row className="profil-row">
                                        <Col sm={4} className="profil-label">
                                          Email
                                        </Col>
                                        <Col sm={8} className="profil-text">
                                          {al.email_alumni}
                                        </Col>
                                      </Row>
                                    </Card.Body>
                                  </div>
                                </div>
                              </Tab>
                              <Tab eventKey="profile" title="Detail Data Pekerjaan">
                                <div>
                                  <div className="profil-pribadi-card">
                                    <Card.Body>
                                      <Row className="profil-row">
                                        <Col sm={4} className="profil-label">
                                          Status Pekerjaan
                                        </Col>
                                        <Col sm={8} className="profil-text">
                                          {al.status_pekerjaan}
                                        </Col>
                                      </Row>
                                      <Row className="profil-row">
                                        <Col sm={4} className="profil-label">
                                          Nama Kantor
                                        </Col>
                                        <Col sm={8} className="profil-text">
                                          {al.nama_kantor}
                                        </Col>
                                      </Row>
                                      <Row className="profil-row">
                                        <Col sm={4} className="profil-label">
                                          Alamat
                                        </Col>
                                        <Col sm={8} className="profil-text">
                                          {al.alamat_kantor}
                                        </Col>
                                      </Row>
                                      <Row className="profil-row">
                                        <Col sm={4} className="profil-label">
                                          Provinsi
                                        </Col>
                                        <Col sm={8} className="profil-text">
                                          {al.provinsi_kantor}
                                        </Col>
                                      </Row>

                                      <Row className="profil-row">
                                        <Col sm={4} className="profil-label">
                                          Kota/Kabupaten
                                        </Col>
                                        <Col sm={8} className="profil-text">
                                          {al.kota_kantor}
                                        </Col>
                                      </Row>
                                      <Row className="profil-row">
                                        <Col sm={4} className="profil-label">
                                          Divisi
                                        </Col>
                                        <Col sm={8} className="profil-text">
                                          {al.divisi}
                                        </Col>
                                      </Row>

                                      <Row className="profil-row">
                                        <Col sm={4} className="profil-label">
                                          Jabatan
                                        </Col>
                                        <Col sm={8} className="profil-text">
                                          {al.jabatan}
                                        </Col>
                                      </Row>
                                    </Card.Body>
                                  </div>
                                </div>
                              </Tab>
                            </Tabs>
                          </Card>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            );
          })}
      </Container>
    </div>
  );
}

export default ProfilPanel;
