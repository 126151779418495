import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Card, Image, Button } from "react-bootstrap";
import axios from "axios";
import Paginate from "../../inc/Paginate";
import { BiSearchAlt } from "react-icons/bi";


function KisahAlumni() {
  const [kisahalumni, setKA] = useState([]);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(8);
  const [searchValue, setSearchValue] = useState("");

  function navigateToKADetail(id_kisah_alumni_value) {
    navigate("/main/kisahalumnidetail", {
      state: { id_kisah_alumni_current: id_kisah_alumni_value },
    });
  }

  useEffect(() => {
    axios
      .get("https://ika.pcr.ac.id/backend_ikapcr/public/api/KisahAlumni")
      .then((response) => {
        setKA(response.data);
      });
  }, []);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = kisahalumni.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const previousPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== Math.ceil(kisahalumni.length / postsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div>
      <div style={{ paddingTop: "70px" }}>
        <div className="main-title">
          <h1>
            <strong>Kisah Alumni</strong>
          </h1>
        </div>
      </div>
      <Container style={{ padding: 30 }}>
        <div className="search">
          <div className="search-box">
            <Button className="btn-search">
              <BiSearchAlt />
            </Button>
            <input
              className="input-search"
              type="text"
              name="search"
              placeholder="Type to Search..."
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
        </div>
        <div className="konten">
          <Row>
            {currentPosts
              .filter((ksjudul) =>
                ksjudul.judul_kisah.match(new RegExp(searchValue, "i"))
              )
              .filter((ksalumni) =>
                ksalumni.nama.match(new RegExp(searchValue, "i"))
              )
              .map((kisahalumnis) => {
                return (
                  <Col
                    sm={3}
                    style={{ paddingTop: "20px" }}
                    key={kisahalumnis.id_kisah_alumni}
                  >
                    <div className="kisah-alumni-card">
                      <Card
                        className="kisah-alumni-border-card"
                        onClick={() =>
                          navigateToKADetail(kisahalumnis.id_kisah_alumni)
                        }
                      >
                        <Image
                          className="kisah-alumni-images"
                          roundedCircle
                          src={
                            "https://ika.pcr.ac.id/backend_ikapcr/public/storage/KisahAlumni/" +
                            kisahalumnis.foto_kisah
                          }
                          alt={kisahalumnis.foto_kisah}
                        ></Image>
                        <Card.Body>
                          <div
                            className="kisah-alumni-prodi"
                            style={{ marginTop: -30 }}
                          >
                            <small> {kisahalumnis.judul_kisah}</small>
                          </div>
                          <div className="kisah-alumni-title-card ">
                            <h5>{kisahalumnis.nama}</h5>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </Col>
                );
              })}
          </Row>
        </div>

        <div className="pagenation">
          <Paginate
            postsPerPage={postsPerPage}
            totalPosts={kisahalumni.length}
            paginate={paginate}
            previousPage={previousPage}
            nextPage={nextPage}
          />
        </div>
      </Container>
    </div>
  );
}

export default KisahAlumni;
