import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Nav, Card, Image } from "react-bootstrap";
import LogoImage from "../../assets/images/logo_ikapcr2.jpg";
import axios from "axios";
import "../../assets/css/Panel.css"; // Import the CSS file

const PanelSidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [alumnis, setalumnis] = useState([]);

  useEffect(() => {
    axios
      .get("https://ika.pcr.ac.id/backend_ikapcr/public/api/alumni")
      .then((response) => {
        setalumnis(response.data);
      });
  }, []);

  const logoutCall = () => {
    sessionStorage.removeItem("id_alumni");
    navigate("/../main/login");
    window.location.reload();
  };

  return (
    <div className="">
      <aside
        className="main-sidebar sidebar-primary elevation-4"
        style={{ backgroundColor: "rgb(11, 57, 93)" }}
      >
        <div className="brand-link">
          <img
            src={LogoImage}
            alt="Logo"
            className="brand-image img-circle elevation-3"
            style={{ opacity: ".8" }}
          />
          <span className="brand-text">Panel Users IKAPCR</span>
        </div>

        <div className="sidebar">
          <div>
            {alumnis
              .filter(
                (obj) =>
                  obj.id_alumni ===
                  parseInt(sessionStorage.getItem("id_alumni"))
              )
              .map((al) => (
                <div key={al.id_alumni}>
                  <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div className="image">
                      <img
                        src={
                          "https://ika.pcr.ac.id/backend_ikapcr/public/storage/alumni/" +
                          al.foto
                        }
                        alt={al.foto}
                        className="img-circle elevation-2"
                      />
                    </div>
                    <div
                      style={{
                        paddingLeft: 20,
                        color: "white",
                        fontFamily: "Inter, sans-serif",
                      }}
                    >
                      <div className="d-block">{al.nama}</div>
                    </div>
                  </div>
                </div>
              ))}
          </div>

          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              <li className="nav-item">
                <NavLink
                  to="/panel/profilpanel"
                  className={`nav-link ${
                    location.pathname === "/panel/profilpanel" ? "active" : ""
                  }`}
                >
                  <i className="nav-icon fas fa-users" />
                  <p>Users</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/panel/usahaalumni"
                  className={`nav-link ${
                    location.pathname === "/panel/usahaalumni" ? "active" : ""
                  }`}
                >
                <i className="nav-icon fa-solid fa-shop"></i>
                  <p>Data Usaha</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/panel/loker"
                  className={`nav-link ${
                    location.pathname === "/panel/loker" ? "active" : ""
                  }`}
                >
                  <i className="nav-icon fa-solid fa-link" />
                  <p>Data Loker</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/panel/statusinputan"
                  className={`nav-link ${
                    location.pathname === "/panel/statusinputan" ? "active" : ""
                  }`}
                >
                  <i className="nav-icon fas fa-cog" />
                  <p>Status Inputan</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <a onClick={logoutCall} className="nav-link">
                  <i className="nav-icon fas fa-cog" />
                  <p>Logout</p>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </aside>
    </div>
  );
};

export default PanelSidebar;
