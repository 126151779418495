import { BrowserRouter, Routes, Route } from "react-router-dom";    
// beranda
// import Navigasibar from './components/inc/Navigasibar';
import NavigationBar from "./components/inc/NavigationBar";
import Hero from "./components/inc/Hero";
import Footer from "./components/inc/Footer";

//isi beranda
import BeritaHomepage from "./components/pages/isi-beranda/BeritaHomepage";
import VisiMisiHomepage from "./components/pages/isi-beranda/VisiMisiHomepage";
import KisahAlumniHomepage from "./components/pages/isi-beranda/KisahAlumniHomepage";

// user
import RegisterUser from "./components/pages/User/RegisterUser";
import Login from "./components/pages/User/Login";
import Daftar from "./components/pages/User/Daftar";
import ProfilAlumni from "./components/pages/User/ProfilAlumni";
import EditProfilAlumni from "./components/pages/User/EditProfilAlumni";

// berita
import Berita from "./components/pages/Berita/Berita";
import BeritaDetail from "./components/pages/Berita/BeritaDetail";

//galeri
import Galeri from "./components/pages/Galeri/Galeri";
import GaleriDetail from "./components/pages/Galeri/GaleriDetail";

//profil ikapcr
import VisiMisi from "./components/pages/Profil/VisiMisi";
import SekapurSirih from "./components/pages/Profil/SekapurSirih";
import StrukturOrganisasi from "./components/pages/Profil/StrukturOrganisasi";
import ProgjaDepartment from "./components/pages/Profil/ProgjaDepartment";

//tentang
import FAQ from "./components/pages/Tentang/Faq";
import Kontak from "./components/pages/Tentang/Kontak";

//agenda
import Agenda from "./components/pages/Agenda/Agenda";
import AgendaDetail from "./components/pages/Agenda/AgendaDetail";

//kisah alumni
import KisahAlumni from "./components/pages/Alumni/KisahAlumni";
import KisahAlumniDetail from "./components/pages/Alumni/KisahAlumniDetail";

//loker
import Loker from "./components/pages/Loker/Loker";
import LokerDetail from "./components/pages/Loker/LokerDetail";

//usaha alumni
import UsahaAlumni from "./components/pages/Alumni/UsahaAlumni";
import UsahaAlumniDetail from "./components/pages/Alumni/UsahaAlumniDetail";
import UsahaAlumniUSer from "./components/pages/User/UsahaAlumniUser";
import UsahaAlumniDetailUser from "./components/pages/User/UsahaAlumniDetailUser";
import UpdateUsahaAlumni from "./components/pages/User/UpdateUsahaAlumni";

//statistik
import JumlahPertahun from "./components/pages/Alumni/Statistik/JumlahPertahun";
import Statistik from "./components/pages/Alumni/Statistik/Statistik";

//komentar
import LinkTerkait from "./components/inc/LinkTerkait";
import ArtiLogo from "./components/pages/Profil/ArtiLogo";
import Beranda from "./components/pages/Beranda";
import RecentPost from "./components/inc/RecentPost";
import Paginate from "./components/inc/Paginate";
import ScrollButton from "./components/inc/ScrollButton";

export default function Main(){
    return(
        <>
<NavigationBar />
        <Routes>
          <Route path="/" element={<Beranda />} />

          {/* isi beranda */}

          <Route path="beritahomepage" element={<BeritaHomepage />} />
          <Route path="visimisihomepage" element={<VisiMisiHomepage />} />
          <Route path="kisahalumnihomepage" element={<KisahAlumniHomepage />} />

          {/* user */}
          <Route path="register" element={<RegisterUser/>}/>
          <Route path="login" element={<Login />} />
          <Route path="daftar" element={<Daftar />} />
          <Route path="profilalumni" element={<ProfilAlumni />} />
          <Route path="editprofil" element={<EditProfilAlumni />} />

          {/* Galeri */}
          <Route path="galeri" element={<Galeri />} />
          <Route path="galeridetail" element={<GaleriDetail />} />

          {/* profil ikapcr */}
          <Route path="visimisi" element={<VisiMisi />} />
          <Route path="sekapursirih" element={<SekapurSirih />} />
          <Route path="strukturorganisai" element={<StrukturOrganisasi />} />
          <Route path="progjadepartment" element={<ProgjaDepartment />} />

          {/* kisahalumni */}
          <Route path="kisahalumni" element={<KisahAlumni />} />
          <Route path="kisahalumnidetail" element={<KisahAlumniDetail />} />

          {/* berita */}
          <Route path="berita" element={<Berita />} />
          <Route path="beritadetail" element={<BeritaDetail />} />

          {/* agenda */}
          <Route path="agenda" element={<Agenda />} />
          <Route path="agendadetail" element={<AgendaDetail />} />

          {/* tentang */}
          <Route path="kontak" element={<Kontak />} />
          <Route path="faq" element={<FAQ />} />

          {/* loker */}
          <Route path="loker" element={<Loker />} />
          <Route path="lokerdetail" element={<LokerDetail />} />

          {/* usahaalumni */}
          <Route path="usahaalumni" element={<UsahaAlumni />} />
          <Route path="usahaalumnidetail" element={<UsahaAlumniDetail />} />
          <Route path="usahaalumniuser" element={<UsahaAlumniUSer />} />
          <Route
            path="usahaalumnidetailuser"
            element={<UsahaAlumniDetailUser />}
          />
          <Route path="updateusahaalumni" element={<UpdateUsahaAlumni />} />

          <Route path="jumlahpertahun" element={<JumlahPertahun />} />

          <Route path="artilambang" element={<ArtiLogo />} />
          <Route path="statistik" element={<Statistik />} />
          <Route path="recentpost" element={<RecentPost />} />
          <Route path="paginate" element={<Paginate />} />
          <Route path="scrollbutton" element={<ScrollButton />} />
        </Routes>
        <LinkTerkait />
        <Footer />
        <ScrollButton />
        </>
    );
}