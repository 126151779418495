import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  Row,
  Col,
  Image,
  Button,
  Modal,
  Tab,
  Nav,
} from "react-bootstrap";
import axios from "axios";
import { FiEdit } from "react-icons/fi";
import { BiLogOut } from "react-icons/bi";
import { FcProcess, FcApproval, FcCancel } from "react-icons/fc";
import EditProfilAlumni from "./EditProfilAlumni";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import UsahaAlumniUSer from "./UsahaAlumniUser";
import LokerUser from "./LokerUser";

function ProfilAlumni() {
  const [alumnis, setalumnis] = useState([]);
  const [lokera, setLokera] = useState([]);
  const [usahaal, setUsahaAl] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  

  useEffect(() => {
    axios
      .get("https://ika.pcr.ac.id/backend_ikapcr/public/api/alumni")
      .then((response) => {
        setalumnis(response.data);
        // console.log(response.data);
      });
  }, []);

  useEffect(() => {
    axios
      .get("https://ika.pcr.ac.id/backend_ikapcr/public/api/loker")
      .then((response) => {
        setLokera(response.data);
        // console.log(response.data);
      });
  }, []);

  useEffect(() => {
    axios
      .get("https://ika.pcr.ac.id/backend_ikapcr/public/api/UsahaAlumni")
      .then((response) => {
        setUsahaAl(response.data);
        // console.log(response.data);
      });
  }, []);

  const logoutCall = () => {
    sessionStorage.removeItem("id_alumni");
    navigate("/login");
  };



  // const getUserLogin = alumnis.find(
  //   (u) => u.id_alumni === sessionStorage.getItem("id_alumni")
  // );



  return (
    <div>
       <div style={{ paddingTop: "70px" }}>
        <div className="main-title">
          <h1>
          <strong>Profil Alumni</strong>
          </h1>
        </div>
      </div>
      <Container className="box">

        {alumnis
          .filter(
            (obj) =>
              obj.id_alumni === parseInt(sessionStorage.getItem("id_alumni"))
          )
          .map((al) => {
  
            return (
              <div key={al.id_alumni}>
                <div>
                  <Container>
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="1"
                    >
                      <Row>
                        <Col sm={4}>
                          <Card className="profil-card">
                            <div className="profil-card-isi">
                              <Image
                                roundedCircle
                                src={
                                  "https://ika.pcr.ac.id/backend_ikapcr/public/storage/alumni/" +
                                  al.foto
                                }
                                alt={al.foto}
                                className="profil-card-image"
                              />
                              <div className="profil-card-nama">{al.nama}</div>
                              <div className="profil-card-isi-dt">
                                {al.jurusan}
                                <div className="profil-card-isi-dt">
                                  {al.prodi}
                                  <div className="profil-card-isi-dt">
                                    Generasi {al.generasi}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Card>
                          <div style={{ paddingTop: "20px" }}>
                            <Nav
                              id="profilalumni"
                              variant="pills"
                              className="flex-column"
                            >
                              <Nav.Item>
                                <Nav.Link eventKey="1">Data Alumni</Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="2">Data Pekerjaan</Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="3">Data Usaha</Nav.Link>
                              </Nav.Item>
                          
                              <Nav.Item>
                                <Nav.Link eventKey="4">Data Loker</Nav.Link>
                              </Nav.Item>
                       
                              <Nav.Item>
                                <Nav.Link eventKey="5">Status Inputan</Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                              <Col className='btn-logout'>
                                            <Button onClick={logoutCall}> <BiLogOut /> Logout</Button>

                                        </Col>
                              </Nav.Item>
                            </Nav>
                          </div>
                        </Col>
                        <Col sm={8}>
                          <Tab.Content id="profilalumnitab">
                            <Tab.Pane eventKey="1">
                              <div>
                                <Card className="profil-card">
                                  <div className="profil-pribadi-card">
                                    <Card.Header className="profil-card-header">
                                      <div>
                                        <Row>
                                          <Col
                                            sm={6}
                                            className="profil-card-title"
                                          >
                                            Detail Data Diri
                                          </Col>
                                          <Col className="btn-edit-profil">
                                            <a href="http://localhost:3000/panel">
                                            <Button>
                                              {" "}
                                              <FiEdit /> Update Data Diri
                                            </Button>
                                            </a>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Card.Header>

                                    <div>
                                      
                                    </div>
                                    <Card.Body>
                                      <Row className="profil-row">
                                        <Col sm={4} className="profil-label">
                                          Nama Lengkap
                                        </Col>
                                        <Col sm={8} className="profil-text">
                                          {al.nama}
                                        </Col>
                                      </Row>
                                      <Row className="profil-row">
                                        <Col sm={4} className="profil-label">
                                          Agama
                                        </Col>
                                        <Col sm={8} className="profil-text">
                                          {al.agama}
                                        </Col>
                                      </Row>
                                      <Row className="profil-row">
                                        <Col sm={4} className="profil-label">
                                          Jenis Kelamin
                                        </Col>
                                        <Col sm={8} className="profil-text">
                                          {al.jenis_kelamin}
                                        </Col>
                                      </Row>
                                      <Row className="profil-row">
                                        <Col sm={4} className="profil-label">
                                          Tempat Lahir
                                        </Col>
                                        <Col sm={8} className="profil-text">
                                          {al.tempat_lahir}
                                        </Col>
                                      </Row>
                                      <Row className="profil-row">
                                        <Col sm={4} className="profil-label">
                                          Tanggal Lahir
                                        </Col>
                                        <Col sm={8} className="profil-text">
                                          {moment(al.tanggal_lahir).format(
                                            "LL"
                                          )}
                                        </Col>
                                      </Row>
                                      <Row className="profil-row">
                                        <Col sm={4} className="profil-label">
                                          Alamat
                                        </Col>
                                        <Col sm={8} className="profil-text">
                                          {al.alamat}
                                        </Col>
                                      </Row>
                                      <Row className="profil-row">
                                        <Col sm={4} className="profil-label">
                                          Provinsi
                                        </Col>
                                        <Col sm={8} className="profil-text">
                                          {al.provinsi}
                                        </Col>
                                      </Row>
                                      <Row className="profil-row">
                                        <Col sm={4} className="profil-label">
                                          Kota/Kabupaten
                                        </Col>
                                        <Col sm={8} className="profil-text">
                                          {al.kota}
                                        </Col>
                                      </Row>
                                      <Row className="profil-row">
                                        <Col sm={4} className="profil-label">
                                          Kelurahan
                                        </Col>
                                        <Col sm={8} className="profil-text">
                                          {al.kelurahan}
                                        </Col>
                                      </Row>
                                      <Row className="profil-row">
                                        <Col sm={4} className="profil-label">
                                          Kecamatan
                                        </Col>
                                        <Col sm={8} className="profil-text">
                                          {al.kecamatan}
                                        </Col>
                                      </Row>
                                      <Row className="profil-row">
                                        <Col sm={4} className="profil-label">
                                          No HP
                                        </Col>
                                        <Col sm={8} className="profil-text">
                                          {al.no_hp}
                                        </Col>
                                      </Row>
                                      <Row className="profil-row">
                                        <Col sm={4} className="profil-label">
                                          Email
                                        </Col>
                                        <Col sm={8} className="profil-text">
                                          {al.email_alumni}
                                        </Col>
                                      </Row>
                                    </Card.Body>
                                  </div>
                                </Card>
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="2">
                              <div className="profil-perkerjaan-card">
                                <div>
                                  <Card className="profil-card">
                                    <div className="profil-pribadi-card">
                                      <Card.Header className="profil-card-header">
                                        <div className="profil-card-title">
                                          Data Pekerjaan
                                        </div>
                                      </Card.Header>
                                      <Card.Body>
                                        <Row className="profil-row">
                                          <Col sm={4} className="profil-label">
                                            Status Pekerjaan
                                          </Col>
                                          <Col sm={8} className="profil-text">
                                            {al.status_pekerjaan}
                                          </Col>
                                        </Row>
                                        <Row className="profil-row">
                                          <Col sm={4} className="profil-label">
                                            Nama Kantor
                                          </Col>
                                          <Col sm={8} className="profil-text">
                                            {al.nama_kantor}
                                          </Col>
                                        </Row>
                                        <Row className="profil-row">
                                          <Col sm={4} className="profil-label">
                                            Alamat
                                          </Col>
                                          <Col sm={8} className="profil-text">
                                            {al.alamat_kantor}
                                          </Col>
                                        </Row>
                                        <Row className="profil-row">
                                          <Col sm={4} className="profil-label">
                                            Provinsi
                                          </Col>
                                          <Col sm={8} className="profil-text">
                                            {al.provinsi_kantor}
                                          </Col>
                                        </Row>

                                        <Row className="profil-row">
                                          <Col sm={4} className="profil-label">
                                            Kota/Kabupaten
                                          </Col>
                                          <Col sm={8} className="profil-text">
                                            {al.kota_kantor}
                                          </Col>
                                        </Row>
                                        <Row className="profil-row">
                                          <Col sm={4} className="profil-label">
                                            Divisi
                                          </Col>
                                          <Col sm={8} className="profil-text">
                                            {al.divisi}
                                          </Col>
                                        </Row>
                                      
                                        <Row className="profil-row">
                                          <Col sm={4} className="profil-label">
                                            Jabatan
                                          </Col>
                                          <Col sm={8} className="profil-text">
                                            {al.jabatan}
                                          </Col>
                                        </Row>
                                      </Card.Body>
                                    </div>
                                  </Card>
                                </div>
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="3">
                             <UsahaAlumniUSer/>
                            </Tab.Pane>
                            <Tab.Pane eventKey="4">
                            <LokerUser/>
                            </Tab.Pane>

                            <Tab.Pane eventKey="5">
                              <div>
                                <div className="profil-notif">
                                  <Card className="profil-card">
                                    <div className="profil-notif-isi">
                                      <Card.Header className="profil-card-header">
                                        <div className="profil-card-title">
                                          Status Inputan
                                        </div>
                                      </Card.Header>
                                      <div>
                                        {lokera
                                          .filter(
                                            (obj) =>
                                              obj.id_alumni ===
                                              parseInt(
                                                sessionStorage.getItem(
                                                  "id_alumni"
                                                )
                                              )
                                          )
                                          .filter(
                                            (lokera) =>
                                              lokera.status == "Proses"
                                          )
                                          .map((lokers) => {
                                            return (
                                              <div
                                                key={al.id_alumni}
                                                className="profil-notif"
                                              >
                                                <div className="profil-notif-loker-icon">
                                                  {" "}
                                                  <FcProcess />{" "}
                                                </div>
                                                <div className="profil-notif-loker-isi">
                                                  Lowongan Pekerjaan
                                                  <p className="notif-text">
                                                    {" "}
                                                    {lokers.judul_lowongan}
                                                  </p>{" "}
                                                  sedang dilakukan pengecekan
                                                  oleh admin
                                                </div>
                                              </div>
                                            );
                                          })}
                                      </div>
                                      <div>
                                        {lokera
                                          .filter(
                                            (obj) =>
                                              obj.id_alumni ===
                                              parseInt(
                                                sessionStorage.getItem(
                                                  "id_alumni"
                                                )
                                              )
                                          )
                                          .filter(
                                            (lokera) =>
                                              lokera.status == "Approve"
                                          )
                                          .map((lokers) => {
                                            return (
                                              <div
                                                key={al.id_alumni}
                                                className="profil-notif"
                                              >
                                                <div className="profil-notif-loker-icon">
                                                  {" "}
                                                  <FcApproval />{" "}
                                                </div>
                                                <div className="profil-notif-loker-isi">
                                                  Lowongan Pekerjaan
                                                  <p className="notif-text">
                                                    {" "}
                                                    {lokers.judul_lowongan}
                                                  </p>{" "}
                                                  diupload ke halaman loker
                                                </div>
                                              </div>
                                            );
                                          })}
                                      </div>
                                      <div>
                                        {lokera
                                          .filter(
                                            (obj) =>
                                              obj.id_alumni ===
                                              parseInt(
                                                sessionStorage.getItem(
                                                  "id_alumni"
                                                )
                                              )
                                          )
                                          .filter(
                                            (lokera) =>
                                              lokera.status == "Decline"
                                          )
                                          .map((lokers) => {
                                            return (
                                              <div
                                                key={al.id_alumni}
                                                className="profil-notif"
                                              >
                                                <div className="profil-notif-loker-icon">
                                                  {" "}
                                                  <FcCancel />{" "}
                                                </div>
                                                <div className="profil-notif-loker-isi">
                                                  Lowongan Pekerjaan
                                                  <p className="notif-text">
                                                    {" "}
                                                    {lokers.judul_lowongan}
                                                  </p>{" "}
                                                  ditolak
                                                </div>
                                              </div>
                                            );
                                          })}
                                      </div>
                                      <div>
                                        {usahaal
                                          .filter(
                                            (obj) =>
                                              obj.id_alumni ===
                                              parseInt(
                                                sessionStorage.getItem(
                                                  "id_alumni"
                                                )
                                              )
                                          )
                                          .filter(
                                            (usahaal) =>
                                              usahaal.status == "Approve"
                                          )
                                          .map((usahaas) => {
                                            return (
                                              <div
                                                key={al.id_alumni}
                                                className="profil-notif"
                                              >
                                                <div className="profil-notif-loker-icon">
                                                  {" "}
                                                  <FcApproval />{" "}
                                                </div>
                                                <div className="profil-notif-loker-isi">
                                                  <p className="notif-text">
                                                    {" "}
                                                    {usahaas.nama_usaha}
                                                  </p>
                                                  yang anda inputkan sudah
                                                  berhasil dan tampil di halaman
                                                  Ikapcr Preneur
                                                </div>
                                              </div>
                                            );
                                          })}
                                      </div>
                                      <div>
                                        {usahaal
                                          .filter(
                                            (obj) =>
                                              obj.id_alumni ===
                                              parseInt(
                                                sessionStorage.getItem(
                                                  "id_alumni"
                                                )
                                              )
                                          )
                                          .filter(
                                            (usahaal) =>
                                              usahaal.status == "Proses"
                                          )
                                          .map((usahaas) => {
                                            return (
                                              <div
                                                key={al.id_alumni}
                                                className="profil-notif"
                                              >
                                                <div className="profil-notif-loker-icon">
                                                  {" "}
                                                  <FcProcess />{" "}
                                                </div>
                                                <div className="profil-notif-loker-isi">
                                                  <p className="notif-text">
                                                    {" "}
                                                    {usahaas.nama_usaha}
                                                  </p>
                                                  yang anda inputkan sedang di
                                                  validasi oleh admin, harap
                                                  menunggu..
                                                </div>
                                              </div>
                                            );
                                          })}
                                      </div>
                                      <div>
                                        {usahaal
                                          .filter(
                                            (obj) =>
                                              obj.id_alumni ===
                                              parseInt(
                                                sessionStorage.getItem(
                                                  "id_alumni"
                                                )
                                              )
                                          )
                                          .filter(
                                            (usahaal) =>
                                              usahaal.status == "Decline"
                                          )
                                          .map((usahaas) => {
                                            return (
                                              <div
                                                key={al.id_alumni}
                                                className="profil-notif"
                                              >
                                                <div className="profil-notif-loker-icon">
                                                  {" "}
                                                  <FcCancel />{" "}
                                                </div>
                                                <div className="profil-notif-loker-isi">
                                                  <p className="notif-text">
                                                    {" "}
                                                    {usahaas.nama_usaha}
                                                  </p>
                                                  ditolak
                                                </div>
                                              </div>
                                            );
                                          })}
                                      </div>
                                      <diV></diV>
                                    </div>
                                  </Card>
                                </div>
                              </div>
                            </Tab.Pane>
                          </Tab.Content>
                        </Col>
                      </Row>
                    </Tab.Container>
                  </Container>
                </div>
              </div>
            );
          })}
      </Container>
    </div>
  );
}

export default ProfilAlumni;
