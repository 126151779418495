import React, { Component, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Form,
  Button,
  Nav,
  Alert,
  Card,
} from "react-bootstrap";
import LoginImage from "../../assets/images/login.png";
import jwt_decode from "jwt-decode";
import { useNavigate, Navigate } from "react-router-dom";
import axios from "axios";
import { object } from "underscore";
import { Helmet } from "react-helmet";
/* global gapi */

function Login() {
  const [user, setUser] = useState({});
  const [alumni, setAlumni] = useState({});
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [email_alumni, setEmailAlumni] = useState("");
  const [error, setError] = useState("");

  const google = window.google;
  const navigate = useNavigate();

  // setIsWithGoogle(false);
  useEffect(() => {
    axios
      .get("https://ika.pcr.ac.id/backend_ikapcr/public/api/alumni")
      .then((response) => {
        setAlumni(response.data);

        // console.log(response.data);
      });
  }, []);

  const handleLogin = (event) => {
    // event.preventDefault();
    // var data = new FormData();
    // data.append("email_alumni", email_alumni);
    // data.append("password", password);
    // const validasiAlumni = alumni.find((a) => a.email_alumni === email_alumni);
    // const validasiAlumniPassword = alumni.find(
    //   (a) => a.email_alumni === email_alumni && a.password === password
    // );

    // console.log(validasiAlumni);
    // console.log(validasiAlumniPassword);

    // if (validasiAlumni !== undefined && validasiAlumniPassword !== undefined) {
    //   sessionStorage.setItem("id_alumni", validasiAlumni.id_alumni);
    //   navigate("/profilalumni");
    // } else if (validasiAlumniPassword === undefined) {
    //   setError("Invalid email or password.");
    //   console.log(email_alumni);
    //   console.log(password);
    // }
    event.preventDefault();

    if (!email_alumni || !password) {
      setError("Please fill in all fields.");
      return;
    }

    const validasiAlumni = alumni.find((a) => a.email_alumni === email_alumni);
    const validasiAlumniPassword =
      validasiAlumni && validasiAlumni.password === password;

    if (validasiAlumniPassword) {
      sessionStorage.setItem("id_alumni", validasiAlumni.id_alumni);
      navigate("/../panel/profilpanel");
      window.location.reload();
    } else if (!validasiAlumni) {
      setError("Email is not registered.");
    } else {
      setError("Invalid password.");
    }
  };

  function handleSignOut(event) {
    setUser({});
    sessionStorage.removeItem("id_alumni");
    document.getElementById("SignInDiv").hidden = false;
    document.getElementById("emailInput").hidden = false;
  }

  useEffect(() => {
    if (sessionStorage.getItem("id_alumni") !== null) {
      navigate("/../panel/profilpanel");
      window.location.reload();
    }
  }, []);

  function navigateToRegis() {
    navigate("/main/register");
  }

  return (
    <div>
      <Container style={{ padding: 30 }}>
        <div className="main-title-1">
          <h1>
            <strong></strong>
          </h1>
        </div>
        <div>
          <Row>
            <Col sm>
              <Card className="kontak-card">

              <div className="main-title-login">
                <h3>
                  <strong>Welcome Back!</strong>
                </h3>
              </div>

              <div>
                <div>
                  <div>
                    <Form>
                      {error && <Alert variant="danger">{error}</Alert>}
                      <Form.Group style={{ paddingBottom: 10 }}>
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          required
                          id="email_alumni"
                          type="email"
                          placeholder="Enter your email.."
                          className="formlogin"
                          onChange={(e) => setEmailAlumni(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group
                        style={{ paddingBottom: 10 }}
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label>password</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Enter your password.."
                          className="formlogin"
                          required
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </Form.Group>
                      <Button
                        className="formlogin"
                        type="submit"
                        onClick={(e) => handleLogin(e)}
                      >
                        Login
                      </Button>
                    </Form>
                  </div>
                </div>
              </div>
              <Row style={{ paddingTop: 30 }}>
                <Col>Belum punya akun?</Col>
                <Col>
                  <Button 
                  onClick={navigateToRegis}
                  className="registbnlogin">Klik Disini</Button>
                </Col>
              </Row>
              </Card>
            </Col>

            <Col sm>
              <div style={{ marginTop: -20 }}>
                <Image src={LoginImage} style={{ width: "100%" }}></Image>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}

export default Login;
