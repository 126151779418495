import React, { useState, useEffect } from "react";
import { Container, Image, Col, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import axios from "axios";

function GaleriDetail() {
  const [galerid, setGaleriD] = useState([]);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxIndex, setLightboxIndex] = useState(0);
  const location = useLocation();

  useEffect(() => {
    axios
      .get("https://ika.pcr.ac.id/backend_ikapcr/public/api/galeri/")
      .then((response) => {
        setGaleriD(response.data);
      });
  }, []);

  const openLightbox = (index) => {
    setLightboxIndex(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };

  const filteredImages = galerid
    .filter(
      (galeridd) =>
        galeridd.id_agenda === location.state.id_agenda_current
    )
    .map((gal) => ({
      url:
        "https://ika.pcr.ac.id/backend_ikapcr/public/storage/galeri/" +
        gal.foto_galeri,
    }));

  return (
    <div>
       <div style={{ paddingTop: "70px" }}>
        <div className="main-title">
        <h4>
          <strong>{location.state.nama_kegiatan}</strong>
        </h4>
        </div>
      </div>
   
      <Container className="box">
        <div className="konten">
        <Row>
        {filteredImages.map((gal, index) => {
          return (
            <Col key={index}>
              <div onClick={() => openLightbox(index)}>
                <Image
                  className="galeri-images-detail"
                  src={gal.url}
                  alt={gal.url}
                />
              </div>
            </Col>
          );
        })}
      </Row>
        </div>
      </Container>
         {/* Lightbox for displaying the images */}
         {lightboxOpen && (
          <Lightbox
            images={filteredImages}
            onClose={closeLightbox}
            startIndex={lightboxIndex}
          />
        )}
      </div>
    
  );
}

export default GaleriDetail;
