import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Card, Image, Button } from "react-bootstrap";
import { FaRegCalendarAlt, FaRegClock, FaLocationArrow } from "react-icons/fa";
import { BsArrowRight } from "react-icons/bs";
import axios from "axios";
import moment from "moment";
import Paginate from "../../inc/Paginate";
import { BiSearchAlt } from "react-icons/bi";

function Agenda() {
  const [agenda, setAgenda] = useState([]);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(4);
  const [searchValue, setSearchValue] = useState("");

  function navigateToAgendaDetail(id_agenda_value) {
    navigate("/main/agendadetail", {
      state: { id_agenda_current: id_agenda_value },
    });
  }

  useEffect(() => {
    axios
      .get("https://ika.pcr.ac.id/backend_ikapcr/public/api/agenda")
      .then((response) => {
        setAgenda(response.data);
      });
  }, []);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = agenda.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const previousPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== Math.ceil(agenda.length / postsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div>
      <div style={{ paddingTop: "70px" }}>
        <div className="main-title">
          <h1>
            <strong>Agenda</strong>
          </h1>
        </div>
      </div>
      <Container className="box">
        <div className="search">
          <div className="search-box">
            <Button className="btn-search">
              <BiSearchAlt />
            </Button>
            <input
              className="input-search"
              type="text"
              name="search"
              placeholder="Type to Search..."
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
        </div>
        <div className="konten">
          <Row>
            {currentPosts
              .filter((agendajdl) =>
                agendajdl.nama_kegiatan.match(new RegExp(searchValue, "i"))
              )
              .map((agendas) => {
                return (
                  <Col style={{ paddingTop: "20px" }}>
                    <div key={agendas.id_agenda} className="agenda-card">
                      <Card className="agenda-card-border"
                      onClick={() =>
                        navigateToAgendaDetail(agendas.id_agenda)
                      }
                      >
                        <div className="agenda-card-header">
                          <Image
                            className="agenda-card-image"
                            src={
                              "https://ika.pcr.ac.id/backend_ikapcr/public/storage/agenda/" +
                              agendas.foto
                            }
                            alt={agendas.foto}
                          />
                        </div>
                        <Card.Body>
                          <div className="agenda-card-cover-isi">
                            <div>
                              <div className="agenda-card-title">
                                {agendas.nama_kegiatan}
                              </div>
                            </div>
                            <div className="agenda-card-isi">
                              <div>
                                <FaRegCalendarAlt />{" "}
                                {moment(agendas.tanggal).format("LL")}
                                <div className="agenda-card-isi-isi">
                                  <FaRegClock /> {agendas.waktu}
                                  <div className="agenda-card-isi-isi">
                                    <FaLocationArrow />{" "}
                                    {agendas.tempat_kegiatan}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="agenda-card-detail">
                            <div className="agenda-btn">
                              <Button
                                className="agenda-btn-dt"
                                onClick={() =>
                                  navigateToAgendaDetail(agendas.id_agenda)
                                }
                              >
                                Lihat Agenda
                                <BsArrowRight />
                              </Button>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </Col>
                );
              })}
          </Row>
        </div>
        <div className="pagenation">
          <Paginate
            postsPerPage={postsPerPage}
            totalPosts={agenda.length}
            paginate={paginate}
            previousPage={previousPage}
            nextPage={nextPage}
          />
        </div>
      </Container>
    </div>
  );
}

export default Agenda;
