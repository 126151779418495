import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Card, Image, Button } from "react-bootstrap";
import { FaRegCalendarAlt, FaRegClock, FaLocationArrow } from "react-icons/fa";
import { BsArrowRight } from "react-icons/bs";
import axios from "axios";
import moment from "moment";

function AgendaHomepage() {
  moment.locale("id");
  const [agenda, setAgenda] = useState([]);
  const navigate = useNavigate();
  function navigateToAgenda() {
    navigate("/main/agenda");
  }

  function navigateToAgendaDetail(id_agenda_value) {
    navigate("/main/agendadetail", {
      state: { id_agenda_current: id_agenda_value },
    });
  }


  useEffect(() => {
    axios
      .get("https://ika.pcr.ac.id/backend_ikapcr/public/api/agenda")
      .then((response) => {
        setAgenda(response.data);
      });
  }, []);

  return (
    <div>
      <Container className="box">
        <div className="main-title-beranda">
          <h1>
            <strong>Agenda</strong>
          </h1>
        </div>
        <div >
          <div className="lihat-semua-btn">
            <Button className="lihat-semua-btn-dt" onClick={navigateToAgenda}>
              Lihat Semua Agenda
              <BsArrowRight />
            </Button>
          </div>
        </div>
        <div className="konten">
          <Row>
            {agenda.slice(0, 4).map((agendas) => {
              return (
                <Col style={{ paddingTop: "20px" }}>
                  <div key={agendas.id_agenda} className="agenda-card">
                    <Card
                      onClick={() => navigateToAgendaDetail(agendas.id_agenda)}
                      className="agenda-card-border"
                    >
                      <div className="agenda-card-header">
                        <Image
                          className="agenda-card-image"
                          src={
                            "https://ika.pcr.ac.id/backend_ikapcr/public/storage/agenda/" +
                            agendas.foto
                          }
                          alt={agendas.foto}
                        />
                      </div>
                      <Card.Body>
                        <div className="agenda-card-cover-isi">
                          <div>
                            <div className="agenda-card-title">
                              {agendas.nama_kegiatan}
                            </div>
                          </div>
                          <div className="agenda-card-isi">
                            <div>
                              <FaRegCalendarAlt />{" "}
                              {moment(agendas.tanggal).format("LL")}
                              <div className="agenda-card-isi-isi">
                                <FaRegClock /> {agendas.waktu}
                                <div className="agenda-card-isi-isi">
                                  <FaLocationArrow /> {agendas.tempat_kegiatan}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="agenda-card-detail">
                          <div className="agenda-btn">
                            <Button
                              className="agenda-btn-dt"
                              onClick={() =>
                                navigateToAgendaDetail(agendas.id_agenda)
                              }
                            >
                              Lihat Agenda
                              <BsArrowRight />
                            </Button>
                          </div>
                        </div> */}
                      </Card.Body>
                    </Card>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      
      </Container>
    </div>
  );
}

export default AgendaHomepage;
