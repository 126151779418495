import { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

function EditLoker({ id_loker }) {
  const navigate = useNavigate();

  const [lokerde, setLokerD] = useState([]);
  const [lokere, setLoker] = useState([]);
  const [judul_lowongan, setJudulLowongan] = useState("");
  const [deskipsi, setDeskipsi] = useState("");
  const [status, setStatus] = useState("");
  const [gambar, setGambar] = useState("");
  const [kategori, setKategori] = useState("");
  const [tglmulai, setTglMulai] = useState("");
  const [tglselesai, setTglSelesai] = useState("");

  useEffect(() => {
    fetch("https://ika.pcr.ac.id/backend_ikapcr/public/api/loker/" + id_loker)
      .then((res) => res.json())
      .then((response) => {
        setLokerD(response);
        // Populate state variables based on response
        if (response.length > 0) {
          const usahaLoker = response[0]; // Assuming you only expect one result
          setJudulLowongan(usahaLoker.judul_lowongan);
          setDeskipsi(usahaLoker.deskipsi);
          setKategori(usahaLoker.kategori);
          setTglMulai(usahaLoker.tglmulai);
          setTglSelesai(usahaLoker.tglselesai);
          setGambar(usahaLoker.gambar);
          // ... Set other state variables
        }
      });
  }, [id_loker]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Construct the data object
      const updateData = {
        id_alumni: sessionStorage.getItem("id_alumni"),
        judul_lowongan: judul_lowongan,
        deskipsi: deskipsi,
        kategori: kategori,
        tglmulai: tglmulai,
        tglselesai: tglselesai,

        // ... More fields
      };

      // Make the PUT request to update the data
      const response = await axios.post(
        `https://ika.pcr.ac.id/backend_ikapcr/public/api/loker/update-data/${id_loker}`,
        updateData
      );

      // Show success Swal notification
      Swal.fire("Success", "Data berhasil diperbarui!", "success").then(() => {
        // Redirect to UsahaDetailPanel after Swal is closed
        window.location.reload();
      });
    } catch (error) {
      console.error("Error updating data:", error);
      // Show error Swal notification
      Swal.fire("Error", "Terjadi kesalahan saat memperbarui data.", "error");
    }
  };

  const handleFotoInput = (e) => {
    setGambar(e.target.files[0]);
  };

  return (
    <div style={{ padding: 30 }}>
      <Form onSubmit={handleSubmit} encType="multipart/form-data">
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>Posisi dan Nama Perusahaan</Form.Label>
          <Form.Control
            placeholder="IT Support - Telkomsel Indonesia"
            type="text"
            id="judul_lowongan"
            value={judul_lowongan}
            onChange={(e) => setJudulLowongan(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>Tanggal Mulai</Form.Label>
          <Form.Control
            id="tanggal_mulai"
            type="date"
            value={tglmulai}
            onChange={(e) => setTglMulai(e.target.value)} // Update tglmulai state
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>Tanggal Berakhir</Form.Label>
          <Form.Control
            id="tanggal_selesai"
            type="date"
            value={tglselesai}
            onChange={(e) => setTglSelesai(e.target.value)} // Update tglselesai state
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Kategori</Form.Label>
          <Form.Select
            required
            id="kategori"
            type="text"
            value={kategori}
            onChange={(e) => setKategori(e.target.value)}
          >
            <option>Pilih Kategori Loker</option>
            <option value="Magang">Magang</option>
            <option value="Fulltime">Fulltime</option>
          </Form.Select>
        </Form.Group>
        <Form.Group controlId="formFile" className="mb-3">
          <Form.Label>Inputkan Gambar Poster</Form.Label>
          <Form.Control type="file" id="gambar" onChange={handleFotoInput} />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label>Deskripsi</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            id="deskipsi"
            value={deskipsi}
            onChange={(e) => setDeskipsi(e.target.value)}
          />
        </Form.Group>
      </Form>
      <div style={{display:'flex', justifyContent:'end'}}>
      <Button type="submit" onClick={() => handleSubmit()}>
        Update
      </Button>
      </div>
    </div>
  );
}

export default EditLoker;
