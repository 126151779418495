import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {  Container, Row, Col, Card, Image, Button } from "react-bootstrap";
import { BsArrowRight } from "react-icons/bs";
import { BiSearchAlt } from "react-icons/bi";
import { AiFillHeart } from "react-icons/ai";
import axios from "axios";
import moment from "moment/moment";
import Paginate from "../../inc/Paginate";

function Berita(props) {
  const [berita, setBerita] = useState([]);
  const [likes, setLikes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(8);
  const navigate = useNavigate();
  const { id } = useParams();
  const [like, setLike] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = berita.slice(indexOfFirstPost, indexOfLastPost);
  const [refreshKey, setRefreshKey] = useState(1);

  function handleLikeClick(beritaVal) {
    var idBerita = beritaVal;
    var idAlumni = sessionStorage.getItem("id_alumni");
    var likeValue = "liked";

    var likeData = new FormData();
    likeData.append("id_berita", beritaVal);
    likeData.append("id_alumni", sessionStorage.getItem("id_alumni"));
    likeData.append("like", "1");

    var checkAlumniLiked = like.some(
      (l) =>
        l.id_alumni === parseInt(sessionStorage.getItem("id_alumni")) &&
        l.id_berita === parseInt(beritaVal)
    );

    if (checkAlumniLiked === true) {
      fetch(
        "https://ika.pcr.ac.id/backend_ikapcr/public/api/like" +
          sessionStorage.getItem("id_alumni") +
          "/" +
          beritaVal,
        {
          method: "GET",
        }
      ).then(
        function (res) {},
        function (e) {}
      );
    } else {
      fetch("https://ika.pcr.ac.id/backend_ikapcr/public/api/like", {
        method: "POST",
        body: likeData,
      }).then(
        function (res) {},
        function (e) {}
      );
    }
    setRefreshKey((oldKey) => oldKey + 1);
  }

  function navigasiBerita(id_berita_value) {
    navigate("/main/beritadetail", {
      state: { id_berita_current: id_berita_value },
    });
  }

  useEffect(() => {
    axios
      .get("https://ika.pcr.ac.id/backend_ikapcr/public/api/berita")
      .then((response) => {
        setBerita(response.data);
      });

    axios
      .get("https://ika.pcr.ac.id/backend_ikapcr/public/api/like")
      .then((response) => {
        setLikes(response.data);
      });

    axios
      .get("https://ika.pcr.ac.id/backend_ikapcr/public/api/likes")
      .then((response) => {
        setLike(response.data);
      });
  }, [refreshKey]);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const previousPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== Math.ceil(berita.length / postsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div>
       <div style={{ paddingTop: "70px" }}>
        <div className="main-title">
          <h1>
            <strong>Berita</strong>
          </h1>
        </div>
      </div>
      <Container className="box">
      
        <div className="search">
          <div className="search-box">
            <Button className="btn-search">
              <BiSearchAlt />
            </Button>
            <input
              className="input-search"
              type="text"
              name="search"
              placeholder="Type to Search..."
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
        </div>

        <div className="konten">
          <Row>
            {currentPosts
              .filter((jdlBerita) =>
                jdlBerita.judul_berita.match(new RegExp(searchValue, "i"))
              )
              .map((beritas) => {
                return (
                  <Col style={{ paddingTop: 20 }}>
                    <div key={beritas.id_berita} className="berita-card">
                      <Card className="berita-card-border"
                      // onClick={() =>
                      //   navigasiBerita(beritas.id_berita)
                      // }
                      >
                        <div className="berita-card-header">
                          <Image
                            className="berita-card-images"
                            src={
                              "https://ika.pcr.ac.id/backend_ikapcr/public/storage/berita/" +
                              beritas.gambar
                            }
                            alt={beritas.gambar}
                          />
                        </div>
                        <Card.Body>
                          <div className="berita-card-isi">
                            <Row>
                              <Col>
                                {" "}
                                <small className="text-muted">
                                  {moment(beritas.tanggal_posting).format("LL")}
                                </small>{" "}
                              </Col>
                              <Col className="btn-like">
                                <Row>
                                  <Col>
                                    <div
                                      onClick={() =>
                                        handleLikeClick(beritas.id_berita)
                                      }
                                    >
                                      <div className="like">
                                        {" "}
                                        <AiFillHeart />
                                      </div>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="like-count">
                                      {likes
                                        .filter(
                                          (l) =>
                                            l.id_berita === beritas.id_berita
                                        )
                                        .map((countLike) => {
                                          return (
                                            <div key={countLike.id_berita}>
                                              {countLike.lik}
                                            </div>
                                          );
                                        })}
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </div>
                          <div className="berita-card-cover-isi">
                            <Card.Title className="berita-card-title">
                              {beritas.judul_berita}
                            </Card.Title>
                          </div>
                          <div>
                            <div className="berita-card-detail">
                              <div className="berita-btn">
                                <Button
                                  className="berita-btn-dt"
                                  onClick={() =>
                                    navigasiBerita(beritas.id_berita)
                                  }
                                >
                                  Baca Berita <BsArrowRight />
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </Col>
                );
              })}
          </Row>
        </div>
        <div className="pagenation">
          <Paginate
            postsPerPage={postsPerPage}
            totalPosts={berita.length}
            paginate={paginate}
            previousPage={previousPage}
            nextPage={nextPage}
          />
        </div>
      </Container>
    </div>
  );
}

export default Berita;
