import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Table,
  Modal,
  Button,
} from "react-bootstrap";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import UpdateUsahaAlumni from "./UpdateUsahaAlumni";

function UsahaAlumniDetailUser() {
  const [usahaalumniD, setUAD] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const location = useLocation();
  const navigate = useNavigate();

  //   function navigateToBiolink() {
  //     fetch("https://ika.pcr.ac.id/backend_ikapcr/public/api/UsahaAlumni/" +
  //     location.state.id_usaha_alumni_current
  //     )
  //     .then(response => response.json())
  //     .then((data) => {
  //       window.open(data.link_tree, '_blank');
  //     })
  //     .catch((error) => console.error("oops:",error));
  // }

  useEffect(() => {
    axios
      .get(
        "https://ika.pcr.ac.id/backend_ikapcr/public/api/UsahaAlumni/" +
          location.state.id_usaha_alumni_current
      )
      .then((response) => {
        setUAD(response.data);
      });
  }, []);

  function navigateToEdit(id_usaha_alumni_value) {
    navigate("/editusaha", {
      state: { id_usaha_alumni_current: id_usaha_alumni_value },
    });
  }

  const handleEditClick = () => {
    navigateToEdit(location.state.id_usaha_alumni_current);
  };

  return (
    <div>
      {usahaalumniD.map((uadetail) => {
        return (
          <div key={uadetail.id_usaha_alumni}>
            <div style={{ paddingTop: "70px" }}>
              <div className="main-title">
                {/* <h1>
            <strong>Visi Misi</strong>
          </h1> */}
              </div>
            </div>
            <Container className="box">
              <div>
                <Row>
                  <Col sm>
                    <Image
                      className="agenda-detail-gambar-isi"
                      src={
                        "https://ika.pcr.ac.id/backend_ikapcr/public/storage/UsahaAlumni/" +
                        uadetail.foto_usaha
                      }
                      alt={uadetail.gambar}
                    />
                  </Col>
                  <Col>
                    <div className="usaha-alumni-title-detail">
                      <p className="usaha-alumni-title-detail-text">
                        <strong>{uadetail.nama_usaha}</strong>
                      </p>
                    </div>
                    <div>
                      <div>
                        <Table id="uadetail">
                          <thead>
                            <th colSpan={2}>Owner</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td rowSpan={3}>
                                <div className="uadetail-img-owner">
                                  <Image
                                    roundedCircle
                                    width={50}
                                    src={
                                      "https://ika.pcr.ac.id/backend_ikapcr/public/storage/alumni/" +
                                      uadetail.foto
                                    }
                                    alt={uadetail.foto}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="uadetail-td">
                                  <p>{uadetail.nama}</p>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="uadetail-td">
                                  <p>{uadetail.prodi}</p>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="uadetail-td">
                                  <p>Generasi {uadetail.generasi}</p>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                      <Button onClick={handleShow}>Launch demo modal</Button>
                      <Modal show={show} onHide={handleClose}>
                        {location.state && (
                          <UpdateUsahaAlumni
                            id_usaha_alumni={
                              location.state.id_usaha_alumni_current
                            }
                          />
                        )}
                      </Modal>
                      {/* <Button onClick={handleEditClick}>
                   
                  
                        Edit Usaha
                      </Button> */}
                      <div>=</div>
                      <div className="uadetail-row">
                        <div className="uadetail-label">Kategori :</div>
                        <div className="uadetail-text">
                          {uadetail.jenis_usaha}
                        </div>
                      </div>
                      <div className="uadetail-row">
                        <div className="uadetail-label">Alamat :</div>
                        <div className="uadetail-text">
                          {uadetail.alamat_usaha}
                        </div>
                      </div>
                      <div className="uadetail-row">
                        <div className="uadetail-label">No HP :</div>
                        <div className="uadetail-text">{uadetail.no_hp}</div>
                      </div>
                      <div className="uadetail-row">
                        <div className="uadetail-label">Email :</div>
                        <div className="uadetail-text">{uadetail.email}</div>
                      </div>
                      <div className="uadetail-row">
                        <div className="uadetail-label">Biolink :</div>
                        <div
                          className="uadetail-text"
                          // onClick={() => navigateToBiolink()}
                        >
                          {uadetail.link_tree}
                        </div>
                      </div>

                      <Row className="uadetail-row">
                        <div sm={6} className="uadetail-label">
                          Tentang Product Ini :
                        </div>
                        <div className="uadetail-text-deskripsi">
                          {uadetail.deskripsi_usaha}
                        </div>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
        );
      })}
    </div>
  );
}

export default UsahaAlumniDetailUser;
